import React, { createContext, useContext, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

type TExpanderCTX = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const ExpanderCTX = createContext<TExpanderCTX>({
  open: false,
  setOpen: (val) => {},
});

type ExpanderProps = {
  defaultOpen?: boolean | undefined;
  open?: boolean | undefined;
  as: string | undefined | null;
  className: string;
  children: any;
};
const Expander = ({
  defaultOpen = undefined,
  open: _open = undefined,
  as = null,
  className,
  children,
}: ExpanderProps) => {
  const [show, setShow] = useState(defaultOpen || false);

  useEffect(() => {
    if (_open !== undefined) {
      setShow(_open);
    }
  }, [_open]);

  const ctx = {
    open: show,
    setOpen: setShow,
  } as TExpanderCTX;

  return (
    <ExpanderCTX.Provider value={ctx}>
      {as
        ? React.createElement(as, { className, children })
        : React.createElement(React.Fragment, { children })}
    </ExpanderCTX.Provider>
  );
};

type ExpanderButtonProps = {
  className: string;
  onClick?: () => void;
  children: any;
};
const ExpanderButton = ({
  className,
  onClick,
  children,
}: ExpanderButtonProps) => {
  const { open, setOpen } = useContext(ExpanderCTX);

  const click = () => {
    setOpen((prev) => {
      const next = !prev;
      if (next && onClick) {
        onClick();
      }

      return next;
    });
  };

  return (
    <button className={className} onClick={click}>
      {!!children && children({ open })}
    </button>
  );
};
Expander.Button = ExpanderButton;

type ExpanderPanelProps = {
  children: any;
};
const ExpanderPanel = ({ children }: ExpanderPanelProps) => {
  const { open } = useContext(ExpanderCTX);

  return (
    <Transition show={open} as="div">
      {/* <Transition.Child
          as={React.Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        > */}
      {!!children && children}
      {/* </Transition.Child> */}
    </Transition>
  );
};
Expander.Panel = ExpanderPanel;

export default Expander;
