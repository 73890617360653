import logo from '../../images/poltio-logo.svg';

export const AuthHeading = ({ msg }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img className="w-auto h-12 mx-auto" src={logo} alt="Workflow" />
      <h2 className="mt-6 text-3xl font-semibold text-center dark:text-gray-400 text-gray-600">
        {msg}
      </h2>
    </div>
  );
};
