import { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { validateUsername } from '../../../core/helper';

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const AccountUsername = ({
  username,
  setUsername,
  setValid,
  intl: { formatMessage },
}) => {
  const [value, setValue] = useState(username);
  const [isValid, setIsValid] = useState('');

  const debounce = useRef(null);

  useEffect(() => {
    if (debounce.current !== null) {
      clearTimeout(debounce.current); // rentry cancel previous
    }

    debounce.current = setTimeout(() => {
      debounce.current = null; // clear debounce on run

      if (value === username) return;

      // validate
      if (validateUsername(value)) {
        setValid((prev) => ({ ...prev, username: true })); // enable save
        setIsValid(''); // clear error message
        setUsername(value); // handle assignment
      } else {
        setIsValid(formatMessage(messages.AccountUsernameErrorInvalid)); // raise error message
        setValid((prev) => ({ ...prev, username: false })); // disable save
      }
    }, 1000);
  }, [value, username, setUsername, setValid, formatMessage]);

  const onChange = (event) => {
    const next = event.target.value;
    setValid((prev) => ({ ...prev, username: false })); // dont let it save before validation
    setValue(next);
  };

  return (
    <div className="col-span-full">
      <label
        htmlFor="username"
        className="block text-sm font-medium leading-6 dark:text-gray-300"
      >
        {formatMessage(messages.AccountUsernameLabel)}
      </label>
      <div className="mt-2">
        <input
          id="username"
          name="username"
          type="text"
          autoComplete="username"
          className={classNames(
            'block w-full rounded-md   py-1.5  shadow-sm  dark:bg-gray-900/30 dark:text-gray-200 dark:placeholder:text-gray-300  focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500 sm:text-sm sm:leading-6',
            isValid
              ? 'ring-red-600 border-red-600 focus:ring-red-600 focus:border-red-600'
              : 'focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm border-gray-300 rounded-md'
          )}
          value={value}
          onChange={onChange}
        />
      </div>
      <div className="flex text-red-600 absolute">
        <span className="pl-3 text-xs align-middle">{isValid}</span>
      </div>
    </div>
  );
};

export default injectIntl(AccountUsername);
