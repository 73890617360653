import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PasswordStrengthBar from 'react-password-strength-bar';

import messages from './messages';

import { register } from '../../api';
import { stripReturnUrl, validateEmail } from '../../core/helper';

import { appPrefix, captchaKey } from '../../core/constants';
import { ErrorAlert } from '../Common/Alert';
import Button from '../Common/Button';
import { CheckBoxInput, TextInput } from '../Common/Form';
import { AuthHeading } from './Heading';
import LoadingDialog from '../Common/Loading/LoadingDialog';
import Footer from '../Common/Footer';
import AuthContainer from './AuthContainer';
import { Helmet } from 'react-helmet';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let SignupComp = ({ intl: { formatMessage } }) => {
  let [searchParams] = useSearchParams();

  const [formData, setFormData] = useState({
    email: '',
    pass: '',
    passAgain: '',
    firstName: '',
    lastName: '',
    termsAccepted: false,
    errorMessage: '',
  });
  const [sending, setSending] = useState(false);

  const { pass, passAgain } = formData;

  // linkedin tag
  useEffect(() => {
    const _linkedin_partner_id = '2069721';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    var s = document.getElementsByTagName('script')[0];
    var b = document.createElement('script');
    (function (l) {
      if (!l) {
        window.lintrk = function (a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }

      b.type = 'text/javascript';
      b.async = true;
      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
      s.parentNode.insertBefore(b, s);
      document.body.appendChild(b);
    })(window.lintrk);
    return () => {
      document.body.removeChild(b);
    };
  }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      captchaKey,
      `https://www.google.com/recaptcha/api.js?render=${captchaKey}`,
      function () {}
    );
  }, []);

  const formInvalid = () => {
    const { email, pass, passAgain, termsAccepted, firstName, lastName } =
      formData;

    if (firstName === '') {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.FirstNameError),
      });
      return true;
    }
    if (lastName === '') {
      setFormData({
        ...FormData,
        errorMessage: formatMessage(messages.LastNameError),
      });
    }
    if (!validateEmail(email)) {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.EnterValidEmail),
      });
      return true;
    }
    if (pass.length < 8) {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.PasswordSix),
      });
      return true;
    }
    if (pass !== passAgain) {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.PasswordMatch),
      });
      return true;
    }
    if (!termsAccepted) {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.PleaseAgree),
      });
      return true;
    }

    return false;
  };

  const submitForm = async (event) => {
    event.preventDefault();

    const { email, pass, passAgain, termsAccepted, firstName, lastName } =
      formData;

    if (formInvalid()) return;

    setSending(true);

    let returnUrlModified = stripReturnUrl(searchParams);

    try {
      await register(
        email,
        pass,
        firstName,
        lastName,
        termsAccepted,
        passAgain,
        returnUrlModified || '/'
      );
      let capterra_vkey = 'bb629e7c37e1cc91e56013abfda900f1';
      let capterra_vid = '2197738';
      let ct = document.createElement('img');
      let base = 'https://ct.capterra.com/capterra_tracker.gif';
      ct.src = `${base}?vid=${capterra_vid}&vkey=${capterra_vkey}`;
      document.body.appendChild(ct);
    } catch (exception) {
      setSending(false);
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.EmailTaken),
      });
    }
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(captchaKey, { action: 'submit' }).then(() => {
        submitForm(e);
      });
    });
  };

  return (
    <>
      <Helmet>
        <title>Signup | Poltio Platform</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <LoadingDialog show={sending} />
      <AuthContainer>
        <AuthHeading msg={formatMessage(messages.CreateNew)} />

        <div className="mt-8">
          <div className="">
            <div className="">
              <div className="flex flex-row">
                <div className="mr-3">
                  <TextInput
                    text={formatMessage(messages.FirstName)}
                    id="firstName"
                    name="firstName"
                    autoComplete="none"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        firstName: e.target.value,
                        errorMessage: null,
                      });
                    }}
                    value={formData.firstName}
                  />
                </div>
                <div className="ml-3">
                  <TextInput
                    text={formatMessage(messages.LastName)}
                    id="lastName"
                    name="lastName"
                    autoComplete="none"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        lastName: e.target.value,
                        errorMessage: null,
                      });
                    }}
                    value={formData.lastName}
                  />
                </div>
              </div>
              <div className="pt-6">
                <TextInput
                  text={formatMessage(messages.Email)}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="none"
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                      errorMessage: null,
                    });
                  }}
                  value={formData.email}
                />
              </div>
              <div className="pt-6">
                <TextInput
                  text={formatMessage(messages.Pass)}
                  id="pass"
                  name="pass"
                  type="password"
                  autoComplete="none"
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      pass: e.target.value,
                      errorMessage: null,
                    });
                  }}
                  value={formData.pass}
                />
                <PasswordStrengthBar password={pass} minLength={8} />
              </div>
              <div className="">
                <TextInput
                  className={classNames(
                    'block w-full shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md',
                    pass === passAgain
                      ? 'focus:ring-green-500 focus:border-green-500'
                      : null
                  )}
                  text={formatMessage(messages.PassAgain)}
                  id="passAgain"
                  name="passAgain"
                  type="password"
                  autoComplete="none"
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      passAgain: e.target.value,
                      errorMessage: null,
                    });
                  }}
                  value={formData.passAgain}
                />
              </div>

              <div className="flex items-center justify-between pt-6">
                <CheckBoxInput
                  text={
                    <a
                      className="font-semibold text-gray-600 dark:text-gray-300"
                      href={`//${appPrefix}poltio.app/terms-and-conditions`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage(messages.AcceptTC)}
                    </a>
                  }
                  name="terms"
                  font="sm"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      termsAccepted: e.target.checked,
                      errorMessage: null,
                    });
                  }}
                  checked={formData.termsAccepted}
                />
              </div>

              <div className="flex items-center pt-6">
                <div className="w-full text-center">
                  <Button.Primary
                    type="submit"
                    className="flex w-full"
                    disabled={sending}
                    onClick={handleOnClick}
                  >
                    {formatMessage(messages.SignUp)}
                  </Button.Primary>
                </div>
              </div>

              {formData.errorMessage ? (
                <ErrorAlert title={formData.errorMessage} />
              ) : null}
            </div>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300 dark:border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-800">
                    {formatMessage(messages.AlreadyHave)}
                  </span>
                </div>
              </div>

              <Button.Secondary
                className="flex justify-center w-full mt-4"
                disabled={sending}
                href={`/login`}
              >
                {formatMessage(messages.Login)}
              </Button.Secondary>
            </div>
          </div>
          <Footer />
        </div>
      </AuthContainer>
    </>
  );
};
SignupComp = injectIntl(SignupComp);

const Signup = () => <SignupComp />;

export default Signup;
