import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  getLeadCoupons,
  addCouponCode,
  deleteCouponCodes,
  deleteSingleCode,
} from '../../api/leads';
import { useNavigate } from 'react-router-dom';

import Pagination from '../Common/Pagination';
import Button from '../Common/Button';
import Loading from '../Common/Loading';
import messages from './messages';
import { SwitchInput, TextAreaInput, TextInput } from '../Common/Form';
import { NotificationsCTX } from '../../contexts/Notification';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import {
  PencilIcon,
  TrashIcon as TrashIconX,
  ArrowUturnLeftIcon,
} from '@heroicons/react/24/solid';
import { CouponCode, Lead } from '../../core/types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Heading from '../Common/Page/Heading';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  activeLead: Lead;
  couponCodes?: Array<CouponCode>;
  activeCouponCode?: CouponCode;
  intl: any;
};

const CouponCodes: FC<Props> = ({
  couponCodes,
  activeCouponCode,
  intl: { formatMessage, formatDate },
}) => {
  const location = useLocation();

  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: 1,
  });
  const [coupons, setCoupons] = useState<Array<CouponCode>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<CouponCode>({
    single_use: 1,
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [removeExistingCode, setRemoveExistingCode] = useState<boolean>(false);
  const { success, error } = useContext(NotificationsCTX);

  const navigate = useNavigate();

  const activeLead = useMemo(() => location?.state, [location?.state]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (activeLead.id) {
      const { data } = await getLeadCoupons(
        activeLead.id,
        pageData.page,
        pageData.sizePerPage
      );
      setCoupons(data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        };
      });
      setLoading(false);
    }
  }, [pageData.page, pageData.sizePerPage, activeLead]);

  useEffect(() => {
    const data = activeLead;
    setVisible(false);
    if (data) {
      if (couponCodes && activeCouponCode) {
        setCoupons(couponCodes);
        setCode(activeCouponCode);
        setVisible(true);
      } else {
        try {
          fetchData();
        } catch (e) {
          navigate('/leads');
        }
      }
    } else {
      navigate('/leads');
    }
  }, [
    activeLead,
    pageData.page,
    pageData.sizePerPage,
    fetchData,
    navigate,
    activeCouponCode,
    couponCodes,
  ]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  const submitData = async (activeLead: Lead) => {
    try {
      await addCouponCode(activeLead.id, code);
      success(
        code.id
          ? formatMessage(messages.EditSuccess)
          : formatMessage(messages.AddSuccess),
        ''
      );

      fetchData(); // reload
      setVisible(false);
      setCode({ single_use: 1 });
    } catch (err) {
      Sentry.captureException(err);
      error(formatMessage(messages.ErrorOccured));
      return;
    }
  };

  const _deleteCouponCodes = async (l: Lead) => {
    if (window.confirm(formatMessage(messages.AreuSureAll))) {
      try {
        await deleteCouponCodes(l.id);
        success(formatMessage(messages.SuccessDeleteAll));
        fetchData();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
      }
    }
  };

  const _deleteSingleCode = async (code_id: number) => {
    if (window.confirm(formatMessage(messages.AreuSureSingle))) {
      try {
        await deleteSingleCode(activeLead.id, code_id);
        success(formatMessage(messages.SuccessDeleteSingle));
        setCode({ single_use: 1 });
        fetchData();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
        console.log(e);
      }
    }
  };

  const closeCodes = () => {
    setVisible(!visible);
    setCode({ single_use: 1 });
  };

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
      <Helmet>
        <title>{`CouponCodes - ${activeLead.id} | Poltio Platform`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Heading
        title={`${formatMessage(messages.CouponCodes)} - ${activeLead.id}`}
        body={formatMessage(messages.CouponCodesDesc)}
      >
        <Link className="poltio-btn poltio-btn-secondary mr-2" to={'/leads'}>
          <ArrowUturnLeftIcon
            className="w-4 h-4 mr-2 -ml-1"
            aria-hidden="true"
          />
          {formatMessage(messages.GoBack)}
        </Link>
      </Heading>
      <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full">
        <div>
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
            showTotal
          />
          {!visible ? (
            <div className="mt-5 sm:mt-6 flex justify-end">
              <div>
                <Button.Danger
                  type="button"
                  className="-m-2 inline-flex p-2 border-0 focus:ring-0"
                  onClick={() => _deleteCouponCodes(activeLead)}
                  disabled={coupons.length <= 0}
                  tooltip={true}
                  message={formatMessage(messages.RemoveAll)}
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </Button.Danger>
              </div>
              <div className="px-7">
                <Button.Primary
                  type="button"
                  className="-m-2 inline-flex p-2 border-0 focus:ring-0"
                  onClick={() => setVisible(!visible)}
                  tooltip={true}
                  message={formatMessage(messages.AddCouponCode)}
                >
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                </Button.Primary>
              </div>
            </div>
          ) : null}
          {visible ? (
            <div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <div>
                  {code.code ? (
                    <>
                      <TextInput
                        name="button_value"
                        label={formatMessage(messages.EditCoupon)}
                        type="text"
                        value={code.code}
                        debounce={false}
                        onChange={(event: any) => {
                          setCode((prev) => ({
                            ...prev,
                            code: event.target.value,
                          }));
                        }}
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        {formatMessage(messages.CouponCodesDesc)}
                      </p>
                    </>
                  ) : (
                    <>
                      <TextAreaInput
                        name="button_value"
                        rows={3}
                        font="semibold"
                        label={formatMessage(messages.CouponCodes)}
                        placeholder="code1&#13;&#10;code2&#13;&#10;code3"
                        type="text"
                        value={code.codes}
                        debounce={false}
                        onChange={(event: any) => {
                          setCode((prev) => ({
                            ...prev,
                            codes: event.target.value,
                          }));
                        }}
                      />
                      <p className="mt-2 text-xs text-gray-500">
                        {formatMessage(messages.EveryLine)}
                      </p>
                      <p className="mt-2 text-sm text-gray-500">
                        {formatMessage(messages.CouponCodesDesc)}
                      </p>
                      <div className="pt-2">
                        <SwitchInput
                          value={!!removeExistingCode}
                          onSwitch={(value: boolean) => {
                            setRemoveExistingCode(!removeExistingCode);
                            setCode((prev) => ({
                              ...prev,
                              remove_existing: value ? 1 : 0,
                            }));
                          }}
                        >
                          {formatMessage(messages.RemoveExisting)}
                        </SwitchInput>
                      </div>
                    </>
                  )}

                  <div className="pt-2">
                    <SwitchInput
                      value={!!code.single_use}
                      onSwitch={(value: boolean) =>
                        setCode((prev) => ({
                          ...prev,
                          single_use: value ? 1 : 0,
                        }))
                      }
                    >
                      {formatMessage(messages.SingleUse)}
                    </SwitchInput>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex justify-end">
                <Button.Secondary
                  type="button"
                  className="w-30 mr-5"
                  onClick={closeCodes}
                >
                  {formatMessage(messages.SubmitCancel)}
                </Button.Secondary>
                <Button.Primary
                  type="button"
                  className="w-30"
                  onClick={() => submitData(activeLead)}
                >
                  {formatMessage(messages.SubmitSave)}
                </Button.Primary>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="max-w-7xl mx-auto px-2">
              <Loading />
            </div>
          ) : (
            <div className="flex flex-col py-4">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr className="dark:text-gray-200 text-gray-500">
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.Id)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.Code)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.CreatedAt)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.UpdatedAt)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.IsUsed)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.SingleUse)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.UserId)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.LeadDate)}
                          </th>
                          <th scope="col" className="relative px-6 py-5"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {coupons.map((code, i) => (
                          <tr
                            key={i}
                            className={
                              i % 2 === 0
                                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900 text-center'
                                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900 text-center'
                            }
                          >
                            <td className="text-sm whitespace-nowrap">
                              {code.id}
                            </td>
                            <td className="pr-2 text-sm whitespace-nowrap">
                              {code.code}
                            </td>

                            <td className="pr-10 text-sm whitespace-nowrap">
                              {formatDate(code.created_at)}
                            </td>
                            <td className="pr-10 text-sm whitespace-nowrap">
                              {code.updated_at?.toString()}
                            </td>
                            <td className="pr-4 text-sm whitespace-nowrap">
                              {code.is_used
                                ? formatMessage(messages.Used)
                                : formatMessage(messages.NotUsed)}
                            </td>
                            <td className="pr-8 text-sm whitespace-nowrap">
                              {code.single_use
                                ? formatMessage(messages.True)
                                : formatMessage(messages.False)}
                            </td>
                            <td className="pr-10 text-sm whitespace-nowrap">
                              {code.session_id}
                            </td>
                            <td className="pr-10 text-sm whitespace-nowrap">
                              {code.used_at?.toString()}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              <div className="z-0 w-full py-2">
                                <span className="relative inline-flex">
                                  <div>
                                    <Button
                                      className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 border-0 focus:ring-0"
                                      onClick={() => {
                                        setVisible(true);
                                        setCode((prev) => ({
                                          ...prev,
                                          code: code.code,
                                          id: code.id,
                                          single_use: code.single_use,
                                          is_used: code.is_used,
                                        }));
                                      }}
                                      tooltip={true}
                                      type="button"
                                      disabled={false}
                                      href={null}
                                      showSpinner={false}
                                      message={formatMessage(
                                        messages.UpdateCode
                                      )}
                                    >
                                      <PencilIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </Button>
                                  </div>

                                  <div className="px-7">
                                    <Button
                                      className="-m-2 inline-flex p-2 text-red-400 dark:text-red-500 hover:text-red-600 dark:hover:text-red-600 border-0 focus:ring-0"
                                      onClick={() => {
                                        if (code.id) {
                                          _deleteSingleCode(code.id);
                                        }
                                      }}
                                      tooltip={true}
                                      type="button"
                                      disabled={false}
                                      href={null}
                                      showSpinner={false}
                                      message={formatMessage(
                                        messages.RemoveCode
                                      )}
                                    >
                                      <TrashIconX
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </Button>
                                  </div>
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CouponCodes);
