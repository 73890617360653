import { injectIntl } from 'react-intl';
import ThemeSearch from '../SearchField/ThemeSearch';
import messages from './messages';
import Label from './Label';

const _ThemeInput = ({
  themeId,
  themeName,
  onSelect,
  loadInit = false,
  isLoading,
  msg = null,
  intl: { formatMessage },
}) => {
  return (
    <div className="flex flex-col pb-2 dark:text-gray-300">
      {msg ?? <Label text={formatMessage(messages.AddTheme)} />}
      <div className="flex items-center pt-1 pb-2">
        <div className="w-full">
          <ThemeSearch
            themeId={themeId}
            themeName={themeName}
            onSelect={onSelect}
            loadInit={loadInit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

const ThemeInput = injectIntl(_ThemeInput);

export { ThemeInput };
export default ThemeInput;
