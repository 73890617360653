import React, { Fragment, useState } from 'react';
import {
  Dialog,
  Transition,
  DialogTitle,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Loading from '../Loading';

const Content = ({ children }) => {
  const [hasOverlay, setHasOverlay] = useState(false);

  const showOverlay = () => setHasOverlay(true);
  const closeOverlay = () => setHasOverlay(false);

  return (
    <>
      <Transition
        as={Fragment}
        show={hasOverlay}
        enter="transition-opacity duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={hasOverlay ? 'loading-overlay' : 'hidden'}>
          <div className="flex justify-center h-full">
            <Loading />
          </div>
        </div>
      </Transition>
      <div className="mt-2">
        {!!children &&
          React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child, {
                  showOverlay: showOverlay,
                  closeOverlay: closeOverlay,
                })
              : child
          )}
      </div>
    </>
  );
};

const _Header = (title, setOpen) => (
  <div className="header">
    <DialogTitle as="h3" className="text-lg font-medium text-gray-900">
      {title}
    </DialogTitle>
    <div className="ml-3 h-7 flex items-center">
      <button className="close-btn" onClick={() => setOpen(false)}>
        <span className="sr-only">Close panel</span>
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  </div>
);

const Modal = ({ title, open, setOpen, isLoading = false, children }) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="poltio-image-input-modal" onClose={setOpen}>
        <div className="flex justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {isLoading ? (
            <div className="fixed inset-y-0 max-w-md right-0 flex w-screen justify-center bg-black bg-opacity-70 transition-opacity z-20">
              <div className="flex justify-center h-full">
                <Loading />
              </div>
            </div>
          ) : null}
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogPanel className="dialog-overlay" />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="w-screen">
              <div className="container">
                {_Header(title, setOpen)}
                {!!children && <Content>{children}</Content>}
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
