import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';

import { Link } from 'react-router-dom';
import Tooltip from '../Common/Button/Tooltip';
import Table from '../Common/Table';

import messages from './messages';

const DynWidgetsTable = ({
  dynWidgets,
  handleDeleteClick,
  openEdit,
  intl: { formatMessage },
}) => {
  const columns = [
    { name: 'ID', accessor: 'id' },
    { name: `${formatMessage(messages.ContentId)}`, accessor: 'public_id' },
    { name: `${formatMessage(messages.Name)}`, accessor: 'name' },
    { name: 'Url', accessor: 'url' },
  ];

  return (
    <Table cols={columns}>
      <tbody>
        {dynWidgets.map((h, i) => (
          <tr
            key={i}
            className={
              i % 2 === 0
                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
            }
          >
            <td className="hidden px-6 py-6 text-sm whitespace-nowrap lg:flex">
              {h.id}
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              <Link
                className="text-sm font-medium"
                to={`/c/${h.public_id}`}
                style={{ cursor: 'pointer' }}
              >
                {h.public_id}
              </Link>
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              {h.name?.length > 25 ? `${h.name?.substring(0, 25)}...` : h.name}
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              {h.url?.length > 25 ? `${h.url?.substring(0, 25)}...` : h.url}
            </td>

            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
              <div className="z-0 w-full py-2">
                <span className="relative inline-flex">
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      openEdit(h);
                    }}
                    target="_blank"
                    rel="noreferrer"
                    className="group cursor-pointer relative inline-block border-gray-400 text-center"
                  >
                    <PencilIcon
                      className="w-5 h-5 mr-4 -ml-1 text-poltio-purple hover:text-poltio-purple-hover"
                      aria-hidden="true"
                    />
                    <Tooltip title={formatMessage(messages.Edit)} />
                  </a>
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteClick(h.id);
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TrashIcon
                      className="w-5 h-5 mr-2 -ml-1 text-poltio-red hover:text-poltio-red-hover"
                      aria-hidden="true"
                    />
                  </a>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default injectIntl(DynWidgetsTable);
