import { useState } from 'react';

import VoteSourcesReport from './VoteSourcesReport';
import VotersReport from './VotersReport';
import SessionReport from './SessionReport';
import { injectIntl } from 'react-intl';
import messages from '../messages';
import DetailCard from '../../../Common/DetailCard/index';
import Button from '../../../Common/Button';
import QuestionsCondReports from './QuestionsCondReports';

import SetSummaryReport from './SetSummaryReport';
import FreeTypeAnswers from '../FreeTypeAnswers';

const Graphs = ({ content, intl: { formatMessage } }) => {
  //free type answers
  const [openModal, setOpenModal] = useState(false);
  const [freeAnswer, setFreeAnswer] = useState();
  const onClick = (row) => {
    setFreeAnswer(row);
    setOpenModal(true);
  };
  const freeTypeAnswers = content.questions.filter(
    (q) => q.answer_type === 'free_number' || q.answer_type === 'free_text'
  );

  return (
    <div className="mt-4 space-y-4">
      <DetailCard
        title={formatMessage(messages.VoteSources)}
        subtitle={formatMessage(messages.VoteSourcesDesc)}
      >
        <VoteSourcesReport public_id={content.public_id} />
      </DetailCard>

      <DetailCard
        title={formatMessage(messages.SetSummary)}
        subtitle={formatMessage(messages.SetSummaryDesc)}
      >
        <SetSummaryReport questionsProp={content.questions} />
      </DetailCard>

      {freeTypeAnswers.length ? (
        <DetailCard
          title={formatMessage(messages.FreeTypeAnswers)}
          // subtitle={formatMessage(messages.VotePercentage)}
        >
          <div className="overflow-x-auto border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr className="dark:text-gray-200 text-gray-500">
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.Title)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.QuestionID)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.VoteCount)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.AnswerType)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {freeTypeAnswers.map((row, i) => (
                  <tr
                    key={`results${i}`}
                    className={
                      i % 2 === 0
                        ? 'bg-white dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                        : 'bg-gray-50 dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                    }
                  >
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                      {row.title.length > 60
                        ? row.title?.substring(0, 50) + '...'
                        : row.title}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                      {row.id}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                      {row.vote_count}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                      {row.answer_type === 'free_number'
                        ? 'Free Number'
                        : 'Free Text'}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap text-right">
                      <Button.Primary
                        className="self-end"
                        onClick={() => onClick(row)}
                      >
                        {formatMessage(messages.ClickForAnswers)}
                      </Button.Primary>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <FreeTypeAnswers
            answers={freeAnswer}
            open={openModal}
            setOpen={setOpenModal}
          />
        </DetailCard>
      ) : null}
      <DetailCard
        title={formatMessage(messages.VoterReport)}
        subtitle={formatMessage(messages.VoterReportDesc)}
      >
        <VotersReport public_id={content.public_id} type={content.type} />
      </DetailCard>
      <DetailCard title={formatMessage(messages.SessionReport)}>
        <SessionReport public_id={content.public_id} />
      </DetailCard>
      <DetailCard
        title={'Questions and Conditions'}
        subtitle="It shows the conditions of the questions in your content."
      >
        <QuestionsCondReports public_id={content.public_id} />
      </DetailCard>
    </div>
  );
};

export default injectIntl(Graphs);
