import {
  useEffect,
  useState,
  useRef,
  type FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { injectIntl } from 'react-intl';
import { pcSave } from '../../api';
import messages from './messages';
import Param from './Param';

import { validateHtml, theme } from '../../core/helper';
import SlideOver from '../Common/SlideOver';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { PixelCode } from '../../core/types';
import { Helmet } from 'react-helmet';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  pc: PixelCode;
  handleSubmit: () => {};
  success: any;
  error: any;
  intl: any;
};

const PixelCodesForm: FC<Props> = ({
  open,
  setOpen,
  pc,
  success,
  error,
  handleSubmit,
  intl: { formatMessage },
}) => {
  const [data, setData] = useState<PixelCode>();

  const [saving, setSaving] = useState(false);

  const [code, setCode] = useState('');
  const [event, setEvent] = useState<any>({ target: {} });
  const [name, setName] = useState('');

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setData(pc);

    if (pc.id) {
      setCode(pc.code);
      setName(pc.name);
      return;
    }

    setCode('');
    setName('');
  }, [pc]);

  const handleChange = (val: any) => {
    inputRef?.current?.focus();
    const startPos = event.target.selectionStart || code.length;
    const endPos = event.target.selectionEnd || code.length;
    setCode(
      (code) =>
        code.substring(0, startPos) + val + code.substring(endPos, code.length)
    );
  };

  const onFormSubmit = async () => {
    if (!validateHtml(code)) {
      error(formatMessage(messages.EnterValidHTML));
      return;
    }

    if (!saving) {
      try {
        setSaving(true);

        await pcSave(code, name, data?.id);

        success(formatMessage(messages.PixelCodeSave));

        handleSubmit();

        setOpen(false);
      } catch (e) {
        error(formatMessage(messages.ErrorSaving));
      }

      setSaving(false);
    }
  };

  return (
    <SlideOver
      open={open}
      setOpen={() => {}}
      isLoading={saving}
      onSave={onFormSubmit}
      isDisabled={!name}
      onCancel={() => setOpen(false)}
    >
      <Helmet>
        <title>
          {pc.name
            ? `Editing PixelCode: ${pc.name} | Poltio Platform`
            : `Creating PixelCode | Poltio Platform`}
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <SlideOver.Header
        title={
          pc.id
            ? formatMessage(messages.EditCode)
            : formatMessage(messages.CreateNew)
        }
        subtitle={
          pc.id
            ? formatMessage(messages.EditPixelCode)
            : formatMessage(messages.AddPixelCode)
        }
        setOpen={setOpen}
        children={null}
      />

      <SlideOver.Body>
        <div className="divide-y divide-gray-200 ">
          <div className="pt-6 pb-5 space-y-6 px-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {formatMessage(messages.Name)}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full border-gray-300 dark:bg-gray-900/30 dark:text-gray-200 dark:placeholder:text-gray-300 shadow-sm sm:text-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 rounded-md"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="url"
                className="block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Pixel Code
              </label>
              <div
                className="mt-1"
                data-color-mode={`${theme === 'dark' ? 'dark' : 'light'}`}
              >
                <CodeEditor
                  value={code}
                  ref={inputRef}
                  language="html"
                  placeholder="<script> put your event code here </script>"
                  padding={15}
                  onChange={(e) => {
                    setCode(e.target.value.trim());
                    setEvent(e);
                  }}
                  style={{
                    fontSize: 12,
                    fontFamily:
                      'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    border: '1px solid',
                    borderColor: 'rgb(0, 148, 232)',
                    borderRadius: 6,
                  }}
                  onFocus={(e) => {
                    setCode(e.target.value);
                    setEvent(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 gap-4 px-4">
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 leading-6">
            {formatMessage(messages.DynamicParams)}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {formatMessage(messages.DynamicParamsDesc)}
          </p>
        </div>

        <div className="py-4 gap-4 ">
          <Param
            onClick={() => handleChange('[parent_page_url]')}
            help={formatMessage(messages.PageUrlHelp)}
            example={
              'url=https://blog.yourpage.com/which-friends-character-you-should-marry'
            }
            label={formatMessage(messages.ParentPage)}
          />

          <Param
            onClick={() => handleChange('[q_title]')}
            help={formatMessage(messages.QuestionTitleHelp)}
            example={'title=What is your choice of dinner'}
            label={formatMessage(messages.QuestionTitle)}
          />
          <Param
            onClick={() => handleChange('[q_number]')}
            help={formatMessage(messages.QuestionNumberHelp)}
            example={'questionNumber=4'}
            label={formatMessage(messages.QuestionNumber)}
          />
          <Param
            onClick={() => handleChange('[q_id]')}
            help={formatMessage(messages.QuestionIdHelp)}
            example={'questionId=1235516'}
            label={formatMessage(messages.QuestionID)}
          />
          <Param
            onClick={() => handleChange('[a_title]')}
            help={formatMessage(messages.AnswerTitleHelp)}
            example={'title=Rice + chicken + salad'}
            label={formatMessage(messages.AnswerTitle)}
          />
          <Param
            onClick={() => handleChange('[a_number]')}
            help={formatMessage(messages.AnswerNumberHelp)}
            example={'answerNumber=2'}
            label={formatMessage(messages.AnswerNumber)}
          />
          <Param
            onClick={() => handleChange('[a_id]')}
            help={formatMessage(messages.AnswerIdHelp)}
            example={'answerId=12345543'}
            label={formatMessage(messages.AnswerID)}
          />
          <Param
            onClick={() => handleChange('[r_title]')}
            help={formatMessage(messages.ResultTitleHelp)}
            example={'title=Rice chicken salad bowl'}
            label={formatMessage(messages.ResultTitle)}
          />
          <Param
            onClick={() => handleChange('[r_number]')}
            help={formatMessage(messages.ResultNumberHelp)}
            example={'resultNumber=3'}
            label={formatMessage(messages.ResultNumber)}
          />
          <Param
            onClick={() => handleChange('[r_id]')}
            help={formatMessage(messages.ResultIdHelp)}
            example={'resultId=1234412'}
            label={formatMessage(messages.ResultID)}
          />
          <Param
            onClick={() => handleChange('[voter_id]')}
            help={formatMessage(messages.VoterIdHelp)}
            example={'voter_id=1234412'}
            label={'Voter ID'}
          />
          <Param
            onClick={() => handleChange('[puid]')}
            help={formatMessage(messages.UuidHelp)}
            example={'puid=XXX'}
            label={'PUID'}
          />
          <div className="py-2 mt-10 max-w-md px-4 text-sm text-gray-600 font-medium">
            {formatMessage(messages.MoreInfo)}{' '}
            <a
              className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
              href="https://platform.poltio.com/docs/pixelcodes/"
              target="_blank"
              rel="noreferrer"
            >
              {formatMessage(messages.Docs)}
            </a>
          </div>
        </div>
      </SlideOver.Body>
      <SlideOver.Footer />
    </SlideOver>
  );
};

export default injectIntl(PixelCodesForm);
