import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { getEmbedSettings } from '../../../api';
import { NotificationsCTX } from '../../../contexts/Notification';
import { getDefaultDomain, getUrlList } from '../../../core/helper';
import { Dropdown } from '../Form';
import { SketchPicker } from 'react-color';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Content, User } from '../../../core/types';
import { Disclosure } from '@headlessui/react';
import {
  ChevronDownIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/20/solid';

type ColorPickerProps = {
  color: string;
  onChange?: (e: any) => void;
};

type Props = {
  data: Content;
  embedCode: string;
  setEmbedCode: Dispatch<SetStateAction<string>>;
  widgetUrl: string;
  setWidgetUrl: Dispatch<SetStateAction<string>>;
  finalCode: string;
  setFinalCode: Dispatch<SetStateAction<string>>;
  width: string;
  setWidth: Dispatch<SetStateAction<string>>;
  height: string;
  setHeight: Dispatch<SetStateAction<string>>;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  url?: string;
  modal?: boolean;
  selectedIdxPrew?: number;
  user?: User;
  flyingQs: string;
  setFlyingQs: Dispatch<SetStateAction<string>>;
};

const convertRgbString = (val: any) => {
  const rgbString = `rgba(${val.r}, ${val.g}, ${val.b}, ${val.a})`;
  return rgbString;
};

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

const ColorPicker: FC<ColorPickerProps> = ({ color, onChange }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((s) => !s);
  };
  return (
    <>
      {show ? (
        <div style={{ position: 'absolute', zIndex: '20' }}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={toggleShow}
          />
          <SketchPicker color={`${color}`} onChange={onChange} />
        </div>
      ) : null}
      <div
        onClick={toggleShow}
        className="rounded-md"
        style={{
          backgroundColor: `${color}`,
          height: 38,
          width: 50,
          borderStyle: 'solid',
        }}
      />
    </>
  );
};

const EmbedSettings: FC<Props> = ({
  data,
  embedCode,
  setEmbedCode,
  widgetUrl,
  setWidgetUrl,
  finalCode,
  setFinalCode,
  width,
  setWidth,
  height,
  setHeight,
  type,
  setType,
  url,
  modal,
  selectedIdxPrew,
  user,
  setFlyingQs,
}) => {
  const [disclaimer, setDisclaimer] = useState(true);
  const [alignment, setAlignment] = useState('center');
  const [vertical, setVertical] = useState('off');
  const [resultfit, setResultFit] = useState('sc');
  const [openLinks, setOpenLinks] = useState('_blank');
  const [lang, setLang] = useState('default');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [nocookie, setCookie] = useState(false);
  const [puid, setPUID] = useState('');
  const [custom_id, setCustom_id] = useState('');
  const [noAutoResize, setNoAutoResize] = useState(false);
  const [bgColor, setBgColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [bg, setBg] = useState('');
  const [buttonIconColor, setButtonIconColor] = useState('');
  const [widgetIconColor, setWidgetIconColor] = useState('');
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [position, setPosition] = useState('');
  // const [sidebarPosition, setSidebarPosition] = useState('');
  const [flyingCode, setFlyingCode] = useState('');
  const [link, setLink] = useState(url);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [positionIdx, setPositionIdx] = useState(5);
  // const [sbPositionIdx, setSbPositionIdx] = useState(0);
  const [buttonZIndex, setButtonZIndex] = useState('');
  const [hideButton, setHideButton] = useState(false);
  const [initialPosition, setInitialPosition] = useState('');
  const [initialPositionIdx, setInitialPositionIdx] = useState(0);
  const [scrollThreshold, setScrollThreshold] = useState('');

  const { error, success } = useContext(NotificationsCTX);

  const { formatMessage } = useIntl();

  const langOptions = useMemo(
    () => [
      {
        name: 'DeviceLanguage',
        value: 'default',
      },
      {
        name: 'English',
        value: 'en',
      },
      {
        name: 'Turkish',
        value: 'tr',
      },
      {
        name: 'Spanish',
        value: 'es',
      },
      {
        name: 'Italian',
        value: 'it',
      },
      {
        name: 'Portuguese',
        value: 'pt',
      },
      {
        name: 'Arabic',
        value: 'ar',
      },
      {
        name: 'Austrian',
        value: 'de-at',
      },
      {
        name: 'Algerian',
        value: 'ar-dz',
      },
      {
        name: 'French',
        value: 'fr',
      },
      {
        name: 'German',
        value: 'de',
      },
      {
        name: 'Romanian',
        value: 'ro',
      },
      {
        name: 'Dutch',
        value: 'nl',
      },
      {
        name: 'Hebrew',
        value: 'he',
      },
      {
        name: 'Thai',
        value: 'th',
      },
      {
        name: 'Hungarian',
        value: 'hu',
      },
      {
        name: 'Egyptian',
        value: 'ar-eg',
      },
      {
        name: 'Moroccan',
        value: 'ar-ma',
      },
    ],
    []
  );

  const tabs = useMemo(
    () => [
      {
        name: 'Widget',
        current: type === 'flying',
        value: 'flying',
      },
      { name: 'Embed', current: type === 'iframe', value: 'iframe' },
      {
        name: 'Link',
        current: type === 'direct',
        value: 'direct',
      },
    ],
    [formatMessage, type]
  );

  const positionList = [
    { title: 'top-left' },
    { title: 'top-right' },
    { title: 'center-left' },
    { title: 'center-right' },
    { title: 'bottom-left' },
    { title: 'bottom-right' },
  ];

  const initialPositionList = [
    { title: 'collapsed' },
    { title: 'active' },
    { title: 'expanded' },
  ];

  const removeEmptyLines = (str: string) =>
    str
      .split(/\r?\n/)
      .filter((line) => line.trim() !== '')
      .join('\n');

  useEffect(() => {
    setWidgetUrl(
      `https://${link}/widget/${data.public_id}?disclaimer=${
        disclaimer ? 'on' : 'off'
      }${alignment === 'center' ? '' : `&align=${alignment}`}${
        vertical === 'off' ? '' : `&vertical=${vertical}`
      }${resultfit !== 'sc' ? `&resultfit=${resultfit}` : ''}${
        lang === 'default' ? '' : `&loc=${lang}`
      }${utmSource ? `&utm_source=${utmSource}` : ''}${
        utmMedium ? `&utm_medium=${utmMedium}` : ''
      }${utmCampaign ? `&utm_campaign=${utmCampaign}` : ''}${
        puid ? `&puid=${puid}` : ''
      }${custom_id ? `&custom_id=${custom_id}` : ''}${
        nocookie ? `&cookie=off` : ''
      }${openLinks === '_blank' ? '' : `&openlinks=${openLinks}`}`
    );
  }, [
    data,
    disclaimer,
    lang,
    utmCampaign,
    utmSource,
    utmMedium,
    resultfit,
    puid,
    alignment,
    nocookie,
    openLinks,
    vertical,
    setWidgetUrl,
    custom_id,
    link,
  ]);

  useEffect(() => {
    setFlyingCode(
      `<div
      class="poltio-widget-flying"
      data-poltio-widget-content="${data.public_id}"
      ${!disclaimer ? `data-poltio-widget-disclaimer=${'off'}` : ''}
      ${lang === 'default' ? '' : `data-poltio-widget-loc="${lang}"`}
      ${nocookie ? `data-poltio-widget-cookie="off"` : ''}
      ${openLinks !== '_blank' ? `data-poltio-widget-openlinks="${openLinks}"` : ''}
      ${resultfit !== 'sc' ? `data-poltio-widget-resultfit="${resultfit}"` : ''}
      ${puid ? `data-poltio-widget-puid="${puid}"` : ''}
      ${custom_id ? `data-poltio-widget-custom_id=${custom_id}` : ''}
      ${utmSource ? `data-poltio-widget-utm_source=${utmSource}` : ''}
      ${utmMedium ? `data-poltio-widget-utm_medium=${utmMedium}` : ''}
      ${utmCampaign ? `data-poltio-widget-utm_campaign=${utmCampaign}` : ''}
      ${bg ? `data-poltio-widget-bgcolor="${bg}"` : ''}
      ${bgColor ? `data-poltio-floating-bgcolor="${bgColor}"` : ''}
      ${textColor ? `data-poltio-floating-textcolor="${textColor}"` : ''}
      ${buttonIconColor ? `data-poltio-floating-icon-color="${buttonIconColor}"` : ''}
      ${widgetIconColor ? `data-poltio-floating-widget-icon-color="${widgetIconColor}"` : ''}
      ${text ? `data-poltio-floating-buttontext="${text}"` : ''}
      ${title ? `data-poltio-floating-title="${title}"` : ''} 
      ${desc ? `data-poltio-floating-desc="${desc}"` : ''}
      ${buttonZIndex ? `data-poltio-floating-zindex="${buttonZIndex}"` : ''}
      ${position && position !== 'bottom-right' ? `data-poltio-floating-position="${position}"` : ''}
      ${initialPosition && initialPosition !== 'collapsed' ? `data-poltio-floating-initial-position="${initialPosition}"` : ''}
      ${scrollThreshold ? `data-poltio-floating-scroll-threshold="${scrollThreshold}"` : ''}
></div>
<script id="poltiosdk" src=${link === 'poltio.com' ? '"https://sdk.poltio.com/poltio.js"' : `"https://${link}/poltio.js"`} defer>
</script>`
    );
  }, [
    data,
    disclaimer,
    lang,
    utmCampaign,
    utmSource,
    utmMedium,
    resultfit,
    puid,
    alignment,
    nocookie,
    openLinks,
    vertical,
    setWidgetUrl,
    height,
    width,
    noAutoResize,
    custom_id,
    bgColor,
    textColor,
    text,
    title,
    desc,
    bg,
    buttonZIndex,
    position,
    initialPosition,
    scrollThreshold,
    link,
  ]);

  useEffect(() => {
    setFlyingQs(
      `?content=${data.public_id}&diclaimer=${disclaimer ? 'on' : 'off'}&loc=${lang}&resultfit=${resultfit !== 'sc' ? `${resultfit}` : ''}&${bg ? `widgetbg='${bg}'` : ''}&${bgColor ? `floatingbg='${bgColor}'` : ''}&${textColor ? `floatingtext='${textColor}'` : ''}&${title ? `floatingtitle=${title}` : ''}&${desc ? `floatingdesc=${desc}` : ''}&${text ? `floatingbuttontext=${text}` : ''}&${buttonZIndex ? `floatingzindex=${buttonZIndex}` : ''}&${hideButton ? `floatinghidebutton=${hideButton}` : ''}&${position ? `floatingposition=${position}` : ''}&${buttonIconColor ? `iconcolor='${buttonIconColor}'` : ''}&${widgetIconColor ? `widgeticoncolor='${widgetIconColor}'` : ''}&${initialPosition ? `initialposition=${initialPosition}` : ''}`
    );
  });

  useEffect(() => {
    setEmbedCode(
      `<iframe id="poltio-embed-${
        data.public_id
      }" class="poltio-embed" src="${widgetUrl}" loading="lazy" width="${width}" height="${height}" frameBorder="0"  allowFullScreen="allowfullscreen" scrolling="yes" title="${
        data.title
      }" ${
        noAutoResize ? `resize="no"` : ''
      } allow="clipboard-write"></iframe><script src="https://sdk.poltio.com/widget.js" defer></script>`
    );
  }, [data, height, width, widgetUrl, setEmbedCode, noAutoResize]);

  useEffect(() => {
    setFinalCode(
      type === 'iframe'
        ? removeEmptyLines(embedCode)
        : type === 'direct'
          ? widgetUrl
          : removeEmptyLines(flyingCode)
    );
  }, [embedCode, setFinalCode, type, widgetUrl, flyingCode]);

  const urlList = getUrlList(user);

  const defaultCD = getDefaultDomain(user);

  useEffect(() => {
    !modal ? setLink(url) : setLink(defaultCD);
  }, [defaultCD, modal, url]);

  useEffect(() => {
    selectedIdxPrew
      ? setSelectedIdx(selectedIdxPrew)
      : setSelectedIdx(urlList.indexOf(link));
  }, [urlList, link, selectedIdx, selectedIdxPrew]);

  const copyEmbedCode = async () => {
    try {
      await navigator.clipboard.writeText(finalCode);
      success('Embed code copied.');
    } catch (e) {
      console.log(e);
      error('Error copying embed code!');
    }
  };

  const getData = useCallback(async () => {
    try {
      const { data: resp } = await getEmbedSettings('content');
      setWidth(resp.width || '100%');
      setHeight(resp.height || '800');
      setLang(resp.lang || 'default');
      setAlignment(resp.alignment || 'center');
      setResultFit(resp.resultfit || 'sc');
      setDisclaimer(
        typeof resp.disclaimer != 'undefined' ? resp.disclaimer : true
      );
      setUtmSource(resp.utmSource || '');
      setUtmMedium(resp.utmMedium || '');
      setUtmCampaign(resp.utmCampaign || '');
    } catch (e) {
      console.log(e);
      error('Error occured!');
    }
  }, [error, setHeight, setWidth]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleTypeChange = (e: any) => {
    e.preventDefault();
    setType(e.target.value);
  };

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          // defaultValue={tabs.find((tab) => tab.current).name}
          defaultValue={tabs[0].name}
          onChange={handleTypeChange}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-poltio-blue- focus:outline-none focus:ring-poltio-blue- sm:text-sm"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                aria-current={tab.current ? 'page' : undefined}
                value={tab.value}
                onClick={(e) => handleTypeChange(e)}
                className={classNames(
                  tab.current
                    ? 'border-poltio-blue-500 text-poltio-blue-500'
                    : 'border-transparent text-gray-500 dark:text-gray-200 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      {modal ? (
        <div className="mt-2 w-1/2">
          <label
            htmlFor="custom_id"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
          >
            {formatMessage(messages.SelectDomain)}
          </label>
          <Dropdown
            data={urlList}
            selectedIdx={selectedIdx}
            onChange={(i) => {
              setSelectedIdx(i);
              setLink(urlList[i]);
            }}
          />
        </div>
      ) : null}
      <div className="my-2">
        <div className="relative mt-1 sm:mt-0 sm:col-span-2">
          <textarea
            id="embedcode"
            name="embedcode"
            rows={7}
            className="shadow-sm block w-full focus:ring-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 focus:border-poltio-blue-500 sm:text-sm border border-gray-300 rounded-md"
            value={finalCode}
            readOnly
          />
          <div className="absolute inset-y-0 right-0 top-2 pr-3">
            <ClipboardDocumentIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400 cursor-pointer"
              onClick={copyEmbedCode}
            />
          </div>
        </div>
      </div>
      {type === 'flying' ? (
        <Disclosure
          key={'flying'}
          as="div"
          className="pt-6 border-b border-solid"
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left">
                <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                  {formatMessage(messages.WidgetStyling)}
                </span>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div>
                  <div className="my-2">
                    <label
                      htmlFor="data-text"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.Title)}
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Let us choose together"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="data-text"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.Description)}
                    </label>
                    <textarea
                      name="desc"
                      id="desc"
                      rows={2}
                      className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Not sure yet? Don’t worry! With a few simple questions we will guide you through the selection"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                    />
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="data-text"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.ButtonText)}
                    </label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Start Now"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="my-2">
                      <label
                        htmlFor="data-text"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.FloatingColor)}
                      </label>
                      <ColorPicker
                        onChange={(e) => {
                          setBgColor(convertRgbString(e.rgb));
                        }}
                        color={bgColor || 'rgba(0, 158, 235, 1)'}
                      />
                    </div>
                    <div className="my-2">
                      <label
                        htmlFor="data-text"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.FloatingTextColor)}
                      </label>
                      <ColorPicker
                        onChange={(e) => {
                          setTextColor(convertRgbString(e.rgb));
                        }}
                        color={textColor || 'rgba(243, 244, 246, 1)'}
                      />
                    </div>
                    <div className="my-2">
                      <label
                        htmlFor="data-text"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.SlideoverColor)}
                      </label>
                      <ColorPicker
                        onChange={(e) => {
                          setBg(convertRgbString(e.rgb));
                        }}
                        color={bg || 'rgba(243, 244, 246, 1)'}
                      />
                    </div>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="my-2">
                      <label
                        htmlFor="button-icon-color"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.ButtonIconColor)}
                      </label>
                      <ColorPicker
                        onChange={(e) => {
                          setButtonIconColor(convertRgbString(e.rgb));
                        }}
                        color={buttonIconColor || 'rgba(31,61,84,1)'}
                      />
                    </div>
                    <div className="my-2">
                      <label
                        htmlFor="widget-icon-color"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.WidgetIconColor)}
                      </label>
                      <ColorPicker
                        onChange={(e) => {
                          setWidgetIconColor(convertRgbString(e.rgb));
                        }}
                        color={widgetIconColor || 'rgba(31,61,84,1)'}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="flex items-center h-5 mt-2">
                      <input
                        id="hideButton"
                        name="hideButton"
                        type="checkbox"
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300 rounded"
                        checked={hideButton}
                        onChange={(e) => setHideButton(e.target.checked)}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-700 dark:text-gray-300"
                        >
                          {formatMessage(messages.HideButton)}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 w-1/2">
                    <label
                      htmlFor="custom_id"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.ButtonPosition)}
                    </label>
                    <Dropdown
                      data={positionList}
                      selectedIdx={positionIdx}
                      onChange={(i) => {
                        setPositionIdx(i);
                        setPosition(positionList[i].title);
                      }}
                    />
                  </div>
                  <div className="mt-2 w-1/2">
                    <label
                      htmlFor="custom_id"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.InitialPosition)}
                    </label>
                    <Dropdown
                      data={initialPositionList}
                      selectedIdx={initialPositionIdx}
                      onChange={(i) => {
                        setInitialPositionIdx(i);
                        setInitialPosition(initialPositionList[i].title);
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="buttonZIndex"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.ButtonZIndex)}
                    </label>
                    <input
                      type="text"
                      name="buttonZIndex"
                      id="buttonZIndex"
                      className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="100"
                      value={buttonZIndex}
                      onChange={(e) => setButtonZIndex(e.target.value)}
                    />
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="scrollThreshold"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.ScrollThreshold)}
                    </label>
                    <input
                      type="text"
                      name="scrollThreshold"
                      id="buttonZIndex"
                      className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="300"
                      value={scrollThreshold}
                      onChange={(e) => setScrollThreshold(e.target.value)}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ) : null}
      <div className="divide-y divide-gray-900/10">
        <Disclosure key={'lang'} as="div" className="pt-6">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left">
                <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                  {formatMessage(messages.LangSettings)}
                </span>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <fieldset className="mt-3 col-span-2">
                  <div className="mt-2 space-y-2 grid grid-cols-2">
                    {langOptions.map((l, i) => (
                      <div className="flex items-center" key={i}>
                        <input
                          id={l.value}
                          name="lang"
                          value={l.value}
                          type="radio"
                          checked={lang === l.value}
                          onChange={(e) => setLang(e.target.value)}
                          className="form-radio focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 checked:bg-poltio-blue-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={l.value}
                          className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                        >
                          {formatMessage(messages[l.name])}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure key={'puid'} as="div" className="pt-6">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left">
                <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                  {formatMessage(messages.PuidSettings)}
                </span>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="my-2">
                  <label
                    htmlFor="puid"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    PUID
                  </label>
                  <input
                    type="text"
                    name="puid"
                    id="puid"
                    className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="{{ user.hash }}"
                    value={puid}
                    onChange={(e) => setPUID(e.target.value)}
                  />
                  <p
                    className="mt-2 text-sm text-gray-500 dark:text-gray-400"
                    id="email-description"
                  >
                    {formatMessage(messages.PuidDesc)}
                    <a
                      className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                      href="https://platform.poltio.com/docs/options/#puid"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage(messages.Docs)}
                    </a>
                  </p>
                </div>
                <div className="my-2">
                  <label
                    htmlFor="custom_id"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    {formatMessage(messages.CustomID)}
                  </label>
                  <input
                    type="text"
                    name="custom_id"
                    id="custom_id"
                    className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="{{ user.custom_identifier }}"
                    value={custom_id}
                    onChange={(e) => setCustom_id(e.target.value)}
                  />
                  <p
                    className="mt-2 text-sm text-gray-500 dark:text-gray-400"
                    id="email-description"
                  >
                    {formatMessage(messages.CustomIdDesc)}
                    <a
                      className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                      href="https://platform.poltio.com/docs/options/#custom-id"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage(messages.Docs)}
                    </a>
                  </p>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure key={'cookiedisc'} as="div" className="pt-6">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left">
                <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                  {formatMessage(messages.CookieDisc)}
                </span>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="flex items-start my-2">
                  <div className="flex items-center h-5">
                    <input
                      id="disclaimer"
                      name="disclaimer"
                      type="checkbox"
                      className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300 rounded"
                      checked={disclaimer && !nocookie}
                      onChange={(e) => setDisclaimer(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm w-64">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.DisplayDisclaimer)}
                    </label>
                  </div>
                  <div className="text-sm">
                    <label
                      htmlFor="disclaimer"
                      className="block text-xs font-normal text-gray-500 dark:text-gray-400"
                    >
                      {formatMessage(messages.DisclaimerDesc)}
                    </label>
                  </div>
                </div>
                <div className="my-2 flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="nocookie"
                      name="nocookie"
                      type="checkbox"
                      className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300 rounded"
                      checked={nocookie}
                      onChange={(e) => setCookie(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm w-44">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.DontUseCookie)}
                    </label>
                  </div>
                  <div className="text-sm">
                    <label
                      htmlFor="nocookie"
                      className="block text-xs font-normal text-gray-500 dark:text-gray-400"
                    >
                      {formatMessage(messages.CookieDesc)}
                      <a
                        className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                        href="https://platform.poltio.com/docs/cookies/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {formatMessage(messages.LearnCookies)}
                      </a>
                    </label>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {type === 'iframe' ? (
          <Disclosure key={'alignment'} as="div" className="pt-6">
            {({ open }) => (
              <>
                <Disclosure.Button className="group flex w-full items-start justify-between text-left">
                  <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                    {formatMessage(messages.EmbedAlignment)}
                  </span>
                  <span className="ml-6 flex h-7 items-center">
                    <ChevronDownIcon
                      className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                  <div className="my-2 grid grid-cols-2">
                    <fieldset className="mt-3 col-span-1">
                      <div>
                        <legend className="text-base font-medium text-gray-900 dark:text-gray-200">
                          {formatMessage(messages.Alignment)}
                        </legend>
                      </div>
                      <div className="mt-2 space-y-2">
                        <div className="flex items-center">
                          <input
                            id="alignleft"
                            name="align"
                            value="left"
                            type="radio"
                            checked={alignment === 'left'}
                            onChange={(e) => setAlignment(e.target.value)}
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                          />
                          <label
                            htmlFor="alignleft"
                            className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            {formatMessage(messages.Left)}
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="aligncenter"
                            name="align"
                            value="center"
                            type="radio"
                            checked={alignment === 'center'}
                            onChange={(e) => setAlignment(e.target.value)}
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                          />
                          <label
                            htmlFor="aligncenter"
                            className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            {formatMessage(messages.Center)}
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="alignright"
                            name="align"
                            value="right"
                            type="radio"
                            checked={alignment === 'right'}
                            onChange={(e) => setAlignment(e.target.value)}
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                          />
                          <label
                            htmlFor="alignright"
                            className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            {formatMessage(messages.Right)}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="mt-3 col-span-1">
                      <div>
                        <legend className="text-base font-medium text-gray-900 dark:text-gray-200">
                          {formatMessage(messages.VerticalAlignment)}
                        </legend>
                      </div>
                      <div className="mt-2 space-y-2">
                        <div className="flex items-center">
                          <input
                            id="verticaloff"
                            name="vertical"
                            value="off"
                            type="radio"
                            checked={vertical === 'off'}
                            onChange={(e) => setVertical(e.target.value)}
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                          />
                          <label
                            htmlFor="vercitaloff"
                            className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            {formatMessage(messages.Off)}
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="verticaltop"
                            name="vertical"
                            value="top"
                            type="radio"
                            checked={vertical === 'top'}
                            onChange={(e) => setVertical(e.target.value)}
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                          />
                          <label
                            htmlFor="vercitaltop"
                            className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            {formatMessage(messages.Top)}
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="verticalcenter"
                            name="vertical"
                            value="center"
                            type="radio"
                            checked={vertical === 'center'}
                            onChange={(e) => setVertical(e.target.value)}
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                          />
                          <label
                            htmlFor="aligncenter"
                            className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            {formatMessage(messages.Center)}
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="verticalbottom"
                            name="vertical"
                            value="bottom"
                            type="radio"
                            checked={vertical === 'bottom'}
                            onChange={(e) => setVertical(e.target.value)}
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                          />
                          <label
                            htmlFor="verticalbottom"
                            className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            {formatMessage(messages.Bottom)}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : null}
        <Disclosure key={'resultfit'} as="div" className="pt-6">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left">
                <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                  {formatMessage(messages.ResultFitSettings)}
                </span>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <fieldset className="my-2 col-span-1">
                  <div className="mt-2 space-y-2">
                    <div className="flex items-center">
                      <input
                        id="rfoff"
                        name="resultfit"
                        value="off"
                        type="radio"
                        checked={resultfit === 'off'}
                        onChange={(e) => setResultFit(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="alignleft"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.Off)}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="rfsc"
                        name="resultfit"
                        value="sc"
                        type="radio"
                        checked={resultfit === 'sc'}
                        onChange={(e) => setResultFit(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="aligncenter"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.ResultImageCrop)}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="rffit"
                        name="resultfit"
                        value="fit"
                        type="radio"
                        checked={resultfit === 'fit'}
                        onChange={(e) => setResultFit(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="alignright"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.Fit)}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="rfvf"
                        name="resultfit"
                        value="vf"
                        type="radio"
                        checked={resultfit === 'vf'}
                        onChange={(e) => setResultFit(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="aligncenter"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.VerticalFit)}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure key={'resulturl'} as="div" className="pt-6">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left">
                <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                  {formatMessage(messages.ResultUrl)}
                </span>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <fieldset className="my-2 col-span-2">
                  <div className="mt-2 space-y-2">
                    <div className="flex items-center">
                      <input
                        id="self"
                        name="openLinks"
                        value="_self"
                        type="radio"
                        checked={openLinks === '_self'}
                        onChange={(e) => setOpenLinks(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="alignleft"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {'Self'}
                      </label>
                      <label
                        htmlFor="alignleft"
                        className="ml-3 block text-xs font-normal text-gray-500 dark:text-gray-400"
                      >
                        {formatMessage(messages.SelfDesc)}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="blank"
                        name="openLinks"
                        value="_blank"
                        type="radio"
                        checked={openLinks === '_blank'}
                        onChange={(e) => setOpenLinks(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="aligncenter"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {'Blank'}
                      </label>
                      <label
                        htmlFor="alignleft"
                        className="ml-3 block text-xs font-normal text-gray-500 dark:text-gray-400"
                      >
                        {formatMessage(messages.BlankDesc)}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="parent"
                        name="openLinks"
                        value="_parent"
                        type="radio"
                        checked={openLinks === '_parent'}
                        onChange={(e) => setOpenLinks(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="alignright"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {'Parent'}
                      </label>
                      <label
                        htmlFor="alignleft"
                        className="ml-3 block text-xs font-normal text-gray-500 dark:text-gray-400"
                      >
                        {formatMessage(messages.ParentDesc)}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="top"
                        name="openLinks"
                        value="_top"
                        type="radio"
                        checked={openLinks === '_top'}
                        onChange={(e) => setOpenLinks(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="aligncenter"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {'Top'}
                      </label>
                      <label
                        htmlFor="alignleft"
                        className="ml-3 block text-xs font-normal text-gray-500 dark:text-gray-400"
                      >
                        {formatMessage(messages.TopDesc)}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="none"
                        name="openLinks"
                        value="none"
                        type="radio"
                        checked={openLinks === 'none'}
                        onChange={(e) => setOpenLinks(e.target.value)}
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300"
                      />
                      <label
                        htmlFor="aligncenter"
                        className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        {'None'}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {type === 'iframe' ? (
          <Disclosure key={'sizing'} as="div" className="pt-6">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-start justify-between text-left">
                  <span className="text-base font-medium text-gray-700 dark:text-gray-200 leading-7">
                    {formatMessage(messages.Sizing)}
                  </span>
                  <span className="ml-6 flex h-7 items-center">
                    <ChevronDownIcon
                      className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 dark:text-gray-200`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="mt-2">
                    <label
                      htmlFor="width"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.Width)}
                    </label>
                    <input
                      type="text"
                      name="width"
                      id="width"
                      autoComplete="new-password"
                      className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="iframe width"
                      value={width}
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="height"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      {formatMessage(messages.Height)}
                    </label>
                    <input
                      type="text"
                      name="height"
                      id="height"
                      autoComplete="new-password"
                      className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="iframe height"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </div>
                  <div className="my-2 flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="noAutoResize"
                        name="noAutoResize"
                        type="checkbox"
                        className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-400 border-gray-300 rounded"
                        checked={noAutoResize}
                        onChange={(e) => setNoAutoResize(e.target.checked)}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="noAutoResize"
                        className="font-medium text-gray-700 dark:text-gray-300"
                      >
                        {formatMessage(messages.DontUseResize)}
                      </label>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : null}
      </div>
    </>
  );
};

export default EmbedSettings;
