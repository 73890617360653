import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
// @ts-ignore:next-line
import { FormattedMessage } from 'react-intl.macro';
import { injectIntl } from 'react-intl';
import { isValidUrl } from '../../core/helper';
import messages from './messages';
import { webhooksSave } from '../../api';
import SlideOver from '../Common/SlideOver';
import TextInput from '../Common/Form/TextInput';
import TextAreaInput from '../Common/Form/TextAreaInput';
import Label from '../Common/Form/Label';
import ContentSearch from '../Common/Form/ContentSearch';
import { Helmet } from 'react-helmet';
import { WebHook } from '../../core/types';

const sampleCode = `{
  "poltio_user_id": 1234,
  "session_id": "Poltio Session ID",
  "session_start_date": "2024-01-23T11:59:33.000000Z",
  "session_end_date": "2024-01-23T11:59:59.000000Z",
  "publisher_uuid": "Publisher UUID",
  "custom_id": "Custom Publisher ID",
  "event_time": 1568624875,
  "content_type": "Content type",
  "content_id": 12345,
  "complete_session": true,
  "answers": [
      {
          "question_id": 1234,
          "question": "Question Title",
          "answers": [
              {
                  "answer_id": 1234,
                  "answer": "Voted answer title"
              }
          ]
      }
  ],
  "result": {
      "result_id": 1234,
      "result_title": "Result Title",
      "result_image": "content/gcp/1678957893.2458.png",
      "result_desc": "Result Description",
      "time_spent": 16789,
      "right_answer": 0,
      "calculator_result": "0"
  },
  "leads": [
    {
       "user_id": 1234,
       "phone": "+1231231234",
       "email": "example@example.com",
       "name": "Name",
       "comment": "Comment",
       "created_at": "2024-01-23T11:59:43.000000Z",
       "tc_checked": true,
       "content_type" : "Content type",
       "content_id": 12345
    }
]
}`;

const defaultData = {
  name: '',
  delay: 10,
  url: '',
  send_answers: true,
  send_leads: true,
  incomplete_send: false,
  incomplete_delay: 60,
  public_id: '',
  account_wide: false,
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hook: WebHook;
  handleSubmit: () => {};
  success: any;
  error: any;
  intl: any;
  selectedContent: string;
};

const WebhookForm: FC<Props> = ({
  open,
  setOpen,
  hook,
  success,
  error,
  handleSubmit,
  intl: { formatMessage },
  selectedContent,
}) => {
  const [data, setData] = useState<WebHook>(defaultData);
  const [saving, setSaving] = useState(false);
  const [id, setId] = useState('');
  const [bodyInputs, setBodyInputs] = useState<any>([]);
  const [headerInputs, setHeaderInputs] = useState<any>([]);

  useEffect(() => {
    if (selectedContent) {
      setId(selectedContent);
    }
    if (hook.id) {
      setId(hook.public_id || '');
      setData(hook);

      setHeaderInputs(
        hook.oauth_request_headers
          ? Object.keys(hook.oauth_request_headers).map((key) => [
              key,
              hook.oauth_request_headers[key],
            ])
          : []
      );
      setBodyInputs(
        hook.oauth_request_body
          ? Object.keys(hook.oauth_request_body).map((key) => [
              key,
              hook.oauth_request_body[key],
            ])
          : []
      );
    } else {
      setData(defaultData);
    }
  }, [open, hook, selectedContent]);

  useEffect(() => {
    setData((d) => {
      return {
        ...d,
        incomplete_delay: data.incomplete_send
          ? Math.max(d.incomplete_delay, d.delay, 60)
          : 0,
      };
    });
  }, [data.incomplete_send]);

  useEffect(() => {
    setData((d) => {
      return {
        ...d,
      };
    });
  }, [data.send_answers, data.send_leads, data.account_wide, data.use_oauth]);

  useEffect(() => {
    if (id !== '' || null) {
      setData({ ...data, public_id: id });
    } else {
      delete data.public_id;
      setData(data);
    }
  }, [id, data.public_id]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    if (formValid() && !saving) {
      try {
        setSaving(true);

        const bodyObj = bodyInputs.reduce(
          (acc: any, cur: string) => ({ ...acc, [cur[0]]: cur[1] }),
          {}
        );

        const headerObj = headerInputs.reduce(
          (acc: any, cur: string) => ({ ...acc, [cur[0]]: cur[1] }),
          {}
        );

        await webhooksSave({
          ...data,
          hook_id: hook.id,
          oauth_request_body: bodyObj,
          oauth_request_headers: headerObj,
        });
        success(formatMessage(messages.WebhookSaved));
        handleSubmit();
        setSaving(false);
        onClose();
      } catch (e: any) {
        const errorStatus = e?.response?.status ?? -1;
        switch (errorStatus) {
          case 400:
            error('You are trying to add more than 2 account wide hooks.');
            break;
          case 422:
            error(e?.response?.data?.msg);
            break;
          default:
            return error(formatMessage(messages.ErrorSaving));
        }
        setSaving(false);
      }
    }
  };

  const formValid = () => {
    const {
      incomplete_send,
      incomplete_delay,
      delay,
      name,
      account_wide,
      use_oauth,
    } = data;
    if (!account_wide && id?.length < 1) {
      error(formatMessage(messages.ErrorContentEmpty));
      return false;
    }
    if (name?.length < 1) {
      error(formatMessage(messages.ErrorNameEmpty));
      return false;
    }
    if (!isValidUrl(data.url)) {
      error(formatMessage(messages.EnterValidUrl));
      return false;
    }
    if (incomplete_send) {
      if (incomplete_delay < delay || incomplete_delay < 60) {
        error(formatMessage(messages.ErrorIncompleteDelay));
        return false;
      }
    }

    if (use_oauth && !isValidUrl(data?.oauth_login_endpoint)) {
      error(formatMessage(messages.EnterValidUrl));
      return false;
    }
    return true;
  };

  const onSelect = (item: any) => {
    setId(item.public_id);
  };

  const handleAccWide = (e: any) => {
    e.target.checked
      ? setId('')
      : hook.public_id
        ? setId(hook.public_id)
        : setId(id);
    setData({
      ...data,
      account_wide: e.target.checked ? true : false,
    });
  };

  const handleAddBodyInput = () => {
    setBodyInputs([...bodyInputs, []]);
  };

  const handleDeleteBodyInput = (index: number) => {
    const newArray = [...bodyInputs];
    newArray.splice(index, 1);
    setBodyInputs(newArray);
  };

  const handleBodyChange = (event: any, i: number, x: number) => {
    let { value } = event.target;
    let onChangeValue = [...bodyInputs];
    onChangeValue[i][x] = value;

    const otherElement = x === 0 ? onChangeValue[i][1] : onChangeValue[i][0];

    onChangeValue[i][x === 0 ? 1 : 0] = otherElement ?? '';

    setBodyInputs(onChangeValue);
  };

  const handleAddHeaderInput = () => {
    setHeaderInputs([...headerInputs, []]);
  };

  const handleDeleteHeaderInput = (index: number) => {
    const newArray = [...headerInputs];
    newArray.splice(index, 1);
    setHeaderInputs(newArray);
  };

  const handleHeaderChange = (event: any, i: number, x: number) => {
    let { value } = event.target;
    let onChangeValue = [...headerInputs];
    onChangeValue[i][x] = value;

    const otherElement = x === 0 ? onChangeValue[i][1] : onChangeValue[i][0];

    onChangeValue[i][x === 0 ? 1 : 0] = otherElement ?? '';

    setHeaderInputs(onChangeValue);
  };

  const onClose = () => {
    setOpen(false);
    setHeaderInputs([]);
    setBodyInputs([]);
    setData(defaultData);
  };

  return (
    <SlideOver
      open={open}
      setOpen={() => {}}
      isLoading={saving}
      onSave={onFormSubmit}
      isDisabled={saving}
      onCancel={onClose}
    >
      <Helmet>
        <title>
          {data.name
            ? `Editing Webhook: ${data.name} | Poltio Platform`
            : `Creating Webhook | Poltio Platform`}
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <SlideOver.Header
        title={
          hook.id
            ? formatMessage(messages.EditWebhook)
            : formatMessage(messages.CreateNewWebHook)
        }
        subtitle={
          hook.id ? (
            <FormattedMessage
              id="Webhook.CanEdit"
              defaultMessage="You can edit and save your Webhook for {content_type} #{content_id}"
              values={{
                content_id: data.content_id,
                content_type: data.content_type,
              }}
            />
          ) : null
        }
        setOpen={onClose}
        children={null}
      />

      <SlideOver.Body>
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-5 pt-6 pb-5">
            {!data?.account_wide ? (
              <div>
                <ContentSearch
                  onSelect={onSelect}
                  publicId={id || data.public_id || ''}
                />
              </div>
            ) : null}
            <div>
              <TextInput
                label={formatMessage(messages.Name)}
                type="text"
                name="name"
                value={data.name}
                onChange={(e: any) =>
                  setData({ ...data, name: e.target.value })
                }
                debounce={null}
              />
            </div>
            <div>
              <TextAreaInput
                autogrow
                label="Url"
                rows={2}
                name="url"
                value={data.url}
                onChange={(e: any) => setData({ ...data, url: e.target.value })}
                debounce={null}
              />
            </div>
            <div className="pt-2">
              <p className="mt-2 text-xs text-gray-400">
                {formatMessage(messages.WebhookDesc)}
              </p>
              <p className="p-2 mt-2 text-xs text-gray-300 bg-gray-600 bg-opacity-80 rounded-lg">
                https://yourapp.com/
                <span className="font-bold text-green-400 ">
                  {'webhook-endpoint'}
                </span>
              </p>
            </div>
            <div>
              <Label
                htmlFor="delay"
                font="medium"
                text={formatMessage(messages.Delay)}
              />
              <p className="mt-2 text-sm text-gray-500">
                {formatMessage(messages.DelayDesc)}
              </p>
              <TextInput
                type="number"
                name="delay"
                value={data.delay}
                onChange={(e: any) =>
                  setData({ ...data, delay: parseInt(e.target.value) })
                }
                debounce={null}
              />
            </div>

            <fieldset className="space-y-5">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="send_answers"
                    aria-describedby="send_answers-description"
                    name="send_answers"
                    type="checkbox"
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                    checked={data.send_answers}
                    onChange={(e) =>
                      setData({
                        ...data,
                        send_answers: e.target.checked ? true : false,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="send_answers"
                    className="font-medium text-gray-700 dark:text-gray-300"
                  >
                    {formatMessage(messages.SendAnswers)}
                  </label>
                  <p
                    id="send-answers-description"
                    className="text-gray-500 dark:text-gray-400"
                  >
                    {formatMessage(messages.SendAllData)}
                  </p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="send_leads"
                    aria-describedby="send_leads-description"
                    name="send_leads"
                    type="checkbox"
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                    checked={data.send_leads}
                    onChange={(e) =>
                      setData({
                        ...data,
                        send_leads: e.target.checked ? true : false,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="send_leads"
                    className="font-medium text-gray-700 dark:text-gray-300"
                  >
                    {formatMessage(messages.SendLeads)}
                  </label>
                  <p
                    id="send-leads-description"
                    className="text-gray-500 dark:text-gray-400"
                  >
                    {formatMessage(messages.SendAllLeads)}
                  </p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="incomplete_send"
                    aria-describedby="incomplete_send-description"
                    name="incomplete_send"
                    type="checkbox"
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                    checked={data.incomplete_send}
                    onChange={(e) =>
                      setData({
                        ...data,
                        incomplete_send: e.target.checked ? true : false,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="incomplete_send"
                    className="font-medium text-gray-700 dark:text-gray-300"
                  >
                    {formatMessage(messages.SendIncomplete)}
                  </label>
                  <p
                    id="incomplete_send-description"
                    className="text-gray-500 dark:text-gray-400"
                  >
                    {formatMessage(messages.Triggers)}
                  </p>
                </div>
              </div>
            </fieldset>
            {data.incomplete_send ? (
              <div>
                <label
                  htmlFor="incomplete_delay"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-200"
                >
                  {formatMessage(messages.IncompleteDelay)}
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  {formatMessage(messages.IncompleteDesc)}
                </p>
                <div className="mt-2">
                  <input
                    type="number"
                    name="incomplete_delay"
                    id="incomplete_delay"
                    className="block w-full shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md"
                    value={data.incomplete_delay}
                    onChange={(e) =>
                      setData({
                        ...data,
                        incomplete_delay: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            ) : null}
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="account_wide"
                  aria-describedby="account_wide-description"
                  name="account_wide"
                  type="checkbox"
                  className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                  checked={data.account_wide}
                  onChange={handleAccWide}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="account_wide"
                  className="font-medium text-gray-700 dark:text-gray-300"
                >
                  {formatMessage(messages.AccountWide)}
                </label>
                <p
                  id="account_wide-description"
                  className="text-gray-500 dark:text-gray-400"
                >
                  {formatMessage(messages.AccountWideDesc)}
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="use_oauth"
                  aria-describedby="use_oauth-description"
                  name="use_oauth"
                  type="checkbox"
                  className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                  checked={data.use_oauth ?? false}
                  onChange={(e) =>
                    setData({
                      ...data,
                      use_oauth: e.target.checked ? true : false,
                    })
                  }
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="use_oauth"
                  className="font-medium text-gray-700 dark:text-gray-300"
                >
                  {'Use Oauth'}
                </label>
                <p
                  id="account_wide-use_oauth"
                  className="text-gray-500 dark:text-gray-400"
                >
                  {
                    'If your webhook endpoint requires a standard OAuth2 Bearer authorization, you can select this field to enable oauth flow for your webhook.'
                  }
                </p>
              </div>
            </div>
            {data.use_oauth ? (
              <>
                <div>
                  <div>
                    <TextAreaInput
                      autogrow
                      label="Oauth Login Endpoint"
                      rows={2}
                      name="url"
                      value={data.oauth_login_endpoint ?? ''}
                      onChange={(e: any) =>
                        setData({
                          ...data,
                          oauth_login_endpoint: e.target.value,
                        })
                      }
                      debounce={null}
                    />
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      This is the token request endpoint for your oauth flow
                    </p>
                  </div>
                  <label
                    htmlFor="incomplete_delay"
                    className="block text-sm font-medium text-gray-900 dark:text-gray-300 mt-2"
                  >
                    Request Header
                  </label>
                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    If you want to customize the oauth token request header, you
                    can add any number of headers here
                  </p>
                  <div className="mt-2"></div>
                  <div className="">
                    {headerInputs.map((item: any, index: number) => (
                      <div className="grid grid-cols-12 gap-2 mt-2" key={index}>
                        <input
                          name="key"
                          type="text"
                          className="col-span-5 shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md"
                          value={item[0] ?? ''}
                          onChange={(event) =>
                            handleHeaderChange(event, index, 0)
                          }
                        />
                        <input
                          name="value"
                          type="text"
                          className="col-span-5 shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md"
                          value={item[1] ?? ''}
                          onChange={(event) =>
                            handleHeaderChange(event, index, 1)
                          }
                        />
                        {headerInputs.length > 0 && (
                          <button
                            className="text-sm font-semibold text-red-500 w-min col-span-2"
                            onClick={() => handleDeleteHeaderInput(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      className="text-poltio-blue text-sm font-semibold mt-2"
                      onClick={() => handleAddHeaderInput()}
                    >
                      + Add Another Header Field
                    </button>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="incomplete_delay"
                    className="block text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Request Body
                  </label>
                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    If you want to customize the oauth token request body, you
                    can add any number of fields here.
                  </p>
                  <div className="mt-2"></div>
                  <div className="">
                    {bodyInputs.map((item: any, index: number) => (
                      <div className="grid grid-cols-12 gap-2 mt-2" key={index}>
                        <input
                          name="key"
                          type="text"
                          className="col-span-5 shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md"
                          value={item[0] ?? ''}
                          onChange={(event) =>
                            handleBodyChange(event, index, 0)
                          }
                        />
                        <input
                          name="value"
                          type="text"
                          className="col-span-5 shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md"
                          value={item[1] ?? ''}
                          onChange={(event) =>
                            handleBodyChange(event, index, 1)
                          }
                        />
                        {bodyInputs.length > 0 && (
                          <button
                            className="text-sm font-semibold text-red-500 w-min col-span-2"
                            onClick={() => handleDeleteBodyInput(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      className="text-poltio-blue text-sm font-semibold mt-2"
                      onClick={() => handleAddBodyInput()}
                    >
                      + Add Another Body Input
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            <div className="mt-2">
              <p className="text-xs text-gray-400 mt-2">
                {formatMessage(messages.SendDesc)}
              </p>
              <div className="p-2 mt-2 text-xs text-gray-300 bg-gray-600 bg-opacity-80 rounded-lg">
                <div className="font-bold text-green-400 overflow-x-auto">
                  <p className="text-red-400">
                    {formatMessage(messages.To)}
                    {': '}
                    <span className="text-green-400">
                      yourapp.com/webhook-endpoint
                    </span>
                  </p>
                  <p className="text-red-400">
                    {formatMessage(messages.Method)}
                    {': '}
                    <span className="text-green-400">{'POST'}</span>
                  </p>
                  <p className="text-red-400">
                    {formatMessage(messages.Message)}
                    {': '}
                  </p>
                  <pre>
                    <code>
                      {JSON.stringify(JSON.parse(sampleCode), null, 2)}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
            <div className="py-2 mt-10 max-w-md text-sm text-gray-600 dark:text-gray-400 font-semibold">
              {formatMessage(messages.MoreInfo)}{' '}
              <a
                className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                href="https://platform.poltio.com/docs/webhooks/"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage(messages.Docs)}
              </a>
            </div>
          </div>
        </div>
      </SlideOver.Body>
      <SlideOver.Footer />
    </SlideOver>
  );
};

export default injectIntl(WebhookForm);
