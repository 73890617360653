import { useContext, useEffect, useRef, useState } from 'react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import {
  CheckIcon,
  ScissorsIcon,
  TrashIcon,
  CloudArrowUpIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import messages from './messages';

import { NotificationsCTX } from '../../../contexts/Notification';
import ImageButton from './ImageButton';
import Button from '../Button';
import Tooltip from '../Button/Tooltip';

import type { WrappedComponentProps } from 'react-intl';
import type { Crop } from 'react-image-crop';
import ImageSearch from './ImageSearch';

type Props = {
  src?: string;
  callback?: (image_url: string) => void;
  aspect?: number;
  forceCrop?: boolean;
  showOverlay?: () => void;
  closeOverlay?: () => void;
} & WrappedComponentProps;

const ImageInputContent = (props: Props) => {
  const {
    src,
    callback,
    aspect = 8 / 5,
    forceCrop,
    showOverlay = () => {},
    closeOverlay = () => {},
    intl: { formatMessage },
  } = props;

  const { error } = useContext(NotificationsCTX);

  const imageRef = useRef<HTMLImageElement>(null);

  const [_src, setSrc] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [scale, setScale] = useState({ w: 1, h: 1 });
  const [isCropping, setIsCropping] = useState(false);
  const [ext, setExt] = useState('jpeg');

  useEffect(() => {
    setSrc(src ?? '');
    if (forceCrop) {
      setIsCropping(!!src);
    }
  }, [src]);

  function updateCrop() {
    const target = imageRef.current;
    if (!target) {
      return;
    }

    let w = target?.offsetWidth ?? 0;
    let h = target?.offsetHeight ?? 0;

    if (w === 0 || h === 0) {
      return;
    }

    setCrop(makeAspectCrop({ unit: 'px', width: w }, aspect, w, h));
  }
  useEffect(updateCrop, [isCropping, aspect]);

  function onToggleCrop() {
    setIsCropping((prev) => !prev);
  }

  function onUploadChange(event: Event) {
    const files = (event.target as HTMLInputElement)?.files;
    if (!files || files!.length === 0) {
      return;
    }
    const selection = files[0];
    if (!selection) {
      return;
    }

    showOverlay();

    const fSize = selection.size;
    const fSizeMB = Math.round(fSize / (1024 * 1024));
    if (fSizeMB > 5) {
      error(formatMessage(messages.SizeErrorMessage));
      closeOverlay();
      return;
    }

    const reader = new window.FileReader();
    reader.onload = async () => {
      if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(selection.name)) {
        error('Unsupported file format');
        closeOverlay();
        return;
      }

      const img64 = reader.result;

      if (img64 === '') {
        error('Could not read image. Image data is empty');
        closeOverlay();
        return;
      }

      let ext = selection.name.split('.').pop()?.toLowerCase() || 'jpeg';
      ext = ext === 'jpg' ? 'jpeg' : ext;
      setExt(ext);

      setSrc(img64?.valueOf() as string);

      closeOverlay();
    };
    reader.readAsDataURL(selection);
    if (forceCrop) {
      setIsCropping(true);
    }
  }

  function onUploadClick() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute(
      'accept',
      'image/gif, image/jpeg, image/tiff, image/png'
    );
    input.onchange = onUploadChange;
    input.click();
  }

  function onImageLoaded(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    updateCrop();

    let scale = { w: 0, h: 0 };
    let image = event.currentTarget;
    let container = imageRef.current;

    if (container === null) {
      // if container is not set, do nothing
      return;
    }

    scale.w = image.naturalWidth / container.offsetWidth;
    scale.h = image.naturalHeight / container.offsetHeight;

    setScale(scale);
  }

  function onCropClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    if (!crop) {
      // if crop is not set, do nothing
      return;
    }

    showOverlay();

    const dx = scale.w * crop.width;
    const dy = scale.h * crop.height;

    // do crop
    const canvas = document.createElement('canvas');
    canvas.width = dx;
    canvas.height = dy;

    let ctx = canvas.getContext('2d') ?? new CanvasRenderingContext2D();

    let img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.addEventListener(
      'load',
      () => {
        const sx = crop.x * scale.w;
        const sy = crop.y * scale.h;

        ctx.drawImage(img, sx, sy, dx, dy, 0, 0, dx, dy);

        const b64 = canvas.toDataURL(`image/${ext}`);
        // wait for visual feed back
        setTimeout(() => {
          setSrc(b64);
          closeOverlay();
          setIsCropping(false);
        }, 200);
      },
      false
    );
    img.src = _src;
  }

  function onRemove(event: any) {
    setSrc('');
    setIsCropping(false);
  }

  const onImageSearchSelect = (image: any) => {
    setSrc(image);
    setIsCropping(true);
  };

  return (
    <div className="poltio-image-input-content min-w-full" aria-hidden="true">
      <div className="mb-2 pb-0 inline-flex w-full justify-center">
        <div
          className="relative inline-flex w-full justify-center"
          style={{ filter: 'drop-shadow(0px 0px 4px #aaacaf)' }}
        >
          <ReactCrop
            crop={isCropping ? crop : undefined}
            aspect={aspect}
            onChange={(crop, pCrop) => setCrop(crop)}
            disabled={!isCropping}
            className="border-0 border-transparent border-none"
          >
            {_src ? (
              <img
                src={_src}
                onLoad={onImageLoaded}
                alt="CropInput"
                ref={imageRef}
              />
            ) : null}
          </ReactCrop>
          {!_src ? (
            <div className="h-48 w-full pb-5">
              <ImageButton onClick={onUploadClick}>
                <div>{formatMessage(messages.UploadFile)}</div>
              </ImageButton>
            </div>
          ) : null}
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-between px-2">
          <span className="relative z-0 inline-flex shadow-sm rounded-sm -space-x-px">
            <button className="group upload-btn" onClick={onUploadClick}>
              <CloudArrowUpIcon className="h-5 w-5" />
              <Tooltip title={formatMessage(messages.UploadImage)} />
              {/* {fileName ? fileName : formatMessage(messages.UploadFile)} */}
            </button>
          </span>
          <span className="relative z-0 inline-flex shadow-sm rounded-sm -space-x-px">
            {isCropping ? (
              <>
                <button className="group crop-accept-btn" onClick={onCropClick}>
                  <CheckIcon className="h-5 w-5 text-white" />
                  <Tooltip title={formatMessage(messages.ConfirmSelection)} />
                </button>
                {forceCrop ? null : (
                  <button className="crop-cancel-btn" onClick={onToggleCrop}>
                    <XMarkIcon className="h-5 w-5 text-white" />
                  </button>
                )}
              </>
            ) : (
              <button
                className="group crop-start-btn"
                disabled={!_src}
                onClick={onToggleCrop}
              >
                <ScissorsIcon className="h-5 w-5" />
                <Tooltip title={formatMessage(messages.CropImage)} />
              </button>
            )}
            <button
              className="group remove-img-btn"
              disabled={!_src}
              onClick={onRemove}
            >
              <TrashIcon className="h-5 w-5" />
              <Tooltip title={formatMessage(messages.Remove)} />
            </button>
          </span>
        </div>
      </div>

      <div className="inline-flex w-full justify-center mt-1">
        <Button.Primary
          type="submit"
          className={''}
          disabled={isCropping && forceCrop}
          onClick={() => {
            callback?.(_src);
          }}
        >
          {formatMessage(messages.Done)}
        </Button.Primary>
      </div>
      <ImageSearch onSelect={onImageSearchSelect} />
    </div>
  );
};

export default injectIntl(ImageInputContent);
