import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { LinkIcon } from '@heroicons/react/24/outline';

import { saveDomain } from '../../api';
import SlideOver from '../Common/SlideOver';

const defaultData = {
  domain: '',
  is_active: 1,
  is_default: 0,
};

const DomainForm = ({
  open,
  setOpen,
  domain,
  success,
  error,
  handleSubmit,
  intl: { formatMessage },
  statusList,
}) => {
  const [data, setData] = useState(defaultData);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (domain.id) {
      setData(domain);
    } else {
      setData(defaultData);
    }
  }, [open, domain]);

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (!saving) {
      try {
        setSaving(true);
        await saveDomain({
          ...data,
          domain: clearUrl(data.domain),
          id: domain.id,
        });
        success('Domain Saved!');
        handleSubmit();
        setSaving(false);
        setOpen(false);
      } catch (e) {
        setSaving(false);
        const errorStatus = e?.response?.status;
        switch (errorStatus) {
          case 422:
            error(formatMessage(messages.Error422));
            break;
          case 403:
            error(formatMessage(messages.Error403));
            break;
          case 404:
            error(formatMessage(messages.Error404));
            break;
          case 409:
            error(formatMessage(messages.Error409));
            break;
          default:
            return error(formatMessage(messages.Error));
        }
      }
    }
  };

  function clearUrl(url) {
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
      url = 'https://' + url;
    }
    url = new URL(url);
    return url.hostname;
  }

  return (
    <SlideOver
      open={open}
      setOpen={() => {}}
      isLoading={saving}
      onSave={!domain.id ? onFormSubmit : null}
      isDisabled={saving || !data.domain}
      onCancel={() => setOpen(false)}
    >
      <SlideOver.Header
        title={
          domain.id
            ? formatMessage(messages.Setup)
            : formatMessage(messages.CreateNew)
        }
        setOpen={setOpen}
      />

      <SlideOver.Body>
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="pt-6 pb-5 space-y-6">
            <div>
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >
                {formatMessage(messages.CompanyDomain)}
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-900/30 dark:text-gray-300 px-3 text-gray-500 sm:text-sm">
                  https://
                </span>
                {domain.id ? (
                  <input
                    type="text"
                    name="company-website"
                    id="company-website"
                    className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 dark:bg-gray-900/30 dark:text-gray-200 dark:placeholder:text-gray-300 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue sm:text-sm sm:leading-6"
                    placeholder="poltio.yourshop.com"
                    value={clearUrl(domain.domain)}
                    readOnly
                  />
                ) : (
                  <input
                    type="text"
                    name="company-website"
                    id="company-website"
                    className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 dark:bg-gray-900/30 dark:text-gray-200 dark:placeholder:text-gray-300 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue sm:text-sm sm:leading-6"
                    placeholder="poltio.yourshop.com"
                    value={data.domain}
                    onChange={(e) =>
                      setData({ ...data, domain: e.target.value })
                    }
                  />
                )}
              </div>
              <div>
                <div className="text-gray-500 text-sm my-4 bg-gray-200 dark:bg-gray-600 dark:text-gray-200 py-4 p-2 rounded-lg">
                  <p>{formatMessage(messages.WillLook)}</p>
                  <p className="flex gap-x-2 text-center mt-2 font-bold">
                    <LinkIcon className="h-4 w-4" />
                    {data.domain || 'poltio.yourshop.com'}/widget/contentId
                  </p>
                </div>
                {domain.id ? (
                  <div className="">
                    <p className="text-gray-500 text-xs my-4">
                      {formatMessage(messages.YouNeed)}
                      <br />
                      <br />
                      {formatMessage(messages.OneCname)}
                      <br />
                      <br />
                      {formatMessage(messages.DnsRecord)}
                    </p>

                    <h3 className="text-gray-700 dark:text-gray-300 text-xs items-center text-center font-bold mb-2 gap-x-2 flex">
                      {formatMessage(messages.CnameRecord)}{' '}
                      <span>
                        {
                          statusList.filter((s) => s.name === domain.status)[0]
                            ?.icon
                        }
                      </span>
                    </h3>
                    <div className="p-2 text-xs font-semibold text-gray-300 bg-gray-600 bg-opacity-80 rounded-lg">
                      <p className="mt-2 ">
                        Name:{' '}
                        <span className="font-bold text-white">
                          _acme-challenge.{clearUrl(domain.domain)}.
                        </span>
                      </p>
                      <p className="mt-2">
                        Type:{' '}
                        <span className="font-bold text-white">CNAME</span>
                      </p>
                      <p className="mt-2">
                        Value:{' '}
                        <span className="font-bold text-white">
                          {domain.verification_record}
                        </span>
                      </p>
                      <p className="mt-2">
                        TTL ({formatMessage(messages.TimeTo)}:{' '}
                        <span className="font-bold text-white">300</span>
                      </p>
                    </div>
                    <h3 className="text-gray-700 dark:text-gray-300 text-xs items-center text-center gap-x-2 font-bold my-2 flex">
                      {formatMessage(messages.ARecord)}
                      <span>
                        {
                          statusList.filter((s) => s.name === domain.status)[0]
                            ?.icon
                        }
                      </span>
                    </h3>
                    <div className="p-2 text-xs font-semibold text-gray-300 bg-gray-600 bg-opacity-80 rounded-lg">
                      <p className="mt-2 ">
                        Name:{' '}
                        <span className="font-bold text-white">
                          {clearUrl(domain.domain)}.
                        </span>
                      </p>
                      <p className="mt-2">
                        Type: <span className="font-bold text-white">A</span>
                      </p>
                      <p className="mt-2">
                        Value:{' '}
                        <span className="font-bold text-white">
                          34.111.218.134
                        </span>
                      </p>
                      <p className="mt-2">
                        TTL ({formatMessage(messages.TimeTo)}):{' '}
                        <span className="font-bold text-white">300</span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-2 text-sm text-gray-500">
                    <p className="mt-2 p-2 rounded-lg bg-gray-600 bg-opacity-80 text-white text-md font-normal">
                      {formatMessage(messages.IfYouWant)}{' '}
                      <span className="text-poltio-yellow">
                        poltio.yourshop.com
                      </span>{' '}
                      {formatMessage(messages.YouShouldEnter)}{' '}
                      <span className="text-poltio-red">https://</span>{' '}
                      {formatMessage(messages.AnyPath)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </SlideOver.Body>
      <SlideOver.Footer />
    </SlideOver>
  );
};

export default injectIntl(DomainForm);
