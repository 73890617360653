import { serialize } from '../../../core/helper';

export default function generateMockURI(question: any): string {
  let src;
  let mockdata = {
    title: question?.question,
    answer_type: 'text',
  };

  question.choices.forEach((choice: any, j: any) => {
    const key = `answers[${j}][title]`;

    mockdata = { ...mockdata, [key]: choice };
  });

  src = `https://www.poltio.com/mock/question?disclaimer=off&hide_counter=1&`;
  src += `${serialize(mockdata)}`;

  return src;
}
