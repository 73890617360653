import { Fragment, useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { getSankey } from '../../../api';

import Pagination from '../../Common/Pagination';
import Loading from '../../Common/Loading';
import { injectIntl } from 'react-intl';
import messages from './messages';
import Button from '../../Common/Button';
import Table from '../../Common/Table';

const SankeyModal = ({
  open,
  setOpen,
  modalData,
  data,
  intl: { formatMessage },
}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 12,
    lastPage: null,
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const from_id = modalData[0].split(' ')[0].substr(1);
    const to_id = modalData[1].split(' ')[0].substr(1);
    if (from_id && to_id) {
      const r = await getSankey(
        data.public_id,
        from_id,
        to_id,
        pageData.page,
        pageData.sizePerPage
      );

      setUsers(r.data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: r.data.total,
          lastPage: r.data.last_page,
        };
      });
      setLoading(false);
    }
  }, [data.public_id, modalData, pageData.page, pageData.sizePerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  const columns = [
    { name: `${formatMessage(messages.UserID)}`, accessor: 'user_id' },
    { name: `${formatMessage(messages.UUID)}`, accessor: 'uuid' },
  ];

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogPanel className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
              <DialogTitle
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200"
              >
                {formatMessage(messages.Users)}
              </DialogTitle>
              <div>
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  handlePageChange={handlePageChange}
                  totalSize={pageData.totalSize}
                  showTotal
                />
                {loading ? (
                  <div className="max-w-7xl mx-auto px-2">
                    <Loading />
                  </div>
                ) : (
                  <Fragment>
                    <Table
                      cols={columns}
                      className="min-w-full divide-y divide-gray-200"
                    >
                      <tbody>
                        {users.map((l, i) => (
                          <tr
                            key={i}
                            className={
                              i % 2 === 0
                                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                            }
                          >
                            <td className="hidden px-6 py-4 text-sm whitespace-nowrap lg:flex">
                              {l.user_id}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.uuid}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      last={pageData.lastPage}
                      active={pageData.page}
                      size={pageData.sizePerPage}
                      handlePageChange={handlePageChange}
                      totalSize={pageData.totalSize}
                    />
                  </Fragment>
                )}
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <div>
                  <Button.Primary
                    type="button"
                    className="w-full"
                    onClick={() => setOpen(false)}
                  >
                    {formatMessage(messages.Close)}
                  </Button.Primary>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default injectIntl(SankeyModal);
