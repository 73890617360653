/* eslint-disable jsx-a11y/anchor-is-valid */
import { injectIntl } from 'react-intl';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import messages from './messages';
import Table from '../Common/Table';

const PixelCodesTable = ({
  codes,
  openEdit,
  handleDeleteClick,
  intl: { formatNumber, formatMessage },
}) => {
  const columns = [
    { name: 'ID', accessor: 'id' },
    { name: `${formatMessage(messages.Name)}`, accessor: 'name' },
    { name: `${formatMessage(messages.FirePixelCount)}`, accessor: 'counter' },
  ];
  return (
    <Table cols={columns}>
      <tbody>
        {codes.map((h, i) => (
          <tr
            key={i}
            className={
              i % 2 === 0
                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
            }
          >
            <td className="hidden px-6 py-6 text-sm whitespace-nowrap lg:flex">
              {h.id}
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">{h.name}</td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">{h.counter}</td>
            <td className="px-6 pl-4 pr-2 text-md whitespace-nowrap text-right">
              <div className="z-0 w-full py-2">
                <span className="relative inline-flex">
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      openEdit(h);
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PencilIcon
                      className="w-5 h-5 mr-4 -ml-1 text-poltio-purple hover:text-poltio-purple-hover"
                      aria-hidden="true"
                    />{' '}
                  </a>
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteClick(h.id);
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TrashIcon
                      className="w-5 h-5 mr-2 -ml-1 text-poltio-red hover:text-poltio-red-hover"
                      aria-hidden="true"
                    />
                  </a>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default injectIntl(PixelCodesTable);
