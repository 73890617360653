import { type FC, useCallback, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import {
  PencilIcon,
  TrashIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid';
import Tooltip from '../Common/Button/Tooltip';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import messages from './messages';

import {
  getLeadsPaginated,
  leadDelete,
  leadSave,
  addCouponCode,
} from '../../api/leads';
import { NotificationsCTX } from '../../contexts/Notification';
import Pagination from '../Common/Pagination';
import Loading from '../Common/Loading';
import Table from '../Common/Table';
import NewLeadForm from './Create/LeadForm';
import Button from '../Common/Button';
import LeadsLogs from './LeadsLogs';
import Heading from '../Common/Page/Heading';
import { Lead } from '../../core/types';
import { Helmet } from 'react-helmet';

const columns = [
  { id: 0, name: 'ID', accessor: 'id' },
  { id: 0, name: 'Name', accessor: 'name' },
  { id: 1, name: 'View Count', accessor: 'view' },
  { id: 1, name: 'Redirect Count', accessor: 'click' },
  { id: 1, name: 'Input Count', accessor: 'submit' },
];

type Props = {
  showUI: boolean;
  showCreateNewLead: boolean;
  onCloseNewLead: (p: boolean | null) => boolean;
  intl: any;
};

const Leads: FC<Props> = ({
  showUI = true,
  showCreateNewLead = false,
  onCloseNewLead = () => {},
  intl: { formatMessage },
}) => {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: 1,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [activeLead, setActiveLead] = useState({});
  const [activeLogs, setActiveLogs] = useState({});
  const [openLogs, setOpenLogs] = useState(false);

  const { success, error } = useContext(NotificationsCTX);

  const fetchCodes = useCallback(() => {
    setLoading(true);
    getLeadsPaginated(pageData.page, pageData.sizePerPage).then((response) => {
      setCodes(response.data.data);
      setPageData((prev) => ({
        page: prev.page,
        sizePerPage: prev.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      }));
      setLoading(false);
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({ ...pageData, page, sizePerPage });
  };

  const handleDeleteClick = async (id: number) => {
    if (!window.confirm(formatMessage(messages.SureDelete))) {
      return;
    }

    try {
      await leadDelete(id);
      success(formatMessage(messages.LeadDeleted));
      fetchCodes();
    } catch (err) {
      error(formatMessage(messages.ErrorOccured));
    }
  };

  const handleSubmit = async (
    data: Lead,
    code: any,
    setCode: any,
    apply = () => {},
    reject = () => {}
  ) => {
    if (!data.name || data.name === '') {
      error(formatMessage(messages.LeadEmptyNameError));

      reject();
      return;
    }

    try {
      const lead = await leadSave(data);
      if (code.codes || code.code) {
        await addCouponCode(lead.data.id, code);
        setCode({ single_use: 1 });
      }
      apply();
      success(
        data.id
          ? formatMessage(messages.LeadEditToastTitle)
          : formatMessage(messages.LeadCreatedToastTitle),
        ''
      );

      fetchCodes(); // reload
    } catch (err) {
      Sentry.captureException(err);
      error(formatMessage(messages.ErrorOccured));

      reject();
      return;
    }
  };

  const onEditClick = (h: Lead) => {
    setOpen(true);
    setActiveLead(h);
  };

  const onLogs = (h: Lead) => {
    setOpenLogs(true);
    setActiveLogs(h);
  };

  useEffect(() => {
    fetchCodes();
  }, [fetchCodes]);

  const openNew = onEditClick;

  // foreign interactions
  useEffect(() => {
    if (showCreateNewLead) {
      onEditClick({});
    } else {
      setOpen(false);
    }
  }, [showCreateNewLead]);

  const onNewLeadFormSetOpen = (value: boolean) => {
    setOpen(value);
    onCloseNewLead(null);
  };

  const onNewLeadFormHandleSubmit = (
    data: Lead,
    code: any,
    setCode: any,
    apply = () => {},
    reject = () => {}
  ) => {
    handleSubmit(
      data,
      code,
      setCode,
      () => {
        apply();
        onCloseNewLead(true);
      },
      () => {
        reject();
        onCloseNewLead(false);
      }
    );
  };

  // -------------------- RENDER --------------------

  return (
    <>
      {showUI ? (
        <div>
          <Helmet>
            <title>Leads | Poltio Platform</title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Heading
              title={formatMessage(messages.PageHeader)}
              body={formatMessage(messages.PageHeaderDesc)}
            >
              <Button.Primary className="self-end" onClick={() => openNew({})}>
                <PlusCircleIcon
                  className="w-5 h-5 mr-2 -ml-1"
                  aria-hidden="true"
                />
                {formatMessage(messages.NewLead)}
              </Button.Primary>
            </Heading>
          </div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
              totalSize={pageData.totalSize}
              showTotal
            />
          </div>
          {loading ? (
            <div className="max-w-7xl mx-auto px-2">
              <Loading />
            </div>
          ) : (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <Table cols={columns}>
                  <tbody>
                    {codes.map((row: any, row_idx) =>
                      row ? (
                        <tr
                          key={`tbody-idx-${row_idx}`}
                          className={
                            row_idx % 2 === 0
                              ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                              : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                          }
                        >
                          {columns
                            .filter((value) => !!value.accessor)
                            .map((col, col_idx) => (
                              <td
                                key={`tbody-col-idx-${col_idx}`}
                                className="px-6 py-6 text-sm whitespace-nowrap first:hidden lg:first:flex"
                              >
                                <div className="text-sm font-medium">
                                  {col.id === 0
                                    ? row[`${col.accessor}`]
                                    : row.counters
                                      ? row.counters[`${col.accessor}`]
                                      : null}
                                </div>
                              </td>
                            ))}
                          <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap text-right">
                            <div className="z-0 w-full py-2">
                              <span className="relative inline-flex">
                                {row.type === 'input' ||
                                row.lead_count > 0 ||
                                row.has_codes ? (
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                  >
                                    <MenuButton className="mr-5 flex items-center rounded-full text-poltio-blue hover:text-poltio-blue-400">
                                      <EllipsisHorizontalIcon className="w-5 h-5" />
                                    </MenuButton>
                                    <MenuItems
                                      transition
                                      className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-3 dark:bg-gray-900 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                      <span className="dark:text-gray-200 font-semibold block px-4 py-2">
                                        Actions
                                      </span>
                                      {row.type === 'input' ? (
                                        <MenuItem>
                                          <Link
                                            to={`/leads/incoming/${row.id}`}
                                            rel="noreferrer"
                                            state={row}
                                            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-400 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-700 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                          >
                                            {formatMessage(
                                              messages.IncomingLeads
                                            )}
                                          </Link>
                                        </MenuItem>
                                      ) : null}
                                      {row.lead_count > 0 ? (
                                        <MenuItem>
                                          <a
                                            href={''}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-400 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-700 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              onLogs(row);
                                            }}
                                          >
                                            {formatMessage(messages.Logs)}
                                          </a>
                                        </MenuItem>
                                      ) : null}
                                      {row.has_codes ? (
                                        <MenuItem>
                                          <Link
                                            to={`/leads/codes/${row.id}`}
                                            rel="noreferrer"
                                            state={row}
                                            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-400 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-700 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                          >
                                            {formatMessage(
                                              messages.CouponCodes
                                            )}
                                          </Link>
                                        </MenuItem>
                                      ) : null}
                                    </MenuItems>
                                  </Menu>
                                ) : null}
                                <a
                                  href={''}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="group cursor-pointer relative inline-block border-gray-400 text-center"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onEditClick(row);
                                  }}
                                >
                                  <PencilIcon
                                    className="w-5 h-5 mr-4 -ml-1 text-poltio-purple hover:text-poltio-purple-hover"
                                    aria-hidden="true"
                                  />
                                  <Tooltip
                                    title={formatMessage(messages.Edit)}
                                  />
                                </a>
                                <a
                                  href={''}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="group cursor-pointer relative inline-block border-gray-400 text-center"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDeleteClick(row.id);
                                  }}
                                >
                                  <TrashIcon
                                    className="w-5 h-5 mr-2 -ml-1 text-poltio-red hover:text-poltio-red-hover"
                                    aria-hidden="true"
                                  />
                                  <Tooltip
                                    title={formatMessage(messages.Delete)}
                                  />
                                </a>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  totalSize={pageData.totalSize}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}
          <LeadsLogs
            open={openLogs}
            setOpen={setOpenLogs}
            activeLead={activeLogs}
          />
        </div>
      ) : null}
      <NewLeadForm
        lead={activeLead}
        open={open}
        setOpen={onNewLeadFormSetOpen}
        handleSubmit={onNewLeadFormHandleSubmit}
      />
    </>
  );
};

export default injectIntl(Leads);
