/* eslint-disable react/prop-types */
import {
  Fragment,
  useEffect,
  useState,
  useMemo,
  FC,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
// @ts-ignore:next-line
import { FormattedMessage } from 'react-intl.macro';
import { injectIntl } from 'react-intl';
import { isValidUrl } from '../../core/helper';
import messages from './messages';
import { dynWidgetSave } from '../../api';
import SlideOver from '../Common/SlideOver';
import TextInput from '../Common/Form/TextInput';
import TextAreaInput from '../Common/Form/TextAreaInput';
import ContentSearch from '../Common/Form/ContentSearch';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import { NotificationsCTX } from '../../contexts/Notification';
import { Content, DynWidget } from '../../core/types';

const defaultData = {
  name: '',
  url: '',
  id: undefined,
  public_id: '',
};

function classNames(...classes: Array<String>) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  open: Boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dynWidget: any;
  handleSubmit: any;
  selectedContent: string;
  userId: string;
  intl: any;
};

const DynWidgetForm: FC<Props> = ({
  open,
  setOpen,
  dynWidget,
  handleSubmit,
  intl: { formatMessage },
  selectedContent,
  userId,
}) => {
  const urlSelection = useMemo(
    () => [
      {
        id: 1,
        name: formatMessage(messages.AllPages),
        value: '*',
      },
      {
        id: 2,
        name: formatMessage(messages.SpecificPage),
        value: '',
      },
    ],
    [formatMessage]
  );
  const [data, setData] = useState<DynWidget>(defaultData);
  const [saving, setSaving] = useState(false);
  const [id, setId] = useState('');
  const [url, setUrl] = useState(urlSelection[0]);

  const { success, error } = useContext(NotificationsCTX);

  useEffect(() => {
    dynWidget.url === '*' ? setUrl(urlSelection[0]) : setUrl(urlSelection[1]);
  }, [dynWidget, urlSelection]);

  useEffect(() => {
    if (selectedContent) {
      setId(selectedContent);
    }
    if (dynWidget.id) {
      setData(dynWidget);
      setId(dynWidget.public_id);
    } else {
      setData(defaultData);
    }
  }, [open, dynWidget, selectedContent]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      url: url.value,
    }));
  }, [url, setData, url.value]);

  const onFormSubmit = async () => {
    if (formValid() && !saving) {
      try {
        setSaving(true);
        await dynWidgetSave({
          ...data,
          public_id: id,
          dynWidget_id: dynWidget.id,
        });
        success(formatMessage(messages.Saved));
        handleSubmit();
        setSaving(false);
        setOpen(false);
      } catch (err: any) {
        setSaving(false);
        const status = err?.response?.status ?? -1;

        switch (status) {
          case 409:
            error(formatMessage(messages.UrlHas));
            break;
          case 422:
            error(formatMessage(messages.SelectContent));
            break;
          default:
            error(formatMessage(messages.ErrorSaving));
            break;
        }
      }
    }
  };

  const formValid = () => {
    if (data.name?.length < 1) {
      error(formatMessage(messages.ErrorNameEmpty));
      return false;
    }
    if (!isValidUrl(data.url) && data.url !== '*') {
      error(formatMessage(messages.EnterValidUrl));
      return false;
    }
    return true;
  };

  const onSelect = (item: any) => {
    setId(item.public_id);
  };

  return (
    <SlideOver
      open={open}
      setOpen={() => {}}
      isLoading={saving}
      isDisabled={saving}
      onSave={() => onFormSubmit()}
      onCancel={() => setOpen(false)}
    >
      <SlideOver.Header
        title={
          dynWidget.id
            ? formatMessage(messages.Update)
            : formatMessage(messages.CreateNew)
        }
        subtitle={
          dynWidget.id ? (
            <FormattedMessage
              id="DynWidget.CanEdit"
              defaultMessage="You can edit and save your dynamic widget with id #{id}"
              values={{
                id: data.id,
              }}
            />
          ) : null
        }
        setOpen={setOpen}
        children={null}
      />

      <SlideOver.Body>
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div>
              <TextInput
                label={formatMessage(messages.Name)}
                type="text"
                name="name"
                value={data.name}
                onChange={(e: any) =>
                  setData({ ...data, name: e.target.value })
                }
                debounce={null}
              />
            </div>
            <p className="mt-2 text-xs text-gray-400">
              {formatMessage(messages.NameDesc)}
            </p>
            <div>
              <p className="dark:text-gray-100">Url</p>
              <div className="h-full">
                <Listbox value={url} onChange={setUrl}>
                  {({ open }) => (
                    <>
                      <div className="relative mt-1">
                        <ListboxButton className="relative w-full py-2 pl-3 pr-10 text-left bg-white dark:bg-gray-900/30 dark:text-gray-200 border border-gray-300 cursor-default rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm">
                          <span className="block truncate">{url.name}</span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ArrowsUpDownIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </ListboxButton>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <ListboxOptions className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {urlSelection.map((t) => (
                              <ListboxOption
                                key={t.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? 'text-white bg-poltio-blue-600'
                                      : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                  )
                                }
                                value={t}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal',
                                        'block truncate'
                                      )}
                                    >
                                      {t.name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? 'text-white'
                                            : 'text-poltio-blue-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon
                                          className="w-5 h-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>
            {url.value !== '*' ? (
              <div>
                <TextAreaInput
                  autogrow
                  rows={2}
                  name="url"
                  value={data.url}
                  onChange={(e: any) =>
                    setData({ ...data, url: e.target.value })
                  }
                  debounce={null}
                />
              </div>
            ) : null}
            <p className="mt-2 text-xs text-gray-400">
              {formatMessage(messages.UrlDesc)}
            </p>
            <div>
              <ContentSearch
                onSelect={onSelect}
                publicId={id || data.public_id}
              />
            </div>
            <p className="mt-2 text-xs text-gray-400">
              {formatMessage(messages.ContentDesc)}
            </p>
            <div className="p-2 bg-gray-200 dark:bg-gray-900/30 dark:text-gray-200 rounded-lg">
              <pre className="text-xs">
                <code>{`<script defer 
  src="https://sdk.poltio.com/poltio.js">
</script>
<div
  class="poltio-widget-flying"
  data-poltio-widget-pid="${userId}">
</div>`}</code>
              </pre>
            </div>
            <div className="pt-2 dark:text-gray-400">
              {formatMessage(messages.MoreInfo)}{' '}
              <a
                className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                href="https://platform.poltio.com/docs/floating-widget/"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage(messages.Docs)}
              </a>
            </div>
          </div>
        </div>
      </SlideOver.Body>
      <SlideOver.Footer />
    </SlideOver>
  );
};

export default injectIntl(DynWidgetForm);
