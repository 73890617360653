import { Fragment, useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { getFreeTypeAnswers } from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Pagination from '../../Common/Pagination';
import Button from '../../Common/Button';
import Loading from '../../Common/Loading';
import messages from './messages';

const FreeTypeAnswers = ({
  open,
  setOpen,
  answers,
  intl: { formatMessage, formatDate, formatTime },
}) => {
  const { id } = useParams();

  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (answers.id) {
      const { data } = await getFreeTypeAnswers(
        id,
        answers.id,
        pageData.page,
        pageData.sizePerPage
      );
      setData(data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        };
      });
      setLoading(false);
    }
  }, [answers, id, pageData.page, pageData.sizePerPage]);

  useEffect(() => {
    const data = answers;
    if (data) {
      try {
        fetchData(pageData.page, pageData.sizePerPage);
      } catch (e) {
        navigate(`/content`);
      }
    } else {
      return;
    }
  }, [answers, pageData.page, pageData.sizePerPage, fetchData, navigate]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogPanel className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
              <div>
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  handlePageChange={handlePageChange}
                  totalSize={pageData.totalSize}
                  showTotal
                />
                {loading ? (
                  <div className="max-w-7xl mx-auto px-2">
                    <Loading />
                  </div>
                ) : (
                  <Fragment>
                    <div className="flex flex-col py-4">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50 dark:bg-gray-800">
                                <tr className="dark:text-gray-200 text-gray-500">
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                                  >
                                    {formatMessage(messages.VoterID)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                                  >
                                    {formatMessage(messages.Answer)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                                  >
                                    {formatMessage(messages.CreatedAt)}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((a, i) => (
                                  <tr
                                    key={i}
                                    className={
                                      i % 2 === 0
                                        ? 'bg-white dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                                        : 'bg-gray-50 dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                                    }
                                  >
                                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                                      <div className="text-sm font-medium">
                                        {a.voter_id}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                                      <div className="text-sm font-medium">
                                        {a.answer}
                                      </div>
                                    </td>

                                    <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                                      <div className="text-sm font-medium">
                                        {`${formatDate(
                                          a.created_at
                                        )} ${formatTime(a.created_at)}`}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      last={pageData.lastPage}
                      active={pageData.page}
                      size={pageData.sizePerPage}
                      handlePageChange={handlePageChange}
                      totalSize={pageData.totalSize}
                    />
                  </Fragment>
                )}
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <div className="ml-5">
                  <Button.Secondary
                    type="button"
                    className="w-full"
                    onClick={() => setOpen(false)}
                  >
                    {formatMessage(messages.Close)}
                  </Button.Secondary>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default injectIntl(FreeTypeAnswers);
