import React, { useState, Fragment } from 'react';
import {
  compareVoteCounts,
  calculatePercentage,
} from '../../../../core/helper';
import { injectIntl } from 'react-intl';
import messages from '../messages';

const SetSummaryReport = ({ questionsProp, intl: { formatMessage } }) => {
  const [questions, setQuestions] = useState(questionsProp);

  const toggleAnswers = (index) => {
    setQuestions(
      questions.map((q, i) => {
        if (i === index) {
          return {
            ...q,
            expanded: !q.expanded,
          };
        }
        return q;
      })
    );
  };

  const freeTypes = ['free_text', 'free_number'];

  return (
    <div className="flex flex-col pt-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr className="dark:text-gray-200 text-gray-500">
                  <th
                    scope="col"
                    className=" px-6 py-3 text-xs font-medium text-left uppercase "
                  >
                    {formatMessage(messages.Question)}
                  </th>
                  <th
                    scope="col"
                    className=" px-6 py-3 text-xs font-medium text-left uppercase "
                  >
                    {formatMessage(messages.TotalVoteCount)}
                  </th>
                  <th
                    scope="col"
                    className=" px-6 py-3 text-xs font-medium text-left uppercase "
                  >
                    {formatMessage(messages.Answer)}
                  </th>
                  <th
                    scope="col"
                    className=" px-6 py-3 text-xs font-medium text-left uppercase "
                  >
                    {formatMessage(messages.VoteCount)}
                  </th>
                  <th
                    scope="col"
                    className=" px-6 py-3 text-xs font-medium text-left uppercase "
                  >
                    {'%'}
                  </th>
                  <th
                    scope="col"
                    className=" px-6 py-3 text-xs font-medium text-left uppercase "
                  >
                    {formatMessage(messages.Connected)}
                  </th>
                  <th
                    scope="col"
                    className=" px-6 py-3 text-xs font-medium text-left uppercase "
                  >
                    {formatMessage(messages.MultiPunch)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question, i) => {
                  const sortedAnswers =
                    question.answers.sort(compareVoteCounts);
                  return (
                    <Fragment key={i}>
                      <tr
                        className={
                          i % 2 === 0
                            ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                            : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                        }
                        onClick={() => toggleAnswers(i)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td className="px-6 py-2 text-sm">{question.title}</td>
                        <td className="px-6 py-2 text-sm">
                          {question.vote_count}
                        </td>
                        <td className="px-6 py-2 text-sm">
                          {sortedAnswers[0]?.title || '-'}
                        </td>
                        <td className="px-6 py-2 text-sm">
                          {sortedAnswers[0]?.vote_count || '-'}
                        </td>
                        <td className="px-6 py-2 text-sm">
                          {freeTypes.includes(question.answer_type)
                            ? '-'
                            : calculatePercentage(
                                question.vote_count,
                                sortedAnswers[0]?.vote_count
                              ) + `%`}
                        </td>
                        <td className="px-6 py-2 text-sm">
                          {question?.is_connected ? 'Yes' : 'No'}
                        </td>
                        <td className="px-6 py-2 text-sm">
                          {question.max_multi_punch_answer}
                        </td>
                      </tr>
                      {question.expanded
                        ? sortedAnswers.slice(1).map((sa, si) => (
                            <tr
                              className={
                                i % 2 === 0
                                  ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                                  : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                              }
                              key={si}
                            >
                              <td className="px-6 py-2 text-sm"></td>
                              <td className="px-6 py-2 text-sm"></td>
                              <td className="px-6 py-2 text-sm">{sa.title}</td>
                              <td className="px-6 py-2 text-sm">
                                {sa.vote_count}
                              </td>
                              <td className="px-6 py-2 text-sm">
                                {calculatePercentage(
                                  question.vote_count,
                                  sa.vote_count
                                )}
                              </td>
                              <td className="px-6 py-2 text-sm"></td>
                              <td className="px-6 py-2 text-sm"></td>
                            </tr>
                          ))
                        : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SetSummaryReport);
