import { useState, useEffect, useMemo, FC } from 'react';
import { injectIntl } from 'react-intl';
import {
  UserGroupIcon,
  ArrowUturnLeftIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline';

import messages from '../messages';

import LeadFormBodyInputType from './LeadFormBodyInputType';
import LeadFormBodyRedirect from './LeadFormBodyRedirect';
import LeadFormBodyImageDisplay from './LeadFormBodyImageDisplay';

import SlideOver from '../../Common/SlideOver';
import Button from '../../Common/Button';
import { CheckBoxInput } from '../../Common/Form';
import { CouponCode, Lead } from '../../../core/types';
import { Helmet } from 'react-helmet';

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  open: Boolean;
  setOpen: (s: boolean) => void;
  lead: Lead;
  handleSubmit: any;
  intl: any;
};

const NewLeadForm: FC<Props> = ({
  open,
  setOpen,
  lead,
  handleSubmit,
  intl: { formatMessage },
}) => {
  const [data, setData] = useState<Lead>({} as Lead);
  const [saving, setSaving] = useState<boolean>(false);
  const [showSelect, setShowSelect] = useState(
    JSON.parse(
      window?.localStorage?.getItem('poltio_lead_show_select') || 'true'
    )
  );
  const [state, setState] = useState({ page: showSelect ? 0 : 1, tab: 0 });
  const [code, setCode] = useState<CouponCode>({ single_use: 1 });
  const tabs = useMemo(
    () =>
      [
        {
          type: 'input',
          icon: UserGroupIcon,
          text: formatMessage(messages.Input),
          description: formatMessage(messages.InputDescription),
          disabled: false,
          element: LeadFormBodyInputType,
        },
        {
          type: 'redirect',
          icon: ArrowUturnLeftIcon,
          text: formatMessage(messages.Redirect),
          description: formatMessage(messages.RedirectDescription),
          disabled: false,
          element: LeadFormBodyRedirect,
        },
        {
          type: 'empty',
          icon: PhotoIcon,
          text: formatMessage(messages.ImageDisplay),
          description: formatMessage(messages.ImageDisplayDescription),
          disabled: false,
          element: LeadFormBodyImageDisplay,
        },
      ].filter((tab) => !tab.disabled),
    [formatMessage]
  );

  useEffect(() => {
    setData(lead);

    if (lead.type === 'input') {
      setState((prev) => ({ ...prev, tab: 0, page: 1 }));
      return;
    }

    if (lead.type === 'redirect' || lead.type === 'internal_redirect') {
      setState((prev) => ({ ...prev, tab: 1, page: 1 }));
      return;
    }

    if (lead.type === 'empty') {
      setState((prev) => ({ ...prev, tab: 2, page: 1 }));
      return;
    }
  }, [lead, open, tabs]);

  useEffect(
    () =>
      setData((prev) => ({
        ...prev,
        type:
          state.tab < 0 || state.tab > tabs.length // OOB
            ? undefined
            : tabs[state.tab]?.type,
        mandatory: lead?.mandatory || 0,
        tc_optional: lead?.tc_optional || 0,
        tc2_optional: lead?.tc2_optional || 0,
        stop_set: lead?.stop_set || 0,
      })),
    [tabs, state.tab, state.page, lead]
  );

  const submit = () => {
    setSaving(true);

    handleSubmit(
      data,
      code,
      setCode,
      () => {
        // success
        setSaving(false); // disable overlay
        setOpen(false); // close
      },
      () => {
        setTimeout(() => {
          // fail
          setSaving(false); // disable overlay
        }, 500);
      }
    );
  };

  const _cancelClickHandler = () => {
    setOpen(false);
  };

  const handleDontShowClick = (event: any) => {
    window?.localStorage?.setItem(
      'poltio_lead_show_select',
      JSON.stringify(!event.target.checked)
    );
    setShowSelect(!event.target.checked);
  };

  type TProps = {
    text: string;
    disabled: boolean;
    selected: boolean;
    onClick: () => void;
  };

  const T: FC<TProps> = ({ text, disabled, selected, onClick }) => (
    <button className="w-full" disabled={disabled} onClick={onClick}>
      <div
        className={classNames(
          'flex items-center justify-center border-b-2 h-full py-2 dark:text-gray-200',
          selected ? 'border-poltio-blue-500' : 'border-transparent',
          disabled ? 'bg-gray-100 dark:bg-gray-800 text-gray-400' : ''
        )}
      >
        {text}
      </div>
    </button>
  );

  const Panel: any =
    state.tab < 0 || state.tab >= tabs.length // OOB
      ? undefined
      : tabs[state.tab]?.element;

  return (
    <SlideOver
      open={open}
      setOpen={() => {}}
      isLoading={saving}
      onSave={() => submit()}
      onCancel={_cancelClickHandler}
      isDisabled={!data?.name}
    >
      <Helmet>
        <title>
          {data.name
            ? `Editing Lead: ${data.name} | Poltio Platform`
            : `Creating Lead | Poltio Platform`}
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {state.page === 0 && (
        <SlideOver.Header
          title={formatMessage(messages.SelectLeadType)}
          subtitle={null}
          children={null}
          setOpen={_cancelClickHandler}
        />
      )}
      {state.page === 1 && (
        <SlideOver.Header
          title={formatMessage(messages.LeadFormTitle)}
          subtitle={null}
          setOpen={setOpen}
        >
          <Button.Primary
            className="bg-transparent p-0 mx-4 focus:ring-0 ring-0 border-0"
            onClick={() => setState((prev) => ({ ...prev, page: 0 }))}
          >
            <ArrowUturnLeftIcon className="w-6 h-6" />
          </Button.Primary>
        </SlideOver.Header>
      )}
      <SlideOver.Body>
        <div
          className={classNames(
            state.page === 0 ? '' : 'hidden',
            'space-y-4 pt-4'
          )}
        >
          {tabs.map((item, index) => (
            <div
              key={`lead-create-selector-${index}`}
              className="flex w-full px-10"
            >
              <button
                className="flex-1 w-full col-span-1 rounded-lg shadow text-left p-4 space-y-2 dark:bg-gray-900/30"
                onClick={() =>
                  setState((prev) => ({ ...prev, page: 1, tab: index }))
                }
              >
                <div className="">
                  {item?.icon && (
                    <item.icon className="h-9 p-1 text-poltio-blue-500 bg-poltio-blue-50 dark:bg-gray-800 rounded-xl scale-x-[-1]" />
                  )}
                </div>
                <div className="font-semibold dark:text-gray-300">
                  {item.text}
                </div>
                <div className="font-normal text-gray-500 dark:text-gray-400">
                  {item.description}
                </div>
              </button>
            </div>
          ))}
          <ul className="list-disc list-inside space-y-4 px-10 text-gray-600 dark:text-gray-400 font-normal">
            <li>{formatMessage(messages.LeadDesc1)}</li>
            <li> {formatMessage(messages.LeadDesc2)}</li>
            <li> {formatMessage(messages.LeadDesc3)}</li>
            <li>{formatMessage(messages.LeadDesc4)}</li>
            <li> {formatMessage(messages.LeadDesc5)}</li>
          </ul>
        </div>
        <div className={classNames(state.page === 1 ? '' : 'hidden')}>
          <div className="flex-1 flex flex-col justify-between pt-2 pb-5 px-4 sm:px-6">
            <div className="flex justify-between border-b mb-1 divide-x-2 divide-gray-100 dark:divide-gray-400">
              {tabs.map((item, index) => (
                <T
                  key={`lead-create-tab-${index}`}
                  text={item.text}
                  disabled={false}
                  selected={state.tab === index}
                  onClick={() => setState((prev) => ({ ...prev, tab: index }))}
                />
              ))}
            </div>
            <div className="space-y-4">
              <Panel
                data={data}
                setData={setData}
                code={code}
                setCode={setCode}
                formatMessage={formatMessage}
                panelOpen={open}
              />
            </div>
          </div>
        </div>
      </SlideOver.Body>
      {state.page === 0 && (
        <>
          <div className="py-2">
            <CheckBoxInput
              className="justify-center"
              checked={!showSelect}
              onChange={handleDontShowClick}
              label={formatMessage(messages.DontShow)}
            />
          </div>
        </>
      )}
      {state.page === 1 && <SlideOver.Footer />}
    </SlideOver>
  );
};

export default injectIntl(NewLeadForm);
