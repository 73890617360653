/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { injectIntl } from 'react-intl';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import messages from './messages';

const PixelCodesTable = ({
  codes,
  openEdit,
  handleDeleteClick,
  intl: { formatNumber, formatMessage },
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr className="dark:text-gray-200 text-gray-500">
                  <th
                    scope="col"
                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left uppercase lg:flex"
                  >
                    {'ID'}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.Name)}
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.FirePixelCount)}
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {codes.map((h, i) => (
                  <tr
                    key={i}
                    className={
                      i % 2 === 0
                        ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                        : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                    }
                  >
                    <td className="hidden px-6 py-6 text-sm whitespace-nowrap lg:flex">
                      {h.id}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {h.name}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {h.counter}
                    </td>
                    <td className="px-6 pl-4 pr-2 text-md whitespace-nowrap text-right">
                      <div className="z-0 w-full py-2">
                        <span className="relative inline-flex">
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              openEdit(h);
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PencilIcon
                              className="w-5 h-5 mr-4 -ml-1 text-gray-400 dark:text-gray-200"
                              aria-hidden="true"
                            />{' '}
                          </a>
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteClick(h.id);
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <TrashIcon
                              className="w-5 h-5 mr-2 -ml-1 text-red-400 dark:text-red-500"
                              aria-hidden="true"
                            />
                          </a>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(PixelCodesTable);
