import React from 'react';
import {
  HomeIcon,
  CubeIcon,
  SwatchIcon,
  ShareIcon,
  DocumentChartBarIcon,
  CodeBracketIcon,
  PresentationChartLineIcon,
  CircleStackIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  PencilIcon,
  CalendarIcon,
  UserIcon,
  ChatBubbleLeftEllipsisIcon,
  CloudIcon,
} from '@heroicons/react/24/outline';
import { LinkIcon } from '@heroicons/react/24/solid';

import Home from './Home';
import Content from './Content';
import PixelCodes from './PixelCodes';
import Leads from './Leads';
import Webhooks from './Webhooks';
import Sheethoks from './Sheethoks';
import Theme from './Theme';
import ThemeCreateEdit from './Theme/ThemeCreateEdit';
import Reports from './Reports';
import Trivia from './Trivia';
import Settings from './Settings';
import ContentDetail from './Content/Detail';
import PlayerList from './Trivia/PlayerList';
import NewTrivia from './Trivia/NewTrivia';
import ManageTrivia from './Trivia/ManageTrivia';
import Editor from './Editor';
import DraftContents from './Editor/DraftContents';
import ErrorPage from './ErrorPages';
import DataSource from './DataSource';
import DataSourceSageTinkerPage from './DataSource/DataSourceSageTinker';
import DataSourceConfigurePage from './DataSource/Configure';
import SagePage from './DataSource/Sage';
import Gtm from './Gtm';
import RequestDemo from './RequestDemo';
import Tokens from './Settings/Tokens';
import CreateToken from './Settings/Tokens/CreateToken';
import Preview from './Content/Preview';
import PublishFromTemplate from './PublishFromTemplate';
import SagePreview from './SagePreview';
import DynamicWidget from './DynamicWidget';
import Domains from './Domains';
import CanvasView from './CanvasView';
import LogsTable from './Webhooks/LogsTable';
import IncomingLeads from './Leads/IncomingLeads';
import CouponCodes from './Leads/CouponCodes';
const Subscribe = React.lazy(() => import('./Subscribe'));

const routes = [
  {
    name: 'Dashboard',
    path: '/',
    icon: HomeIcon,
    private: true,
    exact: true,
    element: Home,
  },
  {
    name: 'Content',
    path: '/content',
    icon: CubeIcon,
    private: true,
    element: Content,
  },
  {
    navbar: true,
    private: true,
    path: '/editor',
    name: 'NewProject',
    icon: PencilIcon,
    element: DraftContents,
    className: '!pt-0 poltio-editor-btn',
    currentClassName: '!pt-0 poltio-editor-btn',
  },
  {
    name: 'Theme',
    path: '/theme',
    icon: SwatchIcon,
    private: true,
    element: Theme,
    exact: true,
  },
  {
    name: 'Integrations',
    icon: ShareIcon,
    expandable: true,
    children: [
      {
        name: 'PixelCodes',
        path: '/pixelcodes',
        icon: CodeBracketIcon,
        private: true,
        exact: true,
        element: PixelCodes,
      },
      {
        name: 'Webhooks',
        path: '/webhooks',
        icon: ShareIcon,
        private: true,
        element: Webhooks,
      },
      {
        name: 'Sheethooks',
        path: '/sheethooks',
        icon: DocumentChartBarIcon,
        private: true,
        element: Sheethoks,
      },
      {
        name: 'Leads',
        path: '/leads',
        icon: LinkIcon,
        private: true,
        exact: true,
        element: Leads,
      },
      {
        name: 'GoogleTagManager',
        path: '/gtm',
        icon: DocumentChartBarIcon,
        private: true,
        element: Gtm,
      },
    ],
  },
  {
    name: 'DynamicWidgets',
    path: '/widgets',
    icon: CloudIcon,
    private: true,
    element: DynamicWidget,
  },

  {
    name: 'ThemeEdit',
    path: '/theme/:id',
    navbar: false,
    private: true,
    element: ThemeCreateEdit,
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: CircleStackIcon,
    private: true,
    element: Reports,
  },
  {
    navbar: false,
    name: 'Trivia',
    path: '/trivia',
    icon: PresentationChartLineIcon,
    private: true,
    exact: true,
    element: Trivia,
  },
  {
    name: 'DataSource',
    navbar: true,
    private: true,
    path: '/data-source',
    element: DataSource,
    icon: CloudArrowUpIcon,
  },
  {
    navbar: false,
    private: true,
    path: '/editor/:id',
    element: Editor,
  },
  {
    navbar: false,
    private: true,
    path: '/c/:id',
    element: ContentDetail,
  },
  {
    navbar: false,
    private: true,
    path: '/canvas/:id',
    element: CanvasView,
  },
  {
    navbar: false,
    private: true,
    path: '/trivia/player-list/:id',
    element: PlayerList,
  },
  {
    navbar: false,
    private: true,
    path: '/new-trivia',
    exact: true,
    element: NewTrivia,
  },
  {
    navbar: false,
    private: true,
    path: '/manage-trivia/:id',
    element: ManageTrivia,
  },
  {
    navbar: false,
    private: true,
    path: '/errorpage',
    element: ErrorPage,
  },

  {
    navbar: false,
    private: true,
    path: '/data-source/:value',
    element: DataSourceConfigurePage,
  },
  {
    navbar: false,
    private: true,
    path: 'data-source/:id/sage',
    element: SagePage,
  },
  {
    navbar: false,
    private: true,
    path: 'data-source/sage/tinker/:dataSourceId/job/:jobId',
    element: DataSourceSageTinkerPage,
  },
  {
    name: 'Subscription',
    path: '/subscribe',
    icon: SwatchIcon,
    private: true,
    element: Subscribe,
    exact: true,
    navbar: false,
  },
  {
    name: 'Settings',
    icon: Cog6ToothIcon,
    expandable: true,
    children: [
      {
        private: true,
        name: 'AccountSettings',
        path: '/settings',
        icon: UserIcon,
        element: Settings,
      },
      {
        private: true,
        name: 'APITokens',
        path: '/token',
        icon: UserIcon,
        element: Tokens,
      },
      {
        name: 'Domains',
        path: '/domains',
        icon: DocumentChartBarIcon,
        private: true,
        element: Domains,
      },
    ],
  },
  {
    name: 'Contact',
    icon: ChatBubbleLeftEllipsisIcon,
    expandable: true,
    children: [
      {
        private: true,
        name: 'ContactUs',
        icon: ChatBubbleLeftEllipsisIcon,
        element: RequestDemo,
        path: '/request-demo',
      },
      {
        private: true,
        name: 'BookADemo',
        icon: CalendarIcon,
        path: 'https://calendar.app.google/Nag4cEyT9NUW6UaG9',
      },
    ],
  },
  {
    navbar: false,
    private: true,
    path: 'token/new',
    element: CreateToken,
  },
  {
    navbar: false,
    private: true,
    path: 'preview/:id',
    element: Preview,
  },
  {
    navbar: false,
    private: true,
    path: '/sage',
    element: SagePreview,
  },
  {
    name: 'PublishFromTemplate',
    path: '/templates/:id/publish',
    exact: true,
    navbar: false,
    private: true,
    element: PublishFromTemplate,
  },
  {
    navbar: false,
    private: true,
    path: '/webhooks/:id',
    element: Webhooks,
  },
  {
    navbar: false,
    private: true,
    path: '/webhooks/logs/:id',
    element: LogsTable,
  },
  {
    navbar: false,
    private: true,
    path: '/sheethooks/:id',
    element: Sheethoks,
  },
  {
    navbar: false,
    private: true,
    path: '/widgets/:id',
    element: DynamicWidget,
  },
  {
    navbar: false,
    private: true,
    path: '/leads/incoming/:id',
    element: IncomingLeads,
  },
  {
    navbar: false,
    private: true,
    path: '/leads/codes/:id',
    element: CouponCodes,
  },
];

export default routes;
