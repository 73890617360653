import React from 'react';
import { Switch } from '@headlessui/react';
import {
  PencilIcon,
  ClipboardDocumentIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';

import { HealthButton } from '../Common/Button';
import { Link } from 'react-router-dom';
import Tooltip from '../Common/Button/Tooltip';

import messages from './messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const WebhooksTable = ({
  hooks,
  handleToggleClick,
  handleDeleteClick,
  openEdit,
  openDuplicate,
  intl: { formatMessage },
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr className="dark:text-gray-200 text-gray-500">
                  <th
                    scope="col"
                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left uppercase lg:flex"
                  >
                    {'ID'}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.Name)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {'Url'}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.ContentId)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.Active)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    {formatMessage(messages.Health)}
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {hooks.map((h, i) => (
                  <tr
                    key={i}
                    className={
                      i % 2 === 0
                        ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                        : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                    }
                  >
                    <td className="hidden px-6 py-6 text-sm whitespace-nowrap lg:flex">
                      {h.id}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {h.name?.length > 25
                        ? `${h.name?.substring(0, 25)}...`
                        : h.name}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {h.url?.length > 25
                        ? `${h.url?.substring(0, 25)}...`
                        : h.url}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {h.public_id ? (
                        <Link
                          className="text-sm font-medium"
                          to={`/c/${h.public_id}`}
                          style={{ cursor: 'pointer' }}
                        >
                          {h.public_id}
                        </Link>
                      ) : h.account_wide ? (
                        <span className="text-semibold text-white bg-poltio-red p-2 rounded-md">
                          Account Wide
                        </span>
                      ) : (
                        <span className="text-sm font-medium ">
                          {formatMessage(messages.NoContent)}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <Switch
                        checked={h.is_active}
                        onChange={() => handleToggleClick(h)}
                        className={classNames(
                          h.is_active
                            ? 'bg-poltio-blue-600'
                            : 'bg-gray-200 dark:bg-gray-300',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                        )}
                      >
                        <span className="sr-only">{'Active'}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            h.is_active ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-900/50 shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <HealthButton health={h.health}></HealthButton>
                    </td>
                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 dark:text-gray-200 whitespace-nowrap">
                      <div className="z-0 w-full py-2">
                        <span className="relative inline-flex">
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              openEdit(h);
                            }}
                            target="_blank"
                            rel="noreferrer"
                            className="group cursor-pointer relative inline-block border-gray-400 text-center"
                          >
                            <PencilIcon
                              className="w-5 h-5 mr-4 -ml-1 text-gray-400 dark:text-gray-200"
                              aria-hidden="true"
                            />
                            <Tooltip title={formatMessage(messages.Edit)} />
                          </a>
                          <Link
                            href={''}
                            to={`/webhooks/logs/${h.id}`}
                            state={h}
                            rel="noreferrer"
                            className="group cursor-pointer relative inline-block border-gray-400 text-center"
                          >
                            <ClipboardDocumentIcon
                              className="w-5 h-5 mr-4 -ml-1 text-gray-400 dark:text-gray-200"
                              aria-hidden="true"
                            />
                            <Tooltip title={formatMessage(messages.Logs)} />
                          </Link>
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              openDuplicate(h);
                            }}
                            target="_blank"
                            rel="noreferrer"
                            className="group cursor-pointer relative inline-block border-gray-400 text-center"
                          >
                            <DocumentDuplicateIcon
                              className="w-5 h-5 mr-2 -ml-1 text-gray-400 dark:text-gray-200"
                              aria-hidden="true"
                            />
                            <Tooltip
                              title={formatMessage(messages.Duplicate)}
                            />
                          </a>
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteClick(h.id);
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <TrashIcon
                              className="w-5 h-5 mr-2 -ml-1 text-red-400 dark:text-red-500"
                              aria-hidden="true"
                            />
                          </a>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(WebhooksTable);
