import React, { useState, useContext } from 'react';
import { injectIntl } from 'react-intl';

import messages from './messages';

import { forgotPassword } from '../../api';
import { validateEmail } from '../../core/helper';

import { ErrorAlert } from '../Common/Alert';
import Button from '../Common/Button';
import { TextInput } from '../Common/Form';
import { NotificationsCTX } from '../../contexts/Notification';
import { AuthHeading } from './Heading';
import AuthContainer from './AuthContainer';

let ForgotPasswordComp = ({ intl: { formatMessage } }) => {
  const { success } = useContext(NotificationsCTX);

  const [formData, setFormData] = useState({
    email: '',
    errorMessage: '',
  });
  const [sending, setSending] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;

    if (!validateEmail(formData.email)) {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.EnterValidEmail),
      });
      return;
    }

    setSending(true);
    try {
      await forgotPassword(email);
      success('', formatMessage(messages.EmailSent));
      setSending(false);
    } catch (e) {
      setSending(false);
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.CouldNotFindEmail),
      });
    }
  };

  return (
    <AuthContainer>
      <AuthHeading msg={formatMessage(messages.PasswordReset)} />

      <div className="mt-8">
        <div className="">
          <div className="space-y-6">
            <div>
              <TextInput
                text={formatMessage(messages.Email)}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: e.target.value,
                    errorMessage: null,
                  });
                }}
                value={formData.email}
              />
            </div>
            <div className="flex items-center">
              <div className="w-full text-center">
                <Button.Primary
                  type="submit"
                  className="flex w-full"
                  disabled={sending}
                  onClick={onFormSubmit}
                >
                  {formatMessage(messages.ResetPassword)}
                </Button.Primary>
              </div>
            </div>
            {formData.errorMessage ? (
              <ErrorAlert title={formData.errorMessage} />
            ) : null}
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300 dark:border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-800">
                    {formatMessage(messages.DontNeed)}
                  </span>
                </div>
              </div>

              <Button.Secondary
                className="flex justify-center w-full mt-4"
                disabled={sending}
                href={`/login`}
              >
                {formatMessage(messages.BackToLogin)}
              </Button.Secondary>
            </div>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};
ForgotPasswordComp = injectIntl(ForgotPasswordComp);

const ForgotPassword = () => <ForgotPasswordComp />;

export default ForgotPassword;
