import { useContext, useEffect, useState } from 'react';
import { sendDemoRequest } from '../../api';
import Button from '../Common/Button';
import Container from '../Common/Container';
import { NotificationsCTX } from '../../contexts/Notification';
import { injectIntl } from 'react-intl';
import messages from './messages';

import type { Dispatch, FC, SetStateAction } from 'react';
import Heading from '../Common/Page/Heading';
import { IntlFormatters } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

type IntlFormatMessageFn = IntlFormatters['formatMessage'];

declare global {
  interface Window {
    gtag: any;
  }
}

type DemoType = {
  [key: string]: string | undefined;
  message: string | undefined;
  email: string;
};

type FormInputProps = {
  fieldName: string;
  label: string;
  desc: string;
  formData: DemoType;
  setFormData: Dispatch<SetStateAction<DemoType>>;
  isTextArea?: boolean;
  isOptional?: boolean;
  formatMessage: IntlFormatMessageFn;
};

const FormInput: FC<FormInputProps> = ({
  fieldName,
  label,
  desc,
  formData,
  setFormData,
  isTextArea,
  isOptional,
  formatMessage,
}) => (
  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6 mb-2">
    <Helmet>
      <title>Request Demo | Poltio Platform</title>
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    <div className="col-span-full">
      <div className="flex justify-between">
        <label
          htmlFor="company"
          className="block text-sm font-medium leading-6 dark:text-gray-200"
        >
          {label}
        </label>

        {isOptional ? (
          <span
            className="text-xs leading-6 text-gray-500 dark:text-gray-400"
            id="email-optional"
          >
            {formatMessage(messages.Optional)}
          </span>
        ) : null}
      </div>
      <div>
        <p className="block text-xs leading-2 text-gray-400">{desc}</p>
      </div>
      <div className="mt-2">
        {isTextArea ? (
          <textarea
            id={fieldName}
            name={fieldName}
            autoComplete={fieldName}
            className={
              'block w-full shadow-sm sm:text-sm focus:ring-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 focus:border-poltio-blue-500 border-gray-300 rounded-md'
            }
            value={formData[fieldName] ?? ''}
            onChange={(e) =>
              setFormData({ ...formData, [fieldName]: e.target.value })
            }
          />
        ) : (
          <input
            type="text"
            id={fieldName}
            name={fieldName}
            autoComplete={fieldName}
            className={
              'block w-full shadow-sm sm:text-sm focus:ring-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 focus:border-poltio-blue-500 border-gray-300 rounded-md'
            }
            value={formData[fieldName] ?? ''}
            onChange={(e) =>
              setFormData({ ...formData, [fieldName]: e.target.value })
            }
          />
        )}
      </div>
    </div>
  </div>
);

const RequestDemo: FC<any> = ({ user, intl: { formatMessage } }) => {
  const [searchParams] = useSearchParams();
  const { alert, success } = useContext(NotificationsCTX);

  const [formData, setFormData] = useState<DemoType>({} as DemoType);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      email: user.email,
      message:
        searchParams.get('onboarding') === 'true'
          ? `${formatMessage(messages.OnboardingMsg)}`
          : '',
    });
    window.gtag('event', 'request-demo-opened', {});
  }, [user.email]);

  const sendRequest = async () => {
    setButtonDisabled(true);
    try {
      await sendDemoRequest(formData);
      window.gtag('event', 'request-demo-submitted', {});

      setFormData({} as DemoType);
      setButtonDisabled(false);
      setSubmitSuccess(true);
      success(formatMessage(messages.Success));
    } catch (error: any) {
      setButtonDisabled(false);
      alert(formatMessage(messages.Error));
    }
  };

  return (
    <main>
      <div className="container max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Heading title={formatMessage(messages.RequestDemo)} />
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
              {formatMessage(messages.Great)}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
              {formatMessage(messages.LeaveDetail)}
            </p>
          </div>
          <Container>
            {submitSuccess ? (
              <div className=" space-y-8 divide-gray-900/10 divide-solid divide-y">
                <p className="block text-sm font-medium leading-6">
                  {formatMessage(messages.SubmitSuccess)}
                </p>
                <p className="block text-sm font-medium leading-6 text-gray-500">
                  {formatMessage(messages.SubmitSuccessDesc)}
                </p>
              </div>
            ) : (
              <div className=" space-y-8 divide-gray-900/10 divide-solid divide-y">
                <div>
                  <FormInput
                    fieldName="email"
                    label={formatMessage(messages.YourEmail)}
                    desc={formatMessage(messages.EmailDesc)}
                    formData={formData}
                    setFormData={setFormData}
                    formatMessage={formatMessage}
                  />
                </div>
                <div className="py-4">
                  <FormInput
                    fieldName="message"
                    label={formatMessage(messages.YourMessage)}
                    desc={formatMessage(messages.MsgDesc)}
                    formData={formData}
                    setFormData={setFormData}
                    isTextArea
                    isOptional
                    formatMessage={formatMessage}
                  />
                </div>
                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 mt-8">
                  <Button.Primary
                    type="submit"
                    className={''}
                    disabled={buttonDisabled}
                    onClick={sendRequest}
                  >
                    {formatMessage(messages.Send)}
                  </Button.Primary>
                </div>
              </div>
            )}
          </Container>
        </div>
      </div>
    </main>
  );
};

export default injectIntl(RequestDemo);
