import { useState, useCallback, useEffect, useMemo, type FC } from 'react';
import { useLocation } from 'react-router-dom';
import { getInputs } from '../../api/leads';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Pagination from '../Common/Pagination';
import Button from '../Common/Button';
import Loading from '../Common/Loading';
import messages from './messages';
import { iconTypes } from '../../core/constants';
import { Lead } from '../../core/types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';
import Heading from '../Common/Page/Heading';

type Props = {
  intl: any;
};

const IncomingLeads: FC<Props> = ({ intl: { formatMessage, formatDate } }) => {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }
  const location = useLocation();
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: 1,
  });
  const [leads, setLeads] = useState<Array<Lead>>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const activeLead = useMemo(() => location?.state, [location?.state]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (activeLead.id) {
      const { data } = await getInputs(
        activeLead.id,
        pageData.page,
        pageData.sizePerPage
      );
      setLeads(data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        };
      });
      setLoading(false);
    }
  }, [pageData.page, pageData.sizePerPage, activeLead]);

  useEffect(() => {
    const data = activeLead;
    if (data) {
      try {
        fetchData();
      } catch (e) {
        navigate(`/leads`);
      }
    } else {
      navigate(`/leads`);
    }
  }, [activeLead, pageData.page, pageData.sizePerPage, fetchData, navigate]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
      <Helmet>
        <title>{`Incoming Leads - ${activeLead.id} | Poltio Platform`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Heading
        title={`${formatMessage(messages.IncomingLeads)} - ${activeLead.id}`}
        isLong={true}
      >
        <Link className="poltio-btn poltio-btn-secondary mr-2" to={'/leads'}>
          <ArrowUturnLeftIcon
            className="w-4 h-4 mr-2 -ml-1"
            aria-hidden="true"
          />
          {formatMessage(messages.GoBack)}
        </Link>

        <div>
          <Button.Primary
            type="button"
            className=""
            onClick={() =>
              navigate(`/reports`, {
                state: {
                  base_id: `${activeLead.id}`,
                  report: `voter-leads`,
                },
              })
            }
          >
            {formatMessage(messages.Download)}
          </Button.Primary>
        </div>
      </Heading>
      <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full">
        <div>
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
            showTotal
          />
          {loading ? (
            <div className="max-w-7xl mx-auto px-2">
              <Loading />
            </div>
          ) : (
            <div className="flex flex-col py-4">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr className="dark:text-gray-200 text-gray-500">
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.UserId)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.ContentType)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.ContentId)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.Name)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.Email)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.Comment)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.CreatedAt)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.Phone)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.PlayTime)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.CorrectAnswer)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.CalculatorScore)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {'Completion'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads.map((l, i) => (
                          <tr
                            key={i}
                            className={
                              i % 2 === 0
                                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900 text-center'
                                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900 text-center'
                            }
                          >
                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                              {l.user_id}
                            </td>
                            <td className="px-6 pl-4 pr-8 text-sm whitespace-nowrap">
                              {l.content_type}
                            </td>

                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.content_id}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.name}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.email}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.comment}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {formatDate(l.created_at)}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.phone}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.session?.play_time ?? 'N/A'}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.session?.right_answer ?? 'N/A'}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.session?.score ?? 'N/A'}
                            </td>
                            <td className="px-6 pl-12 text-sm whitespace-nowrap">
                              <div className="flex-shrink-0">
                                <svg
                                  className={classNames(
                                    l.session?.finished_at
                                      ? 'text-green-400'
                                      : 'text-red-400',
                                    'h-5 w-5'
                                  )}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d={
                                      iconTypes[
                                        l.session?.finished_at
                                          ? 'success'
                                          : 'error'
                                      ]
                                    }
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(IncomingLeads);
