import { useState, Fragment, useEffect, FC } from 'react';
import {
  Dialog,
  Transition,
  Switch,
  DialogPanel,
  DialogTitle,
  Field,
  Label,
  TransitionChild,
} from '@headlessui/react';
import { embedUrlPrefix } from '../../../core/constants';
import { injectIntl } from 'react-intl';
import messages from './messages';
import EmbedSettings from '../../Common/EmbedSettings';
import { Content, User } from '../../../core/types';
import { XMarkIcon } from '@heroicons/react/24/outline';

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  intl: any;
  open: boolean;
  setOpen: (arg: boolean) => void;
  data: Content;
  user: User;
};

const EmbedModal: FC<Props> = ({
  intl: { formatMessage },
  open,
  setOpen,
  data,
  user,
}) => {
  const [embedCode, setEmbedCode] = useState('');
  const [widgetUrl, setWidgetUrl] = useState('');
  const [finalCode, setFinalCode] = useState('');
  const [flyingQs, setFlyingQs] = useState('');
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('800');
  const [isMobile, setIsMobile] = useState(false);
  const [embedType, setEmbedType] = useState('flying');
  // const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setEmbedCode(
      `<iframe id="poltio-embed-${data.public_id}" class="poltio-embed" src="${widgetUrl}" loading="lazy" width="${width}" height="${height}" frameBorder="0" allowFullScreen="allowfullscreen" scrolling="yes" title="${data.title}" allow="clipboard-write"></iframe><script src="https://sdk.poltio.com/widget.js" defer></script>`
    );
  }, [data, width, widgetUrl, height]);

  useEffect(() => {
    setWidgetUrl(
      `https://${embedUrlPrefix}/widget/${data.public_id}?disclaimer=on`
    );
  }, [data]);

  return (
    <>
      <Dialog
        as="div"
        open={open}
        className="fixed inset-0 overflow-y-auto"
        onClose={() => setOpen(false)}
        style={{ zIndex: '200' }}
      >
        <div className="flex fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <DialogPanel
            transition
            className=" inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
          >
            <DialogTitle
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200"
            >
              {formatMessage(messages.CodeGenerator)}
            </DialogTitle>
            <div className="absolute right-0 top-0 hidden pr-5 pt-6 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white dark:bg-gray-800 dark:text-gray-200 text-gray-900 focus:outline-none focus:ring-2 focus:ring-poltio-blue focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 min-h-screen">
              <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-6 lg:grid lg:grid-cols-12">
                <main className="mr-4 lg:col-span-6">
                  <EmbedSettings
                    data={data}
                    embedCode={embedCode}
                    setEmbedCode={setEmbedCode}
                    widgetUrl={widgetUrl}
                    setWidgetUrl={setWidgetUrl}
                    finalCode={finalCode}
                    setFinalCode={setFinalCode}
                    width={width}
                    setWidth={setWidth}
                    height={height}
                    setHeight={setHeight}
                    type={embedType}
                    setType={setEmbedType}
                    user={user}
                    modal={true}
                    flyingQs={flyingQs}
                    setFlyingQs={setFlyingQs}
                  />
                </main>
                <aside className="hidden lg:block lg:col-span-6">
                  <div className="pb-8">
                    <Field as="div" className="flex items-center">
                      <Switch
                        checked={isMobile}
                        onChange={(val) => {
                          setWidth(val ? '70%' : '100%');
                          setIsMobile(val);
                        }}
                        className={classNames(
                          isMobile
                            ? 'bg-poltio-blue-600'
                            : 'bg-gray-200 dark:bg-gray-300',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                        )}
                      >
                        <span className="sr-only">{'Mobile View'}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            isMobile ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-900/50 shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      <Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          {formatMessage(messages.MobileView)}
                        </span>
                      </Label>
                    </Field>
                  </div>
                  {embedType === 'flying' ? (
                    isMobile ? (
                      <div className="relative mx-auto border-gray-800 bg-white dark:bg-gray-900/50 border-[14px] rounded-[2.5rem] h-[600px] w-[320px]">
                        <div className="rounded-[2rem] overflow-hidden w-[292px] h-[572px] dark:bg-gray-900/50 bg-white">
                          <iframe
                            src={`/flying-preview.html${flyingQs}`}
                            width={'100%'}
                            height={'550'}
                            allowFullScreen={true}
                            title="Flying"
                          ></iframe>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="relative mx-auto border-gray-800 dark:border-gray-200 bg-white dark:bg-gray-900/50 border-[16px] rounded-xl h-[700px] w-full">
                          <div className="rounded-xl overflow-hidden h-[140px] md:h-[700px]">
                            <iframe
                              src={`/flying-preview.html${flyingQs}`}
                              width={'100%'}
                              height={'650'}
                              allowFullScreen={true}
                              title="Flying"
                            ></iframe>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: embedCode }} />
                  )}
                </aside>
              </div>
            </div>
          </DialogPanel>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
        </div>
      </Dialog>
    </>
  );
};

export default injectIntl(EmbedModal);
