import { useContext, useEffect, useState } from 'react';
import {
  CheckIcon,
  ArrowPathIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import messages from './messages';
import { submitSettings } from '../../../api';
import { NotificationsCTX } from '../../../contexts/Notification';
import AccountPhoto from './AccountPhoto';
import AccountUsername from './AccountUsername';
import AccountEmail from './AccountEmail';
import Button from '../../Common/Button';

const Status = {
  NoChanges: 0,
  Ok: 1,
  Changed: 2,
  Submitted: 3,
  Error: 4,
};

const AccountSettings = ({
  user: { email: __email, photo: __photo, username: __username },
  intl: { formatMessage },
}) => {
  const { alert, success, error } = useContext(NotificationsCTX);

  const [status, setStatus] = useState(Status.NoChanges);
  const [email, setEmail] = useState(__email);
  const [photo, setPhoto] = useState(__photo);
  const [username, setUsername] = useState(__username);
  const [valid, setValid] = useState({
    email: true,
    photo: true,
    username: true,
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const allValid = Object.values(valid).every(Boolean);
    const valChanged =
      __email !== email || __photo !== photo || __username !== username;

    setButtonDisabled(!allValid || !valChanged);
  }, [__email, __photo, __username, email, photo, username, valid]);

  const onSave = async () => {
    setStatus(Status.Submitted);

    try {
      await submitSettings({
        email: email,
        photo: photo,
        username: username,
      });

      setTimeout(() => {
        setStatus(Status.Ok);
      }, 500);
      success(formatMessage(messages.SuccessChange));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      setStatus(Status.Error);
      setTimeout(() => {
        setStatus(Status.NoChanges);
      }, 5000);

      if (err.response.status === 400) {
        if (err.response.data.msg === 'Username is taken') {
          error(formatMessage(messages.UsernameTaken));
        }
        if (err.response.data.msg === 'Email is taken') {
          error(formatMessage(messages.EmailTaken));
        }
        return;
      }
      // handle 409
      if (err.response.status === 409) {
        alert(
          err.response.data.msg,
          formatMessage(messages.AccountSettingsErrorInUse),
          5000
        );
        return;
      }
    }
  };

  return (
    <div className="divide-y divide-white/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3 ">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
            {formatMessage(messages.AccountSettingsLabel)}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
            {formatMessage(messages.UpdateProfile)}
          </p>
        </div>

        <div className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <AccountPhoto photo={photo} setPhoto={setPhoto} />

            <AccountUsername
              username={username}
              setUsername={setUsername}
              setValid={setValid}
            />
            <AccountEmail
              email={email}
              setEmail={setEmail}
              setValid={setValid}
            />
          </div>

          <div className="mt-8 flex">
            <Button.Primary
              type="submit"
              disabled={buttonDisabled}
              onClick={onSave}
            >
              {formatMessage(messages.AccountSettingsSave)}
            </Button.Primary>
            <label className="inline-flex px-2 align-middle">
              {status === Status.Ok && (
                <CheckIcon className="h-4 w-4 text-green-600" />
              )}
              {status === Status.Submitted && (
                <ArrowPathIcon className="h-4 w-4 text-yellow-500" />
              )}
              {status === Status.Error && (
                <XMarkIcon className="h-4 w-4 text-red-600" />
              )}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AccountSettings);
