import React, { useState, useEffect, useCallback, useContext } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { getDrafts, deleteContent } from '../../../api';
import { injectIntl } from 'react-intl';
import messages from './messages';

import Loading from '../../Common/Loading';
import Pagination from '../../Common/Pagination';
import DraftContentTable from './DraftContentTable';
import Filters from './Filters';
import EditorPage from '../page';
import Button from '../../Common/Button';
import { NotificationsCTX } from '../../../contexts/Notification';
import Heading from '../../Common/Page/Heading';

const DraftContent = ({ intl: { formatMessage } }) => {
  const [showDrafts, setShowDrafts] = useState(false);
  const [DraftContents, setDraftContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: null,
    page: 1,
    sizePerPage: 12,
    lastPage: null,
    order: 'created_at',
    sort: 'desc',
    type: [],
    searchText: '',
  });

  const { success, error } = useContext(NotificationsCTX);

  const fetchDraftContents = useCallback(async () => {
    setLoading(true);
    const { data } = await getDrafts(
      pageData.page,
      pageData.sizePerPage,
      pageData.order,
      pageData.sort,
      pageData.type,
      pageData.searchText
    );
    setDraftContents(data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        ...p,
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: data.total,
        lastPage: data.last_page,
      };
    });
  }, [
    pageData.page,
    pageData.sizePerPage,
    pageData.order,
    pageData.sort,
    pageData.type,
    pageData.searchText,
  ]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  const sortChanged = (val) => {
    setPageData({
      ...pageData,
      order: val.order,
      sort: val.sort,
      page: 1,
    });
  };

  const setSearchText = (val) => {
    setPageData({
      ...pageData,
      searchText: val,
      page: 1,
    });
  };

  const _deleteContent = async (c) => {
    if (window.confirm(formatMessage(messages.AreUSure))) {
      try {
        await deleteContent(c.public_id);
        success(formatMessage(messages.ContentDeleted));
        fetchDraftContents();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
      }
    }
  };

  useEffect(() => {
    fetchDraftContents();
  }, [fetchDraftContents]);

  return showDrafts ? (
    <div>
      <div className="grid justify-items-stretch max-w-7xl mx-auto px-2 py-6 sm:px-6 md:px-8">
        <div className="flex justify-self-start justify-end items-stretch">
          <Button.Primary
            className="self-end"
            onClick={() => setShowDrafts(false)}
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            {formatMessage(messages.BackToEditor)}
          </Button.Primary>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading title={formatMessage(messages.DraftContent)} />
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Filters sortChanged={sortChanged} setSearchText={setSearchText} />
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotalDraft
        />
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <DraftContentTable
              DraftContents={DraftContents}
              fetchDraftContents={fetchDraftContents}
              deleteContent={_deleteContent}
            />
          </div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <EditorPage
      goToDrafts={() => setShowDrafts(true)}
      total={pageData.totalSize}
    />
  );
};

export default injectIntl(DraftContent);
