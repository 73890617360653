import { useParams } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Tab, TabList } from '@headlessui/react';

import { DSConfigCTX, type TDSConfigRouteParams } from '.';
import { useContext } from 'react';
import messages from '../messages';
import { useIntl } from 'react-intl';

type Step = {
  id: string;
  name: string;
  description: string;
  href: string;
  disabled?: boolean;
};
type StepItemProps = {
  step: Step;
  index: number;
  disabled?: boolean;
  isActive?: boolean;
  isComplete?: boolean;
};

function clss(...args: any[]) {
  return args.filter(Boolean).join(' ');
}

function StepItem(props: StepItemProps) {
  const { step, isActive, isComplete } = props;

  return (
    <div className="group">
      {isComplete ? (
        <>
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="h-0.5 w-full bg-poltio-blue" />
          </div>
          <a
            href={step.href}
            className="relative flex h-8 w-8 items-center justify-center rounded-full bg-poltio-blue hover:bg-poltio-blue-800 pointer-events-none"
          >
            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
            <span className="sr-only">{step.name}</span>
          </a>
        </>
      ) : isActive ? (
        <>
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="h-0.5 w-full bg-gray-200" />
          </div>
          <a
            href={step.href}
            className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-poltio-blue bg-white pointer-events-none"
            aria-current="step"
          >
            <span
              className="h-2.5 w-2.5 rounded-full bg-poltio-blue"
              aria-hidden="true"
            />
            <span className="sr-only">{step.name}</span>
          </a>
        </>
      ) : (
        <>
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="h-0.5 w-full bg-gray-200" />
          </div>
          <a
            href={''}
            className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400 pointer-events-none"
          >
            <span
              className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
              aria-hidden="true"
            />
            <span className="sr-only">{step.name}</span>
          </a>
        </>
      )}
    </div>
  );
}

type DSConfigureStepsProps = {
  activeStep: number;
};
export default function DSConfigureSteps(props: DSConfigureStepsProps) {
  const { formatMessage } = useIntl();
  const params = useParams<TDSConfigRouteParams>();
  const { status, elements } = useContext(DSConfigCTX);

  return (
    <nav className="mx-auto max-w-7xl py-6" aria-label="Progress">
      <TabList className="overflow-hidden rounded-md lg:flex lg:border-gray-200">
        <Tab
          className="relative overflow-hidden lg:flex-1 outline-none"
          disabled={params.value !== 'new'}
        >
          {({ selected }) => (
            <div
              className="
                first:rounded-t-md first:border-b-0 last:rounded-b-md last:border-t-0 overflow-hidden border border-gray-200 lg:border-0
                "
            >
              <StepItem
                step={{
                  id: '01',
                  name: formatMessage(messages.DataSourceForm),
                  description: formatMessage(messages.DataSourceFormDesc),
                  href: '',
                }}
                isActive={selected}
                isComplete={params.value !== 'new'}
                index={0}
              />
            </div>
          )}
        </Tab>

        <Tab className="relative overflow-hidden lg:flex-1 outline-none">
          {({ selected }) => (
            <div className="first:rounded-t-md first:border-b-0 last:rounded-b-md last:border-t-0 overflow-hidden border border-gray-200 lg:border-0">
              <StepItem
                step={{
                  id: '02',
                  name: formatMessage(messages.DataSourceConfig),
                  description: formatMessage(messages.DataSourceConfigDesc),
                  href: '',
                }}
                isActive={selected}
                isComplete={props.activeStep > 1}
                index={1}
              />
            </div>
          )}
        </Tab>

        <Tab className="relative" disabled={!elements}>
          {({ selected }) => (
            <div className="first:rounded-t-md first:border-b-0 last:rounded-b-md last:border-t-0 overflow-hidden border border-gray-200 lg:border-0">
              <StepItem
                step={{
                  id: '03',
                  name: status === 'draft' ? 'Publish' : 'View',
                  description: '',
                  href: '',
                }}
                isActive={selected}
                index={2}
              />
            </div>
          )}
        </Tab>
      </TabList>
    </nav>
  );
}
