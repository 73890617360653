import { type FC, useCallback, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

import messages from './messages';

import {
  getLeadsPaginated,
  leadDelete,
  leadSave,
  addCouponCode,
} from '../../api/leads';
import { NotificationsCTX } from '../../contexts/Notification';
import Pagination from '../Common/Pagination';
import Loading from '../Common/Loading';
import Table from '../Common/Table';
import NewLeadForm from './Create/LeadForm';
import Button from '../Common/Button';
import CouponCodes from './CouponCodes';
import LeadsLogs from './LeadsLogs';
import Heading from '../Common/Page/Heading';
import { Lead } from '../../core/types';
import { Helmet } from 'react-helmet';

const columns = [
  { id: 0, name: 'ID', accessor: 'id' },
  { id: 0, name: 'Name', accessor: 'name' },
  { id: 1, name: 'View Count', accessor: 'view' },
  { id: 1, name: 'Redirect Count', accessor: 'click' },
  { id: 1, name: 'Input Count', accessor: 'submit' },
];

type Props = {
  showUI: boolean;
  showCreateNewLead: boolean;
  onCloseNewLead: (p: boolean | null) => boolean;
  intl: any;
};

const Leads: FC<Props> = ({
  showUI = true,
  showCreateNewLead = false,
  onCloseNewLead = () => {},
  intl: { formatMessage },
}) => {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: 1,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [activeLead, setActiveLead] = useState({});
  const [activeIncoming, setActiveIncoming] = useState<Lead>({});
  const [activeLogs, setActiveLogs] = useState({});
  const [activeCoupon, setActiveCoupon] = useState({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCoupon, setOpenCoupon] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);

  const { success, error } = useContext(NotificationsCTX);

  const fetchCodes = useCallback(() => {
    setLoading(true);
    getLeadsPaginated(pageData.page, pageData.sizePerPage).then((response) => {
      setCodes(response.data.data);
      setPageData((prev) => ({
        page: prev.page,
        sizePerPage: prev.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      }));
      setLoading(false);
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({ ...pageData, page, sizePerPage });
  };

  const handleDeleteClick = async (id: number) => {
    if (!window.confirm(formatMessage(messages.SureDelete))) {
      return;
    }

    try {
      await leadDelete(id);
      success(formatMessage(messages.LeadDeleted));
      fetchCodes();
    } catch (err) {
      error(formatMessage(messages.ErrorOccured));
    }
  };

  const handleSubmit = async (
    data: Lead,
    code: any,
    setCode: any,
    apply = () => {},
    reject = () => {}
  ) => {
    if (!data.name || data.name === '') {
      error(formatMessage(messages.LeadEmptyNameError));

      reject();
      return;
    }

    try {
      const lead = await leadSave(data);
      if (code.codes || code.code) {
        await addCouponCode(lead.data.id, code);
        setCode({ single_use: 1 });
      }
      apply();
      success(
        data.id
          ? formatMessage(messages.LeadEditToastTitle)
          : formatMessage(messages.LeadCreatedToastTitle),
        ''
      );

      fetchCodes(); // reload
    } catch (err) {
      Sentry.captureException(err);
      error(formatMessage(messages.ErrorOccured));

      reject();
      return;
    }
  };

  const onEditClick = (h: Lead) => {
    setOpen(true);
    setActiveLead(h);
  };

  const onIncomingLeads = (h: Lead) => {
    setOpenModal(true);
    setActiveIncoming(h);
  };

  const onCouponCodes = (h: Lead) => {
    setOpenCoupon(true);
    setActiveCoupon(h);
  };

  const onLogs = (h: Lead) => {
    setOpenLogs(true);
    setActiveLogs(h);
  };

  useEffect(() => {
    fetchCodes();
  }, [fetchCodes]);

  const openNew = onEditClick;

  // foreign interactions
  useEffect(() => {
    if (showCreateNewLead) {
      onEditClick({});
    } else {
      setOpen(false);
    }
  }, [showCreateNewLead]);

  const onNewLeadFormSetOpen = (value: boolean) => {
    setOpen(value);
    onCloseNewLead(null);
  };

  const onNewLeadFormHandleSubmit = (
    data: Lead,
    code: any,
    setCode: any,
    apply = () => {},
    reject = () => {}
  ) => {
    handleSubmit(
      data,
      code,
      setCode,
      () => {
        apply();
        onCloseNewLead(true);
      },
      () => {
        reject();
        onCloseNewLead(false);
      }
    );
  };

  // -------------------- RENDER --------------------

  return (
    <>
      {showUI ? (
        <div>
          <Helmet>
            <title>Leads | Poltio Platform</title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Heading
              title={formatMessage(messages.PageHeader)}
              body={formatMessage(messages.PageHeaderDesc)}
            >
              <Button.Primary className="self-end" onClick={() => openNew({})}>
                <PlusCircleIcon
                  className="w-5 h-5 mr-2 -ml-1"
                  aria-hidden="true"
                />
                {formatMessage(messages.NewLead)}
              </Button.Primary>
            </Heading>
          </div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
              totalSize={pageData.totalSize}
              showTotal
            />
          </div>
          {loading ? (
            <div className="max-w-7xl mx-auto px-2">
              <Loading />
            </div>
          ) : (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <Table
                  messages={messages}
                  formatMessage={formatMessage}
                  data={codes}
                  cols={columns}
                  openEdit={onEditClick}
                  handleDeleteClick={handleDeleteClick}
                  incomingLeads={onIncomingLeads}
                  couponCodes={onCouponCodes}
                  logs={onLogs}
                />
              </div>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  totalSize={pageData.totalSize}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}
          <LeadsLogs
            open={openLogs}
            setOpen={setOpenLogs}
            activeLead={activeLogs}
          />
        </div>
      ) : null}
      <NewLeadForm
        lead={activeLead}
        open={open}
        setOpen={onNewLeadFormSetOpen}
        handleSubmit={onNewLeadFormHandleSubmit}
      />
    </>
  );
};

export default injectIntl(Leads);
