import { useState, useEffect, useCallback } from 'react';
import { getSessions } from '../../../../api';
import Loading from '../../../Common/Loading';
import Pagination from '../../../Common/Pagination';
import { injectIntl } from 'react-intl';
import messages from '../messages';
import fetchPaginatedData from './fetchData';

const SessionReport = ({
  public_id,
  intl: { formatMessage, formatDate, formatTime },
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 12,
    lastPage: null,
  });

  const fetchData = useCallback(async () => {
    await fetchPaginatedData(
      public_id,
      pageData.page,
      pageData.sizePerPage,
      getSessions,
      setLoading,
      (data) => {
        setData(() => [...(data?.data ?? [])]);
        setPageData((prev) => ({
          ...prev,
          page: prev.page,
          sizePerPage: prev.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        }));
      }
    );
  }, [public_id, pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <div>
        <div className="max-w-7xl mx-auto ml-4">
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
            showTotal
          />
        </div>
        {loading ? (
          <div className="max-w-7xl mx-auto px-2">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className=" overflow-x-auto">
              <div className="inline-block min-w-full align-middle py-4">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                      <tr className="dark:text-gray-200 text-gray-500">
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {formatMessage(messages.VoterID)}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {formatMessage(messages.SessionID)}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {'PUID'}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {'CUSTOM ID'}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {formatMessage(messages.Score)}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {formatMessage(messages.RightAnswer)}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {formatMessage(messages.PlayTime)}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {formatMessage(messages.StartedAt)}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left uppercase"
                        >
                          {formatMessage(messages.FinishedAt)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((session, i) => {
                        return (
                          <tr
                            key={i}
                            className={
                              i % 2 === 0
                                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                            }
                          >
                            <td className="px-6 py-2 text-sm">
                              {session.voter_id}
                            </td>

                            <td className="px-6 py-2 text-sm">
                              {session.session_id}
                            </td>
                            <td className="px-6 py-2 text-sm">
                              {session.uuid}
                            </td>
                            <td className="px-6 py-2 text-sm">
                              {session.custom_id}
                            </td>
                            <td className="px-6 py-2 text-sm">
                              {session.score}
                            </td>
                            <td className="px-6 py-2 text-sm">
                              {session.right_answer}
                            </td>
                            <td className="px-6 py-2 text-sm">
                              {session.play_time}
                            </td>
                            <td className="px-6 py-2 text-sm">
                              {formatDate(session.started_at)}{' '}
                              {formatTime(session.started_at)}
                            </td>
                            <td className="px-6 py-2 text-sm">
                              {session.finished_at === null
                                ? formatMessage(messages.NotFinished)
                                : ` ${formatDate(
                                    session.finished_at
                                  )} ${formatTime(session.finished_at)} `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(SessionReport);
