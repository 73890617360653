const THead = ({ cols = [] }) => {
  return (
    <thead className="bg-gray-50 dark:bg-gray-800">
      <tr className="dark:text-gray-200 text-gray-500">
        {cols.map((col, idx) => (
          <th
            key={`thead-idx-${idx}`}
            scope="col"
            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase first:hidden lg:first:flex"
          >
            {col.name}
          </th>
        ))}
        <th
          scope="col"
          className="w-1 text-xs font-medium tracking-wider text-left uppercase first:hidden lg:first:flex"
        />
      </tr>
    </thead>
  );
};

const TBody = ({ children }) => {
  return children;
};

const Table = ({ cols, children }) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <THead cols={cols} />
              <TBody children={children} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
