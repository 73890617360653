import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  SheetHookStatusSet: {
    id: 'SheetHook.SheetHookStatusSet',
    defaultMessage: 'SheetHook status set.',
  },
  ErrorOccured: {
    id: 'SheetHook.ErrorOccured',
    defaultMessage: 'Error occured!',
  },
  SureDelete: {
    id: 'SheetHook.SureDelete',
    defaultMessage: 'Are you sure you want to delete this sheet hook?',
  },
  SheetHookDeleted: {
    id: 'SheetHook.SheetHookDeleted',
    defaultMessage: 'SheetHook deleted.',
  },
  Payload: {
    id: 'SheetHook.Payload',
    defaultMessage: 'Payload',
  },
  HttpResponse: {
    id: 'SheetHook.HttpResponse',
    defaultMessage: 'Http Response',
  },
  CreatedAt: {
    id: 'SheetHook.CreatedAt',
    defaultMessage: 'Created At',
  },
  PublisherId: {
    id: 'SheetHook.PublisherId',
    defaultMessage: 'Publisher ID',
  },
  UserId: {
    id: 'SheetHook.UserId',
    defaultMessage: 'User ID',
  },
  ViewPayload: {
    id: 'SheetHook.ViewPayload',
    defaultMessage: 'View Payload',
  },
  Close: {
    id: 'SheetHook.Close',
    defaultMessage: 'Close',
  },
  Type: {
    id: 'SheetHook.Type',
    defaultMessage: 'Type',
  },
  Search: {
    id: 'SheetHook.Search',
    defaultMessage: 'Search',
  },
  New: {
    id: 'SheetHook.New',
    defaultMessage: 'New Sheethook',
  },
  SheetHookSaved: {
    id: 'SheetHook.SheetHookSaved',
    defaultMessage: 'SheetHook saved.',
  },
  ErrorSaving: {
    id: 'SheetHook.ErrorSaving',
    defaultMessage: 'Error occured while saving!',
  },
  ErrorNameEmpty: {
    id: 'SheetHook.ErrorNameEmpty',
    defaultMessage: 'Name can not be empty.',
  },
  ErrorContentEmpty: {
    id: 'Sheethook.ErrorContentEmpty',
    defaultMessage: 'Please select a content.',
  },
  EditSheetHook: {
    id: 'SheetHook.EditSheetHook',
    defaultMessage: 'Edit SheetHook',
  },
  CreateNewSheetHook: {
    id: 'SheetHook.CreateNewSheetHook',
    defaultMessage: 'Create New SheetHook',
  },
  ClosePanel: {
    id: 'SheetHook.ClosePanel',
    defaultMessage: 'Close Panel',
  },
  Name: {
    id: 'SheetHook.Name',
    defaultMessage: 'Name',
  },
  Delay: {
    id: 'SheetHook.Delay',
    defaultMessage: 'Delay',
  },
  SendAnswers: {
    id: 'SheetHook.SendAnswers',
    defaultMessage: 'Send Answers',
  },
  SendAllData: {
    id: 'SheetHook.SendAllData',
    defaultMessage: 'Send all the votes with the data.',
  },
  SendLeads: {
    id: 'SheetHook.SendLeads',
    defaultMessage: 'Send Leads',
  },
  SendAllLeads: {
    id: 'SheetHook.SendAllLeads',
    defaultMessage: 'Send all user input leads with the data.',
  },
  Triggers: {
    id: 'SheetHook.Triggers',
    defaultMessage:
      ' Triggers SheetHook even if user did not see the end result',
  },
  Cancel: {
    id: 'SheetHook.Cancel',
    defaultMessage: 'Cancel',
  },
  Save: {
    id: 'SheetHook.Save',
    defaultMessage: 'Save',
  },
  ContentId: {
    id: 'SheetHook.ContentId',
    defaultMessage: 'Content ID',
  },
  Active: {
    id: 'SheetHook.Active',
    defaultMessage: 'Active',
  },
  Health: {
    id: 'SheetHook.Health',
    defaultMessage: 'Health',
  },
  SendDesc: {
    id: 'SheetHook.SendDesc',
    defaultMessage:
      'Poltio will send a POST request with a JSON body to your URL with the following format.',
  },
  To: {
    id: 'SheetHook.To',
    defaultMessage: 'To',
  },
  Method: {
    id: 'SheetHook.Method',
    defaultMessage: 'Method',
  },
  Message: {
    id: 'SheetHook.Message',
    defaultMessage: 'Message',
  },
  SheetHookDesc: {
    id: 'SheetHook.SheetHookDesc',
    defaultMessage:
      'Before adding a new SheetHook please create a new Google Sheet file and share that file with our bot by using the email address',
  },
  NameDesc: {
    id: 'SheetHook.NameDesc',
    defaultMessage:
      'Give your hook a friendly name so you can find it easier later. This will have no effect in the working of sheethooks',
  },
  UrlDesc: {
    id: 'SheetHook.UrlDesc',
    defaultMessage: 'URL of your google sheet file.',
  },
  GoogleSheetURL: {
    id: 'SheetHook.GoogleSheetURL',
    defaultMessage: 'Google Sheet URL',
  },
  CopyURL: {
    id: 'SheetHook.CopyURL',
    defaultMessage: 'Please copy and paste the URL of your google sheet file.',
  },
  LinkCopied: {
    id: 'SheetHook.LinkCopied',
    defaultMessage: 'Link copied.',
  },
  ErrorCopy: {
    id: 'SheetHook.ErrorCopy',
    defaultMessage: 'Error copying link!',
  },
  Sheethooks: {
    id: 'SheetHook.Sheethooks',
    defaultMessage: 'Sheethooks',
  },
  SheethooksDesc: {
    id: 'SheetHook.SheethooksDesc',
    defaultMessage:
      'Sheethooks allows you to connect a Google Sheet with your content to get user interactions in realtime.',
  },
  MoreInfo: {
    id: 'SheetHook.MoreInfo',
    defaultMessage: 'For More Information',
  },
  Docs: {
    id: 'SheetHook.Docs',
    defaultMessage: 'Documentation',
  },
  Logs: {
    id: 'SheetHook.Logs',
    defaultMessage: 'Logs',
  },
});
