import logo from '../images/poltio-logo-texture.svg';
import history from './history';

import { convertToBase64Image } from './helpers/convertToBase64Image';
export { convertToBase64Image };

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) return true;
  return false;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re =
    /(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z0-9]{2,6}/;
  return re.test(email);
};

export const validateUsername = (username) => {
  return /^([a-zA-Z0-9-]{3,})$/.test(username);
};

export const validateHtml = (html) => {
  const re = /<[a-z/][\s\S]*>/;
  const result = html.match(re);
  if (result) {
    const removed = html.replace(result[0], '');
    return removed.length === 0;
  } else {
    return false;
  }
};

export const setBg = () => {
  document.body.style.backgroundImage = `url(${logo})`;
  document.body.style.backgroundAttachment = 'fixed';
  document.body.style.backgroundSize = '100px';
};

export const removeBg = () => {
  document.body.style.backgroundImage = '';
};

export const groupBy = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item['day']]: [...(result[item['day']] || [item['day']]), item.value],
    }),
    {}
  );

export const groupByAddWeek = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']} Week #${item['week']}`]: [
        ...(result[`${item['year']} Week #${item['week']}`] || [
          `${item['year']} Week #${item['week']}`,
        ]),
        item.value,
      ],
    }),
    {}
  );

export const groupByAddMonth = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']}-${item['month']}`]: [
        ...(result[`${item['year']}-${item['month']}`] || [
          `${item['year']}-${item['month']}`,
        ]),
        item.value,
      ],
    }),
    {}
  );

export const groupByAddYear = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']}`]: [
        ...(result[`${item['year']}`] || [`${item['year']}`]),
        item.value,
      ],
    }),
    {}
  );

export const groupByAddDay = (items, locale) => {
  const groupedYear = items.reduce(
    (result, item) => ({
      ...result,
      [`${item['day']}`]: [
        ...(result[`${item['day']}`] || [`${item['day']}`]),
        item.value,
      ],
    }),
    []
  );

  return Object.keys(groupedYear).map((key) => {
    return [
      new Date(groupedYear[key][0]),
      groupedYear[key][1],
      `<big><b>${new Date(groupedYear[key][0]).toLocaleDateString(
        locale
      )}</b></big><br />
      Total: ${groupedYear[key][1]} <br />
      Poll: ${groupedYear[key][2]} <br />
      Quiz: ${groupedYear[key][3]} <br />
      Test: ${groupedYear[key][4]}`,
    ];
  });
};

export const groupByMonthlyVotes = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']}-${item['month']}`]: [
        ...(result[`${item['year']}-${item['month']}`] || [
          `${item['year']}-${item['month']}`,
        ]),
        item.value,
      ],
    }),
    {}
  );

export const compareVoteCounts = (a, b) => {
  if (a.vote_count < b.vote_count) {
    return 1;
  }
  if (a.vote_count > b.vote_count) {
    return -1;
  }
  return 0;
};

export const calculatePercentage = (total, option) =>
  ((option / (total || 1)) * 100).toFixed(1);

export const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export const serialize = (obj) => {
  let builder = [];

  for (let key in obj) {
    if (!Object.hasOwn(obj, key)) {
      continue;
    }
    if (!obj[key]) {
      continue;
    }

    builder.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }

  return builder.join('&');
};

export const isMobile = (width) => {
  return width < 768;
};

const search = history.location.search;
const params = new URLSearchParams(search); // eslint-disable-line no-undef

const loc = params.get('loc');
const locParam = ['en', 'tr'].indexOf(loc) > -1 ? loc : null;
const trLang = navigator.languages.filter(
  (lang) => lang === 'tr' || lang === 'tr-TR'
);

export const language =
  locParam ||
  window.localStorage.getItem('lang') ||
  (trLang && trLang[0]) ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

export const languageWithoutRegionCode = language
  .toLowerCase()
  .split(/[_-]+/)[0];

export const getQuery = (page, per_page, id) => {
  return id
    ? `?${id ? `public_id=${id}&` : ''}page=${page || ''}&per_page=${
        per_page || ''
      }`
    : `?page=${page || ''}&per_page=${per_page || ''}`;
};

export const stripReturnUrl = (searchParams) => {
  let return_url = searchParams.get('return_url');
  if (!return_url) return '/';

  searchParams.delete('return_url');
  return return_url?.includes('error')
    ? '/'
    : `${return_url}${searchParams.size ? `?${searchParams}` : ''}`;
};

export const getUrlList = (user) => {
  if (user && user.domains) {
    let urlList = user?.domains
      ?.filter((d) => d.is_active)
      .map((d) => d.domain);
    urlList.push('poltio.com');
    return urlList;
  } else {
    return 'poltio.com';
  }
};

export const getDefaultDomain = (user) => {
  if (user && user.domains?.length > 0) {
    let defaultCD = user.domains.find((d) => d.is_default);
    if (defaultCD) {
      return defaultCD.domain;
    } else return 'poltio.com';
  } else return 'poltio.com';
};

export const generateRandomColor = () => {
  let rgb = [];
  for (var i = 0; i < 3; i++) {
    rgb.push(Math.floor(Math.random() * 255));
  }

  return 'rgb(' + rgb.join(',') + ')';
};

export const theme = localStorage.poltioTheme;
