import { useState, useEffect, FC } from 'react';
import api from '../../api/client';
// @ts-ignore
import DatePicker, { registerLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import { enUS, tr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/24/solid';

import { TextInput } from '../Common/Form';

import 'react-datepicker/dist/react-datepicker.css';
import { Content } from '../../core/types';

registerLocale('en', enUS);
registerLocale('tr', tr);

function subst(val: string) {
  return `${val.substring(0, 30)} ${val.length > 29 ? '...' : ''}`;
}

type Props = {
  intl: any;
  messages: any;
};

const ContentStats: FC<Props> = ({
  intl: { formatMessage, formatDate, formatTime, locale },
  messages,
}) => {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  const [startDate, setStartDate] = useState(d);
  const [endDate, setEndDate] = useState(new Date());
  const [query, setQuery] = useState(10);
  const [take, setTake] = useState(10);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setTake(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(
        `/platform/dashboard/summary?start=${dayjs(startDate).format(
          'YYYY-MM-DD'
        )}&end=${dayjs(endDate).format('YYYY-MM-DD')}&take=${take}`
      );
      setContents(data);
    }
    fetchData();
  }, [endDate, startDate, take]);

  return (
    <div>
      <div className="flex">
        <div className="self-end md:w-full"></div>
        <div className="mt-1 grid md:flex gap-y-6 gap-x-4 max-w-7xl md:mx-auto md:w-full md:justify-end px-4 md:px-0">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              {formatMessage(messages.StartDate)}
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <CalendarIcon
                  className="h-5 w-5 text-gray-400 z-50 dark:text-gray-100"
                  aria-hidden="true"
                />
              </div>
              <DatePicker
                className="block w-full rounded-md border-0 py-1.5 pl-10 dark:bg-gray-900/30 text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue-600 sm:text-sm sm:leading-6"
                locale={locale.substring(0, 2)}
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                }}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              {formatMessage(messages.EndDate)}
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <CalendarIcon
                  className="h-5 w-5 text-gray-400 z-50 dark:text-gray-100"
                  aria-hidden="true"
                />
              </div>
              <DatePicker
                className="block w-full rounded-md border-0 py-1.5 pl-10 dark:bg-gray-900/30 text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue-600 sm:text-sm sm:leading-6"
                locale={locale.substring(0, 2)}
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              {formatMessage(messages.NumberOfContent)}
            </label>
            <div className="sm:col-span-1 self-end mt-2">
              <TextInput
                id="numberc"
                className="pb-1.5 dark:bg-gray-900/30 dark:text-gray-100"
                name="numberc"
                type="number"
                onChange={(e: any) => setQuery(e.target.value)}
                value={query}
                debounce={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-5">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 dark:border dark:border-solid shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-y-0">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr className="dark:text-gray-200">
                    <th
                      scope="col"
                      className="py-3 pl-6 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      {formatMessage(messages.Name)}
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      {formatMessage(messages.Vote)}
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      {formatMessage(messages.Voter)}
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      {formatMessage(messages.Start)}
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      {formatMessage(messages.Finish)}
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      {formatMessage(messages.CompRate)}
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      {formatMessage(messages.CreatedAt)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contents?.map((c: Content, i: number) => (
                    <tr
                      key={i}
                      className={
                        i % 2 === 0
                          ? 'bg-white dark:bg-gray-800/30 dark:text-gray-100'
                          : 'bg-gray-50 dark:bg-gray-900/30 dark:text-gray-100'
                      }
                    >
                      <td
                        className="py-4 pl-6 text-sm font-mediumwhitespace-nowrap"
                        style={{ textDecoration: 'underline' }}
                      >
                        <Link to={`/c/${c?.content?.public_id}`}>
                          {subst(
                            c.content.name ? c.content.name : c.content.title
                          )}
                        </Link>
                      </td>
                      <td className="px-1 py-4 text-sm font-medium whitespace-nowrap">
                        {c.vote_count}
                      </td>
                      <td className="px-1 py-4 text-sm font-mediumw hitespace-nowrap">
                        {c.voter_count}
                      </td>
                      <td className="px-1 py-4 text-sm font-light whitespace-nowrap">
                        {c.start_count}
                      </td>
                      <td className="px-1 py-4 text-sm font-light whitespace-nowrap">
                        {c.finish_count}
                      </td>
                      <td className="px-1 py-4 text-sm font-medium whitespace-nowrap">
                        {c.content_type === 'poll'
                          ? '-'
                          : `${(c.start_count > 0
                              ? (c.finish_count / c.start_count) * 100
                              : 0
                            ).toFixed(0)}%`}
                      </td>
                      <td className="px-1 py-4 text-sm font-light whitespace-nowrap">{`${formatDate(
                        c.content.created_at
                      )} ${formatTime(c.content.created_at)}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentStats;
