import { useState, Fragment } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { injectIntl } from 'react-intl';

import { webhooksSave } from '../../api';

import messages from './messages';
import Loading from '../Common/Loading';
import Button from '../Common/Button';
import ContentSearch from '../Common/ContentSearch';

const DuplicateModal = ({
  open,
  setOpen,
  hook,
  error,
  success,
  handleSubmit,
  intl: { formatMessage },
}) => {
  const [id, setId] = useState();
  const [saving, setSaving] = useState(false);
  const {
    incomplete_send,
    incomplete_delay,
    delay,
    name,
    url,
    send_answer,
    send_leads,
  } = hook;

  const duplicate = async () => {
    if (!saving) {
      try {
        setSaving(true);
        await webhooksSave({
          incomplete_delay,
          incomplete_send,
          delay,
          name: `${formatMessage(messages.DuplicateOf)} ${name}`,
          url,
          send_answer,
          send_leads,
          public_id: id,
        });
        success(formatMessage(messages.WebhookSaved));
        handleSubmit();
        setSaving(false);
        setOpen(false);
      } catch (e) {
        setSaving(false);
        error(formatMessage(messages.ErrorSaving));
      }
    }
  };

  const onSelect = (item) => {
    setId(item.public_id);
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogPanel className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {saving ? (
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="flex justify-center h-full">
                  <Loading />
                </div>
              </div>
            ) : (
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div style={{ minHeight: '12rem' }}>
                  <div className="flex items-center justify-center w-full">
                    <p className="text-xl">
                      {formatMessage(messages.DuplicateWebhook)}
                    </p>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {formatMessage(messages.SelectContent)}
                    </DialogTitle>
                  </div>
                  <div className="mx-auto flex items-center justify-center rounded-full py-5">
                    <ContentSearch onSelect={onSelect} />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <Button.Secondary onClick={() => setOpen(false)}>
                    {formatMessage(messages.Cancel)}
                  </Button.Secondary>
                  <Button.Primary onClick={duplicate}>
                    {formatMessage(messages.Duplicate)}
                  </Button.Primary>
                </div>
              </div>
            )}
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default injectIntl(DuplicateModal);
