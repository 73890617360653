import { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { getContent, getResults, getContentStats } from '../../../api';
import { NotificationsCTX } from '../../../contexts/Notification';
import ContentCard from '../../Common/ContentCard';
import Loading from '../../Common/Loading';
import StatItem from '../../Common/StatItem';
import Graphs from './Graphs/index';
import PollStats from './PollStats';
import QuizStats from './QuizStats';
import AnswerVotersModal from './AnswerVotersModal';
import EmbedModal from './EmbedModal';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { isMobile } from '../../../core/helper';
import StatList from '../../Common/StatList';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import Heading from '../../Common/Page/Heading';

const ContentDetail = ({ intl, intl: { formatMessage }, user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { error } = useContext(NotificationsCTX);
  const [loading, setLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [data, setData] = useState({});
  const [results, setResults] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [contentStats, setContentStats] = useState({});
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 15,
    lastPage: null,
  });
  const [modalOpen, setModalOpen] = useState(
    !!location?.state?.answerVotersModal
  );
  const [embedModalOpen, setEmbedModalOpen] = useState(
    !!location?.state?.embedModal
  );
  const [datesChanged, setDatesChanged] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const getData = useCallback(async () => {
    if (id) {
      try {
        const { data } = await getContent(id);
        const result = await getResults(
          id,
          pageData.page,
          pageData.sizePerPage
        );
        setData(data);
        setResults(result.data.data);
        setPageData((p) => {
          return {
            ...p,
            page: p.page,
            sizePerPage: p.sizePerPage,
            totalSize: result.data.total,
            lastPage: result.data.last_page,
          };
        });
        setLoading(false);
        //setStartDate(dayjs(data.content.created_at).$d);
      } catch (e) {
        console.log(e);
        error('Error occured!');
      }
    } else {
      navigate('/content');
    }
  }, [error, navigate, id, pageData.page, pageData.sizePerPage]);

  const getStats = useCallback(async () => {
    if (!loading) {
      const data = await getContentStats(
        id,
        datesChanged ? dayjs(startDate).format('YYYY-MM-DD') : null,
        datesChanged ? dayjs(endDate).format('YYYY-MM-DD') : null
      );
      setContentStats(data.data.stats);
      setStatsLoading(false);
    } else {
      return;
    }
  }, [endDate, id, startDate, loading, datesChanged]);

  const { content, metrics } = data;

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getStats();
  }, [startDate, endDate, getStats]);

  useEffect(() => {
    if (!modalOpen && !embedModalOpen) {
      navigate(`/c/${id}`, {});
    }
  }, [modalOpen, embedModalOpen, id, navigate]);

  useEffect(() => {
    const { embedModal, answerVotersModal } = location.state || {};
    if (embedModal) {
      setEmbedModalOpen(true);
    }
    if (answerVotersModal) {
      setModalOpen(true);
    }
  }, [location.state]);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Helmet>
        <title>{`Stats: ${content.name ?? content.title} | Poltio Platform`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 pb-20">
        <Heading title={formatMessage(messages.ContentStats)} />
      </div>
      <div className="mx-auto mt-5 max-w-7xl grid sm:grid-cols-1 lg:grid-cols-3">
        <div
          className={classNames(
            !isMobile(window.innerWidth)
              ? 'px-8 pb-4 col-span-1 mt-2'
              : 'grid grid-cols-1 max-w-7xl mx-auto px-8 sm:px-6 md:px-8 py-4 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8'
          )}
        >
          <ContentCard
            key={`content$-${content.type}-${content.public_id}`}
            content={content}
            fetchContents={() => navigate('/content')}
          />
        </div>
        <div className="px-8 col-span-2">
          {statsLoading ? (
            <Loading />
          ) : (
            <>
              <StatList
                messages={messages}
                stats={contentStats}
                setDatesChanged={setDatesChanged}
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
                intl={intl}
                datesChanged={datesChanged}
                statsPage={true}
                hasTooltip={true}
              />
              <div className="pb-4">
                <dl className="overflow-hidden bg-white dark:bg-gray-900/30 rounded-lg shadow grid grid-cols-1 divide-y divide-gray-200 dark:divide-gray-500 md:grid-cols-3 md:divide-y-0 md:divide-x">
                  <StatItem
                    item={{
                      name: formatMessage(messages.VotesVs),
                      stat: metrics.vote.week.active,
                      previousStat: metrics.vote.week.vs,
                    }}
                  />
                  <StatItem
                    item={{
                      name: formatMessage(messages.VotersVs),
                      stat: metrics.voter.week.active,
                      previousStat: metrics.voter.week.vs,
                    }}
                  />
                  {data.type !== 'poll' ? (
                    <StatItem
                      item={{
                        name: formatMessage(messages.CompletionVs),
                        stat:
                          (metrics.finish.day.active /
                            (metrics.start.day.active || 1)) *
                          100,
                        previousStat:
                          (metrics.finish.day.vs /
                            (metrics.start.day.vs || 1)) *
                          100,
                        isPercent: true,
                      }}
                    />
                  ) : null}
                </dl>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        {content.type === 'poll' ? <PollStats content={content} /> : null}
        {content.type !== 'poll' ? (
          <QuizStats
            data={content}
            results={results}
            pageData={pageData}
            handlePageChange={handlePageChange}
            datesChanged={datesChanged}
            endDate={endDate}
            startDate={startDate}
          />
        ) : null}
      </div>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        {loading ? null : <Graphs content={content} />}
      </div>
      <AnswerVotersModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={content}
        results={results}
      />
      <EmbedModal
        open={embedModalOpen}
        setOpen={setEmbedModalOpen}
        data={content}
        user={user}
      />
    </div>
  );
};

export default injectIntl(ContentDetail);
