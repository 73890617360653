import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { dataSource } from '../../../api';
import Loading from '../../Common/Loading';

import Button from '../../Common/Button';
import { PlusIcon } from '@heroicons/react/20/solid';

import type { DataSourceViewResponse } from '../types';
import SageQuestionCard from './QuestionCard';

import {
  ArrowTopRightOnSquareIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import ElementBasedQuestionGen from './ElementBasedQuestionGen';
import handleNewQuestion from './handleNewQuestion';
import handleReplaceQuestion from './handleReplaceQuestion';
import handleCreateContent from './handleCreateContent';
import messages from '../messages';
import { useIntl } from 'react-intl';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type Question = {
  id?: string;
  question?: string;
  choices?: string[];
  explanation?: string;
  elementId?: string | number;
  [key: string]: any;
};
function SagePage() {
  const { formatMessage } = useIntl();
  const params = useParams<{ id: string }>();

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);

  const [ds, setDS] = useState<DataSourceViewResponse | null>(null);
  const [questions, setQuestions] = useState<Question[]>([{}]);
  const [publicId, setPublicId] = useState<string | null>(null);

  // load DataSource on mount
  useEffect(() => {
    const dsId = params.id;
    if (!dsId || dsId === 'new') {
      setLoading(false);
      return;
    }

    (async () => {
      try {
        const response = await dataSource.get(dsId);

        setDS(response?.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    })();
  }, []);

  const onNewQuestion = async (
    elementId?: number | string,
    options: any = {}
  ) => {
    const dsId = params.id;

    setRequesting(true);
    await handleNewQuestion(dsId ?? null, elementId ?? null, setQuestions);
    setRequesting(false);
  };

  const onReplaceQuestion = async (
    qid?: string,
    elementId?: number | string,
    options: any = {}
  ) => {
    const dsId = params.id;

    setRequesting(true);
    await handleReplaceQuestion(
      qid ?? null,
      dsId ?? null,
      elementId ?? null,
      questions,
      setQuestions
    );
    setRequesting(false);
  };

  const onCreateContent = async () => {
    setLoading(true);
    const publicId = await handleCreateContent(params.id, questions);
    setPublicId(publicId);
    setLoading(false);
  };

  return (
    <div className="relative bg-white pb-24 pt-6 rounded-xl sm:mx-16 sm:pb-32 sm:pt-4">
      {loading ? (
        <div className="absolute backdrop-blur-sm w-full h-full z-30 flex justify-center">
          <Loading />
        </div>
      ) : null}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
          <div className="pb-2 pt-5">
            <h3 className="text-3xl font-semibold leading-6 text-gray-900">
              {ds?.name ?? null}
            </h3>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              {formatMessage(messages.Generate)}
            </p>
            <p className="max-w-4xl text-sm text-gray-500">
              {formatMessage(messages.WeRecommend)}
            </p>
          </div>

          <div className="mt-3 sm:ml-4 sm:mt-0">
            {!publicId ? (
              <Button.Primary
                className="disabled:pointer-events-none"
                disabled={questions.length <= 1}
                onClick={onCreateContent}
              >
                {'Create Content'}
              </Button.Primary>
            ) : null}
          </div>
        </div>

        <div className="flex min-h-full flex-col">
          {!publicId ? (
            <div className="mx-auto flex w-full max-w-7xl items-start gap-x-4 py-2">
              <main className="flex-1 ">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                  {questions.map((question, index) => (
                    <SageQuestionCard
                      key={question.id ?? index}
                      index={index}
                      question={question}
                      onNew={onNewQuestion}
                      onDelete={() => {}}
                      onReplace={(elementId, options) => {
                        onReplaceQuestion(question.id, elementId, options);
                      }}
                    />
                  ))}
                </div>
              </main>

              <aside className="sticky top-8  w-80 shrink-0 xl:block">
                <section className="">
                  <div className="overflow-hidden rounded-lg bg-white shadow">
                    <div className="p-6">
                      <div className=" flow-root">
                        <ul
                          role="list"
                          className={classNames(
                            '-my-5 divide-y divide-gray-200 text-center',
                            requesting ? 'pointer-events-none' : ''
                          )}
                        >
                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                              <Button.Sky
                                disabled={requesting}
                                onClick={() => {
                                  // pass empty string for Element ID to create generic question
                                  onNewQuestion('');
                                }}
                              >
                                <PlusIcon
                                  className="-mr-0.5 h-5 w-5"
                                  aria-hidden="true"
                                />
                                {formatMessage(messages.GenericQuestion)}
                              </Button.Sky>
                              <p className="mt-2 line-clamp-2 text-sm text-gray-600">
                                {formatMessage(messages.GenericQuestionDesc)}
                              </p>
                            </div>
                          </li>
                          <li className="py-5">
                            <ElementBasedQuestionGen
                              disabled={requesting}
                              elements={ds?.data_source_item_elements ?? []}
                              onNew={(elementId) => onNewQuestion(elementId)}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </aside>
            </div>
          ) : (
            <>
              <div className="flex-grow grid grid-cols-2">
                <div className="flex col-span-1 my-2 items-center justify-center">
                  <a
                    className="inline-flex items-center"
                    href={`https://www.poltio.com/widget/${publicId}`}
                    target="_blank"
                  >
                    <span className="font-medium text-lg">
                      {formatMessage(messages.Open)}
                    </span>
                    <ArrowTopRightOnSquareIcon className="h-5 w-5 mx-1" />
                  </a>
                </div>
                <div className="flex col-span-1 my-2 items-center justify-center">
                  <Link
                    to={`/editor/${publicId}`}
                    className="inline-flex items-center"
                  >
                    <span>{formatMessage(messages.Configure)}</span>
                    <Cog6ToothIcon className="h-5 w-5 mx-1" />
                  </Link>
                </div>
                <div className="col-span-2">
                  <iframe
                    id="sage-widget"
                    src={`https://www.poltio.com/widget/${publicId}`}
                    width="100%"
                    className="rounded outline-none poltio-widget ring-0 min-h-[35rem] h-full"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SagePage;
