/* eslint-disable react/style-prop-object */
import { FormattedNumber } from 'react-intl';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  MinusIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { calculatePercentage } from '../../../core/helper';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function getChangeType(active, vs) {
  const diff = active - vs;
  let val = 'same';
  if (diff > 0) {
    val = 'increase';
  } else if (diff < 0) {
    val = 'decrease';
  } else {
    val = 'same';
  }
  return val;
}

const StatItem = ({ item }) => {
  return (
    <div key={item.name} className="px-4 py-5 sm:p-6">
      <dt className="text-base font-normal text-gray-900 dark:text-gray-100">
        {item.name}
      </dt>
      <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
        <div
          className={classNames(
            item.colors
              ? `text-${
                  item.stat <= 60
                    ? 'poltio-red'
                    : item.stat > 60 && item.stat < 80
                      ? 'poltio-yellow'
                      : item.stat > 80 && item.stat < 90
                        ? 'poltio-blue'
                        : 'green-500'
                }`
              : 'text-poltio-blue',
            'flex items-baseline text-2xl font-semibold'
          )}
        >
          {item.isPercent ? (
            <div className="flex items-center gap-x-2">
              <FormattedNumber
                value={item.stat ? item.stat / 100 : 0}
                style={'percent'}
                maximumFractionDigits="0"
              />
              {item.hasTooltip && item.stat < 70 ? (
                <div className="relative group lg:flex items-end hidden">
                  <button className="text-gray-400">
                    <InformationCircleIcon className="h-6 w-6" />
                  </button>
                  <div
                    className="absolute top-0 invisible group-hover:visible right-10 z-50 transform
                       -translate-y-1/2 translate-x-2 w-max 
                       px-2 py-1 text-xs text-white bg-gray-400
                       rounded shadow-lg"
                  >
                    <span className="line-clamp-none w-96 min-h-max">
                      {item.tooltipMessage}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <FormattedNumber value={item.stat || 0} />
          )}

          {typeof item.previousStat == 'number' ? (
            <span className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-200">
              from <FormattedNumber value={item.previousStat} />
            </span>
          ) : null}
        </div>

        {typeof item.previousStat == 'number' ? (
          <div
            className={classNames(
              getChangeType(item.stat, item.previousStat) === 'increase'
                ? 'bg-green-100 text-green-800'
                : getChangeType(item.stat, item.previousStat) === 'decrease'
                  ? 'bg-red-100 text-red-800'
                  : 'bg-blue-100 text-gray-800',
              'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
            )}
          >
            {getChangeType(item.stat, item.previousStat) === 'increase' ? (
              <ArrowUpIcon
                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            ) : getChangeType(item.stat, item.previousStat) === 'decrease' ? (
              <ArrowDownIcon
                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              <MinusIcon
                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-poltio-blue"
                aria-hidden="true"
              />
            )}

            {item.previousStat > 0
              ? `${calculatePercentage(
                  item.previousStat,
                  Math.abs(item.stat - item.previousStat)
                )}%`
              : null}
          </div>
        ) : null}
      </dd>
    </div>
  );
};

export default StatItem;
