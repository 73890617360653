import React, { useState, useContext } from 'react';
import { injectIntl } from 'react-intl';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../Common/Button';
import { resetPassword } from '../../api';
import messages from './messages';
import { ErrorAlert } from '../Common/Alert';
import { NotificationsCTX } from '../../contexts/Notification';
import { TextInput } from '../Common/Form';
import { AuthHeading } from './Heading';
import AuthContainer from './AuthContainer';

const ResetPassword = ({ intl: { formatMessage } }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const { success, error } = useContext(NotificationsCTX);

  const [sending, setSending] = useState(false);

  const [formData, setFormData] = useState({
    pass: '',
    passAgain: '',
  });

  const formInvalid = () => {
    const { pass, passAgain } = formData;

    if (pass.length < 8) {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.PasswordSix),
      });
      return true;
    }
    if (pass !== passAgain) {
      setFormData({
        ...formData,
        errorMessage: formatMessage(messages.PasswordMatch),
      });
      return true;
    }

    return false;
  };

  const { email, resetCode } = useParams();
  const { pass, passAgain } = formData;
  const navigate = useNavigate();

  const onFormSubmit = async (event) => {
    event.preventDefault();
    if (formInvalid()) return;
    setSending(true);

    async function resetPass() {
      if (email && resetCode) {
        try {
          await resetPassword(email, pass, resetCode, passAgain);
          success('', formatMessage(messages.EmailSent));
          navigate(`/login`);
        } catch (e) {
          setSending(false);
          error('', formatMessage(messages.RpError));

          console.log(e);
        }
      }
    }
    resetPass();
  };

  return (
    <AuthContainer>
      <AuthHeading msg={formatMessage(messages.NewPass)} />

      <div className="mt-8">
        <div className="">
          <div>
            <div>
              <TextInput
                text={formatMessage(messages.Pass)}
                id="pass"
                name="pass"
                type="password"
                autoComplete="none"
                required
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pass: e.target.value,
                    errorMessage: null,
                  });
                }}
                value={formData.pass}
              />
              <PasswordStrengthBar password={pass} minLength={8} />
            </div>
            <div>
              <TextInput
                className={classNames(
                  'block w-full shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md',
                  pass === passAgain
                    ? 'focus:ring-green-500 focus:border-green-500'
                    : null
                )}
                text={formatMessage(messages.PassAgain)}
                id="passAgain"
                name="passAgain"
                type="password"
                autoComplete="none"
                required
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    passAgain: e.target.value,
                    errorMessage: null,
                  });
                }}
                value={formData.passAgain}
              />
            </div>
            <div className="flex items-center pt-6">
              <div className="w-full text-center">
                <Button.Primary
                  type="submit"
                  className="flex w-full"
                  disabled={sending}
                  onClick={onFormSubmit}
                >
                  {formatMessage(messages.ResetPassword)}
                </Button.Primary>
              </div>
            </div>

            {formData.errorMessage ? (
              <ErrorAlert title={formData.errorMessage} />
            ) : null}
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};

export default injectIntl(ResetPassword);
