import { Switch } from '@headlessui/react';
import {
  TrashIcon,
  EyeIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';
import Tooltip from '../Common/Button/Tooltip';
import Table from '../Common/Table';

import messages from './messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DomainTable = ({
  domains,
  handleToggleClick,
  handleDeleteClick,
  openEdit,
  statusList,
  intl: { formatMessage },
}) => {
  const columns = [
    { name: 'ID', accessor: 'id' },
    { name: 'Domain', accessor: 'domain' },
    { name: `${formatMessage(messages.Verified)}`, accessor: 'is_verified' },
    { name: `${formatMessage(messages.Active)}`, accessor: 'is_active' },
    { name: `${formatMessage(messages.Default)}`, accessor: 'is_default' },
    { name: `${formatMessage(messages.Status)}`, accessor: 'status' },
  ];
  return (
    <Table cols={columns}>
      <tbody>
        {domains.map((d, i) => (
          <tr
            key={i}
            className={
              i % 2 === 0
                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
            }
          >
            <td className="hidden px-6 py-6 text-sm whitespace-nowrap lg:flex">
              {d.id}
            </td>

            <td className="px-6 py-4 text-sm whitespace-nowrap">{d.domain}</td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              {d.is_verified ? (
                <CheckIcon
                  className="w-7 h-7 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <XMarkIcon
                  className="w-7 h-7 text-red-500"
                  aria-hidden="true"
                />
              )}
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              <Switch
                checked={d.is_active}
                onChange={() => handleToggleClick(d, 'active')}
                className={classNames(
                  d.is_active
                    ? 'bg-poltio-blue-600'
                    : 'bg-gray-200 dark:bg-gray-300',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                )}
              >
                <span className="sr-only">{'Active'}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    d.is_active ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-900/50 shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              <Switch
                checked={d.is_default}
                onChange={() => handleToggleClick(d)}
                className={classNames(
                  d.is_default
                    ? 'bg-poltio-blue-600'
                    : 'bg-gray-200 dark:bg-gray-300',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                )}
              >
                <span className="sr-only">{'Default'}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    d.is_default ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-900/50 shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              <div className="text-sm font-medium flex flex-row group relative">
                <p
                  className={classNames(
                    `${
                      statusList.filter((s) => s.name === d.status)[0].color
                    } px-2  rounded-md`
                  )}
                >
                  {d.status.toUpperCase()}
                </p>
              </div>
            </td>
            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
              <div className="z-0 w-full py-2">
                <span className="relative inline-flex gap-x-2">
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      openEdit(d);
                    }}
                    target="_blank"
                    rel="noreferrer"
                    className="group cursor-pointer relative inline-block border-gray-400 text-center"
                  >
                    <EyeIcon
                      className="w-5 h-5 mr-4 -ml-1 text-gray-400 dark:text-gray-200"
                      aria-hidden="true"
                    />
                    <Tooltip title={formatMessage(messages.ViewDetail)} />
                  </a>
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteClick(d.id);
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TrashIcon
                      className="w-5 h-5 mr-2 -ml-1 text-red-400 dark:text-red-500"
                      aria-hidden="true"
                    />
                  </a>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default injectIntl(DomainTable);
