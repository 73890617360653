import React, { useContext } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import { EditorCTX } from '../../../context';
import { ConfTypes } from '../../../enums';

import Expander from '../../../../Common/Expander';
import QuestionEditor from './QuestionEditor';
import messages from '../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const QuestionForm = ({ intl: { formatMessage } }) => {
  const { activeQuestionIdx: qidx, focus, confType } = useContext(EditorCTX);

  return (
    <Expander
      open={confType === ConfTypes.Question}
      as="div"
      className="bg-white dark:bg-gray-900/30 rounded-lg shadow divide-y divide-gray-200 dark:divide-gray-500"
    >
      <Expander.Button
        className="flex w-full px-2 py-2 sm:px-2"
        onClick={() => {
          focus(ConfTypes.Question, `questions.${qidx}`, { save: false });
        }}
      >
        {({ open }) => (
          <div className="flex justify-between w-full mx-1 my-1 dark:text-gray-200">
            <span className="flex text-xl ">
              {formatMessage(messages.Question)}
            </span>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-5 sm:p-6">
          <QuestionEditor />
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(QuestionForm);
