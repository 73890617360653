import React from 'react';
import { Switch } from '@headlessui/react';
import {
  PencilIcon,
  TrashIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';

import { HealthButton } from '../Common/Button';
import { Link } from 'react-router-dom';
import Tooltip from '../Common/Button/Tooltip';

import messages from './messages';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import Table from '../Common/Table';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const WebhooksTable = ({
  hooks,
  handleToggleClick,
  handleDeleteClick,
  openEdit,
  openDuplicate,
  intl: { formatMessage },
}) => {
  const columns = [
    { name: 'ID', accessor: 'id' },
    { name: `${formatMessage(messages.Name)}`, accessor: 'name' },
    { name: 'URL', accessor: 'url' },
    { name: `${formatMessage(messages.ContentId)}`, accessor: 'public_id' },
    { name: `${formatMessage(messages.Active)}`, accessor: 'is_active' },
    { name: `${formatMessage(messages.Health)}`, accessor: 'health' },
  ];
  return (
    <Table cols={columns}>
      <tbody>
        {hooks.map((h, i) => (
          <tr
            key={i}
            className={
              i % 2 === 0
                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
            }
          >
            <td className="hidden px-6 py-6 text-sm whitespace-nowrap lg:flex">
              {h.id}
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              {h.name?.length > 25 ? `${h.name?.substring(0, 25)}...` : h.name}
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              {h.url?.length > 25 ? `${h.url?.substring(0, 25)}...` : h.url}
            </td>
            <td className="px-6 py-4 text-sm whitespace-nowrap">
              {h.public_id ? (
                <Link
                  className="text-sm font-medium"
                  to={`/c/${h.public_id}`}
                  style={{ cursor: 'pointer' }}
                >
                  {h.public_id}
                </Link>
              ) : h.account_wide ? (
                <span className="text-semibold text-white bg-poltio-red p-2 rounded-md">
                  Account Wide
                </span>
              ) : (
                <span className="text-sm font-medium ">
                  {formatMessage(messages.NoContent)}
                </span>
              )}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              <Switch
                checked={h.is_active}
                onChange={() => handleToggleClick(h)}
                className={classNames(
                  h.is_active
                    ? 'bg-poltio-blue-600'
                    : 'bg-gray-200 dark:bg-gray-300',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                )}
              >
                <span className="sr-only">{'Active'}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    h.is_active ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-900/50 shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              <HealthButton health={h.health}></HealthButton>
            </td>
            <td className="px-6 pl-4 pr-2 text-sm text-gray-500 dark:text-gray-200 whitespace-nowrap">
              <div className="z-0 w-full py-2">
                <span className="relative inline-flex">
                  <Menu as="div" className="relative inline-block text-left">
                    <MenuButton className="mr-5 flex items-center rounded-full text-poltio-blue hover:text-poltio-blue-400">
                      <EllipsisHorizontalIcon className="w-5 h-5" />
                    </MenuButton>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-3 dark:bg-gray-900 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <span className="dark:text-gray-200 font-semibold block px-4 py-2">
                        Actions
                      </span>
                      <MenuItem>
                        <Link
                          href={''}
                          to={`/webhooks/logs/${h.id}`}
                          state={h}
                          rel="noreferrer"
                          className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-400 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-700 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                          {formatMessage(messages.Logs)}
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <a
                          href={''}
                          target="_blank"
                          rel="noreferrer"
                          className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-400 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-700 data-[focus]:text-gray-900 data-[focus]:outline-none"
                          onClick={(e) => {
                            e.preventDefault();
                            openDuplicate(h);
                          }}
                        >
                          {formatMessage(messages.Duplicate)}
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      openEdit(h);
                    }}
                    target="_blank"
                    rel="noreferrer"
                    className="group cursor-pointer relative inline-block border-gray-400 text-center"
                  >
                    <PencilIcon
                      className="w-5 h-5 mr-4 -ml-1 text-poltio-purple hover:text-poltio-purple-hover"
                      aria-hidden="true"
                    />
                    <Tooltip title={formatMessage(messages.Edit)} />
                  </a>
                  <a
                    href={''}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteClick(h.id);
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TrashIcon
                      className="w-5 h-5 mr-2 -ml-1 text-poltio-red hover:text-poltio-red-hover"
                      aria-hidden="true"
                    />
                  </a>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default injectIntl(WebhooksTable);
