/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { SearchButton } from '../../Common/Button';
import messages from './messages';

const sortOptions = [
  { name: 'Recently Created', val: { order: 'created_at', sort: 'desc' } },
  { name: 'Recently Updated', val: { order: 'updated_at', sort: 'desc' } },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Filters = ({ sortChanged, setSearchText, intl: { formatMessage } }) => {
  const [selectedOption, setSelectedOption] = useState('Sort');
  const [text, setText] = useState('');

  const onSortChanged = (name, val) => {
    sortChanged(val);
    setSelectedOption(name);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchText(text);
    }
  };

  return (
    <div>
      <div className="text-center lg:max-w-7xl">
        <section aria-labelledby="filter-heading" className="py-6">
          <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex items-end justify-start">
              <Menu
                as="div"
                className="relative z-10 inline-block text-left mr-2"
              >
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-200">
                    {selectedOption}
                    <ChevronDownIcon
                      className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 dark:text-gray-400 dark:group-hover:text-gray-300  group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              onClick={() =>
                                onSortChanged(option.name, option.val)
                              }
                              className={classNames(
                                active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                'block px-4 py-2 text-sm font-medium text-gray-900 dark:text-gray-200'
                              )}
                              style={{ cursor: 'pointer' }}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>

            <div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="text"
                    name="searchtext"
                    id="searchtext"
                    className="focus:ring-poltio-blue-500 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                    placeholder={formatMessage(messages.EnterText)}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <SearchButton onClick={() => setSearchText(text)} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default injectIntl(Filters);
