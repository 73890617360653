import { Chart } from 'react-google-charts';
import Loading from '../../Common/Loading';
import SankeyChart from './SankeyChart';
import messages from './messages';
import { injectIntl } from 'react-intl';
import DetailCard from '../../Common/DetailCard/index';
import { isMobile } from '../../../core/helper';
import Pagination from '../../Common/Pagination';
import { Link } from 'react-router-dom';
import { getContentMetrics } from '../../../api';
import dayjs from 'dayjs';
import { useState, useCallback, useEffect, useContext } from 'react';
import { NotificationsCTX } from '../../../contexts/Notification';
import VoteStats from './Graphs/VoteStats';
import { SwitchInput } from '../../Common/Form';

const QuizStats = ({
  intl: { formatMessage, formatNumber },
  data,
  results,
  pageData,
  handlePageChange,
  startDate,
  endDate,
  datesChanged,
}) => {
  const [typeValue, setTypeValue] = useState('vote');
  const { error } = useContext(NotificationsCTX);
  const [loading, setLoading] = useState(true);
  const [vsTypeValue, setVsTypeValue] = useState('');
  const [period, setPeriod] = useState('day');
  const [isVoter, setIsVoter] = useState(false);
  const [metrics, setMetrics] = useState({});
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const theme = localStorage.poltioTheme;

  const today = new Date();
  const createdDate = new Date(data.created_at ?? today);
  const checkedDate = new Date('11/05/2024'); //the date we started recording count of question views

  const getData = useCallback(async () => {
    try {
      const response = await getContentMetrics(data.public_id, period, {
        start: datesChanged ? `${dayjs(startDate).format('YYYY-MM-DD')}` : null,
        end: datesChanged ? `${dayjs(endDate).format('YYYY-MM-DD')}` : null,
        metrics: vsTypeValue
          ? [`${typeValue}`, `${vsTypeValue}`]
          : [`${typeValue}`],
      });
      setMetrics(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      error('Error occured!');
    }
  }, [
    data.public_id,
    period,
    startDate,
    endDate,
    typeValue,
    vsTypeValue,
    error,
    datesChanged,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);
  const QuesWOutFree = data.questions.filter(
    (q) => q.answer_type !== 'free_text' && q.answer_type !== 'free_number'
  );

  const fontSize =
    QuesWOutFree.length <= 5
      ? 16
      : QuesWOutFree.length > 5 && QuesWOutFree.length <= 10
        ? 14
        : 12;

  const QTitleLimit =
    QuesWOutFree.length <= 5
      ? 50
      : QuesWOutFree.length > 5 && QuesWOutFree.length <= 10
        ? 30
        : 15;
  //Correct Answer Chart
  const dataCalculated =
    data.type === 'quiz'
      ? data.questions.map((q) => {
          const correctAnswer = q.answers.filter(
            (a) => a.is_right_answer === 1
          )[0];

          return [
            q.title,
            correctAnswer ? correctAnswer.vote_count / q.vote_count : 0,
            correctAnswer
              ? (q.vote_count - correctAnswer.vote_count) / q.vote_count
              : 0,
          ];
        })
      : null;

  const dataCalculatedWithHeaders =
    data.type === 'quiz' ? [['', 'Correct', 'Wrong'], ...dataCalculated] : null;

  //Votes Percentage Split (Vote) Answer Vote Count / Question Vote Count

  const maxNumAnswers = Math.max(
    ...data.questions.map((q) => q.answers.length),
    0
  );

  const dataAnswerNumbers2 = QuesWOutFree.map((q) => [
    QuesWOutFree.length > 5
      ? q.title?.substring(0, QTitleLimit) + '...'
      : q.title,
    ...q.answers.reduce(
      (r, a) => [
        ...r,
        (a.vote_count / q.vote_count) * 100,
        `<big><b>${q.title}:</b></big><br /> ${a.title}: ${
          (a.vote_count / q.vote_count).toFixed(2) * 100
        }%`,
      ],
      []
    ),
    ...Array(maxNumAnswers * 2 - q.answers.length * 2),
  ]);

  const dataAnswerNumbersWithHeaders2 = [
    [...Array(maxNumAnswers).keys()].reduce(
      (r) => {
        return [
          ...r,
          '',
          { type: 'string', role: 'tooltip', p: { html: true } },
        ];
      },
      ['']
    ),
    ...dataAnswerNumbers2,
  ];

  //Votes Percentage Split (Question View)

  const dataAnswerNumbers3 = QuesWOutFree.map((q) => [
    QuesWOutFree.length > 5
      ? q.title?.substring(0, QTitleLimit) + '...'
      : q.title,
    ...q.answers.reduce(
      (r, a) => [
        ...r,
        q.voter_count > 0
          ? (a.vote_count / q.session_vote_count).toFixed(2) * 100
          : 1,
        q.voter_count > 0
          ? `<big><b>${q.title}:</b></big><br /> ${a.title}: ${
              (a.vote_count / q.session_vote_count).toFixed(2) * 100
            }%`
          : `<big><b>This question has 0 voter</b></big>`,
      ],
      []
    ),
    ...Array(maxNumAnswers * 2 - q.answers.length * 2),
  ]);

  const dataAnswerNumbersWithHeaders3 = [
    [...Array(maxNumAnswers).keys()].reduce(
      (r) => {
        return [
          ...r,
          '',
          { type: 'string', role: 'tooltip', p: { html: true } },
        ];
      },
      ['']
    ),
    ...dataAnswerNumbers3,
  ];

  //Aggregated Votes View
  const dataAnswerNumbers = QuesWOutFree.map((q) => [
    QuesWOutFree.length > 5
      ? q.title?.substring(0, QTitleLimit) + '...'
      : q.title,
    ...q.answers.reduce(
      (r, a) => [
        ...r,
        a.vote_count,
        `<big><b>${q.title}:</b></big><br /> ${a.title}: ${a.vote_count}`,
      ],
      []
    ),
    ...Array(maxNumAnswers * 2 - q.answers.length * 2).fill(0),
    q.vote_count,
    '',
  ]);

  const dataAnswerNumbersWithHeaders = [
    [
      ...[...Array(maxNumAnswers).keys()].reduce(
        (r) => {
          return [
            ...r,
            '',
            { type: 'string', role: 'tooltip', p: { html: true } },
          ];
        },
        ['']
      ),
      { role: 'annotation' },
      { role: 'annotation' },
    ],
    ...dataAnswerNumbers,
  ];

  //Dropout Rate Chart
  let dropoutRateData = null;
  let dropoutRateDataWithHeaders = null;

  dropoutRateData = QuesWOutFree.map((q) => [
    q.title,
    parseFloat(100 - ((q.session_vote_count / q.view_count) * 100).toFixed(2)),
  ]);
  dropoutRateDataWithHeaders = [['', ''], ...dropoutRateData];

  //Complation Rate
  const completionData = [
    [{ type: 'string' }, { type: 'number', label: '' }],
    ['Finished', data.finish_count],
    ['Not Finished', data.start_count - data.finish_count],
  ];

  let totalResults = null;
  let resultsDataCalculated = null;
  let resultsDataCalculatedWithHeaders = null;
  let resultsCalculated = [];

  totalResults = results.reduce((result, item) => +result + +item.counter, 0);

  resultsDataCalculated = results.map((r) => [
    r.title,
    (r.counter / totalResults) * 100,
  ]);
  resultsDataCalculatedWithHeaders = [['', ''], ...resultsDataCalculated];

  //Result Click Table

  resultsCalculated = results.map((r) => {
    return {
      title: r.title,
      url: r.url,
      counter: r.counter,
      click_count: r.click_count,
      perc:
        r.click_count > r.counter
          ? 100
          : ((r.click_count * 100) / (r.counter || 1)).toFixed(0),
    };
  });

  return loading ? (
    <Loading />
  ) : (
    <div className="space-y-4">
      {data.type === 'quiz' ? (
        <DetailCard
          title={formatMessage(messages.SuccesFail)}
          subtitle={formatMessage(messages.QuizPercentage)}
        >
          <Chart
            chartType="AreaChart"
            data={dataCalculatedWithHeaders}
            loader={<Loading />}
            width="100%"
            height="600px"
            options={{
              title: formatMessage(messages.MetricsVsGraph),
              titleTextStyle: { color: theme === 'dark' ? 'white' : 'black' },
              hAxis: {
                title: formatMessage(
                  messages[period[0].toUpperCase() + period.slice(1)]
                ),
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: 12,
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
                fontSize: 16,
              },
              vAxis: {
                minValue: 0,
                format: 'short',
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                },
                gridlines: { color: theme === 'dark' ? 'white' : 'black' },
              },
              chartArea: { width: '90%', height: '70%' },
              colors: ['#009EEC', '#D66DDD'],
              backgroundColor: theme === 'dark' ? '' : '#fff',
              crosshair: {
                color: '#000',
                trigger: 'selection',
              },
              animation: {
                easing: 'inAndOut',
                duration: 1500,
              },
              areaOpacity: 1,
              legend: 'bottom',
            }}
            formatters={[
              {
                type: 'NumberFormat',
                column: 1,
                options: {
                  pattern: '##%',
                },
              },
              {
                type: 'NumberFormat',
                column: 2,
                options: {
                  pattern: '##%',
                },
              },
            ]}
          />
        </DetailCard>
      ) : null}
      <DetailCard
        title={formatMessage(messages.MetricsOverview)}
        subtitle={formatMessage(messages.MetricsDesc)}
        datesChanged={datesChanged}
        startDate={`${formatMessage(messages.StartDate)}: ${dayjs(
          startDate
        ).format('YYYY-MM-DD')}`}
        endDate={`${formatMessage(messages.EndDate)}: ${dayjs(endDate).format(
          'YYYY-MM-DD'
        )}`}
      >
        <VoteStats
          metrics={metrics}
          typeValue={typeValue}
          setTypeValue={setTypeValue}
          vsTypeValue={vsTypeValue}
          setVsTypeValue={setVsTypeValue}
          period={period}
          setPeriod={setPeriod}
          formatMessage={formatMessage}
        />
      </DetailCard>

      <DetailCard
        title={formatMessage(messages.VotesbyAnswers)}
        subtitle={formatMessage(messages.VotePercentage)}
      >
        {createdDate > checkedDate ? (
          <div className="w-1/4 flex items-center dark:text-gray-200">
            <span>{formatMessage(messages.Vote)}</span>
            <div>
              <SwitchInput
                name={'isVoter'}
                font="semibold"
                onSwitch={(value) => setIsVoter(value)}
                value={isVoter}
              />
            </div>

            <span className="w-full">
              {formatMessage(messages.QuestionView)}
            </span>
          </div>
        ) : null}

        {!isVoter ? (
          <Chart
            chartType="BarChart"
            data={dataAnswerNumbersWithHeaders2}
            loader={<Loading />}
            width="100%"
            height="600px"
            options={{
              legend: 'none',
              isStacked: 'percent',
              tooltip: { isHtml: true },
              vAxis: {
                textStyle: {
                  fontSize: fontSize,
                  color: theme === 'dark' ? 'white' : 'black',
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
              hAxis: {
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: fontSize,
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
              colors: ['#009EEC', '#D66DDD', '#FF6644', '#FFC000'],
              backgroundColor: theme === 'dark' ? '' : '#fff',
            }}
          />
        ) : (
          <Chart
            chartType="BarChart"
            data={dataAnswerNumbersWithHeaders3}
            loader={<Loading />}
            width="100%"
            height="600px"
            options={{
              legend: 'none',
              isStacked: 'true',
              tooltip: { isHtml: true },
              vAxis: {
                textStyle: {
                  fontSize: fontSize,
                  color: theme === 'dark' ? 'white' : 'black',
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
              hAxis: {
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: fontSize,
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
              colors: ['#009EEC', '#D66DDD', '#FF6644', '#FFC000'],
              backgroundColor: theme === 'dark' ? '' : '#fff',
            }}
          />
        )}
      </DetailCard>

      <DetailCard
        title={formatMessage(messages.AggregatedVotesView)}
        subtitle={formatMessage(messages.VoteNumbers)}
      >
        <Chart
          chartType="BarChart"
          data={dataAnswerNumbersWithHeaders}
          loader={<Loading />}
          width="100%"
          height="600px"
          options={{
            legend: 'none',
            isStacked: true,
            tooltip: { isHtml: true },
            vAxis: {
              textStyle: {
                fontSize: fontSize,
                color: theme === 'dark' ? 'white' : 'black',
              },
            },
            hAxis: {
              textStyle: {
                color: theme === 'dark' ? 'white' : 'black',
                fontSize: fontSize,
              },
              titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
            },
            colors: ['#009EEC', '#D66DDD'],
            backgroundColor: theme === 'dark' ? '' : '#fff',
          }}
        />
      </DetailCard>
      {createdDate > checkedDate ? (
        <DetailCard
          title={formatMessage(messages.DropoutRate)}
          subtitle={formatMessage(messages.DropoutRateDesc)}
        >
          <Chart
            chartType="SteppedAreaChart"
            data={dropoutRateDataWithHeaders}
            loader={<Loading />}
            width="100%"
            height="600px"
            options={{
              legend: 'none',
              isStacked: 'true',
              tooltip: { isHtml: true },
              vAxis: {
                minValue: 0,
                ticks: [0, 20, 40, 60, 80, 100],
                textStyle: {
                  fontSize: fontSize,
                  color: theme === 'dark' ? 'white' : 'black',
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
              hAxis: {
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: fontSize,
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
              colors: ['#009EEC', '#D66DDD', '#FF6644', '#FFC000'],
              backgroundColor: theme === 'dark' ? '' : '#fff',
            }}
          />
        </DetailCard>
      ) : null}
      <DetailCard
        title={formatMessage(messages.ResultStats)}
        subtitle={formatMessage(messages.ResultRates)}
      >
        <div
          className={classNames(
            !isMobile(window.innerWidth)
              ? 'grid grid-cols-2'
              : 'grid grid-cols-1'
          )}
        >
          <Chart
            chartType="PieChart"
            data={completionData}
            loader={<Loading />}
            width="100%"
            height="700px"
            options={{
              title: formatMessage(messages.CompletionRate),
              titleTextStyle: { color: theme === 'dark' ? 'white' : 'black' },
              legend: {
                position: 'bottom',
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: 12,
                },
              },
              is3D: true,
              colors: ['#009EEC', '#D66DDD'],
              backgroundColor: theme === 'dark' ? '' : '#fff',
              hAxis: {
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: fontSize,
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
              vAxis: {
                textStyle: {
                  fontSize: fontSize,
                  color: theme === 'dark' ? 'white' : 'black',
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
            }}
          />
          <Chart
            chartType="BarChart"
            data={resultsDataCalculatedWithHeaders}
            loader={<Loading />}
            width="100%"
            height="700px"
            options={{
              title: formatMessage(messages.SplitResult),
              titleTextStyle: { color: theme === 'dark' ? 'white' : 'black' },
              legend: 'none',
              hAxis: {
                minValue: 0,
                maxValue: 100,
                textStyle: {
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: 12,
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },

              backgroundColor: theme === 'dark' ? '' : '#fff',
              vAxis: {
                textStyle: {
                  fontSize: 12,
                  color: theme === 'dark' ? 'white' : 'black',
                },
                titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
              },
            }}
            formatters={[
              {
                type: 'NumberFormat',
                column: 1,
                options: {
                  pattern: '#,##%',
                },
              },
            ]}
          />
        </div>
      </DetailCard>

      <DetailCard
        title={formatMessage(messages.ResultUrls)}
        subtitle={formatMessage(messages.UrlDesc)}
      >
        <div className="overflow-x-auto border-b border-gray-200 shadow sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr className="dark:text-gray-200 text-gray-500">
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                >
                  {formatMessage(messages.Title)}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                >
                  {formatMessage(messages.ViewCount)}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                >
                  {formatMessage(messages.ClickCount)}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                >
                  {formatMessage(messages.ResultUrl)}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                >
                  {formatMessage(messages.Click)}
                </th>
              </tr>
            </thead>
            <tbody>
              {resultsCalculated.map((row, i) => (
                <tr
                  key={`results${i}`}
                  className={
                    i % 2 === 0
                      ? 'bg-white text-gray-900 dark:bg-gray-800/30 dark:text-gray-100'
                      : 'bg-gray-50 text-gray-900 dark:bg-gray-900/30 dark:text-gray-100'
                  }
                >
                  <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                    {row.title}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                    {row.counter}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                    {row.click_count}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                    {row.url && (
                      <Link to={row.url} target="_blank" rel="noreferrer">
                        {row.url.length > 50
                          ? row.url.substring(0, 50) + '...'
                          : row.url}
                      </Link>
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                    {formatNumber(row.perc / 100, { style: 'percent' })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          // showTotal
        />
      </DetailCard>

      {results ? (
        <DetailCard
          title={formatMessage(messages.SankeyDiagram)}
          subtitle={formatMessage(messages.SankeyDesc)}
        >
          <SankeyChart public_id={data.public_id} />
        </DetailCard>
      ) : null}
    </div>
  );
};

export default injectIntl(QuizStats);
