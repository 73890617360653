import { injectIntl } from 'react-intl';
import messages from './messages';
import SecurityPassword from './SecurityPassword';

const SecuritySettings = ({ intl: { formatMessage } }) => {
  return (
    <div className="divide-y divide-white/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
            {formatMessage(messages.SecuritySettingsLabel)}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
            {formatMessage(messages.ChangePw)}
          </p>
        </div>

        <div className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <SecurityPassword />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SecuritySettings);
