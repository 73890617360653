import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getWebhookLogs } from '../../api';
import { injectIntl } from 'react-intl';

import Pagination from '../Common/Pagination';
import Loading from '../Common/Loading';
import PayloadModal from './PayloadModal';
import messages from './messages';
import ResponseModal from './ResponseModal';
import Heading from '../Common/Page/Heading';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ArrowUturnLeftIcon, ArrowPathIcon } from '@heroicons/react/24/solid';

const LogsTable = ({ intl: { formatMessage, formatDate } }) => {
  const location = useLocation();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const [payloadOpen, setPayloadOpen] = useState(false);
  const [activePayload, setActivePayload] = useState({});
  const [responseOpen, setResponseOpen] = useState(false);
  const [activeResponse, setActiveResponse] = useState({});
  const hook = useMemo(() => location?.state, [location?.state]);

  const fetchData = useCallback(async () => {
    if (hook.id) {
      setLoading(true);
      const { data } = await getWebhookLogs(
        hook.id,
        pageData.page,
        pageData.sizePerPage
      );
      setLogs(data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        };
      });
      setLoading(false);
    }
  }, [pageData.page, pageData.sizePerPage, hook]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
      <Helmet>
        <title>{`Webhook Logs - ${hook.id} | Poltio Platform`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Heading title={`Webhook - ${hook.id} Logs`} isLong={true}>
        <Link className="poltio-btn poltio-btn-secondary mr-2" to={'/webhooks'}>
          <ArrowUturnLeftIcon
            className="w-4 h-4 mr-2 -ml-1"
            aria-hidden="true"
          />
          {formatMessage(messages.GoBack)}
        </Link>
        <button
          className="poltio-btn poltio-btn-secondary"
          onClick={() => fetchData()}
        >
          <ArrowPathIcon className="w-4 h-4" aria-hidden="true" />
        </button>
      </Heading>
      <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full">
        <div>
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
            showTotal
          />
          {loading ? (
            <div className="max-w-7xl mx-auto px-2">
              <Loading />
            </div>
          ) : (
            <div className="flex flex-col py-4">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr className="dark:text-gray-200 text-gray-500">
                          <th
                            scope="col"
                            className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left uppercase lg:flex"
                          >
                            {'ID'}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.Payload)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.HttpResponseCode)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.HttpResponse)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.CreatedAt)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.PublisherId)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                          >
                            {formatMessage(messages.UserId)}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs.map((l, i) => (
                          <tr
                            key={i}
                            className={
                              i % 2 === 0
                                ? 'bg-white font-medium dark:bg-gray-800/30 dark:text-gray-100 text-gray-900'
                                : 'bg-gray-50 font-medium dark:bg-gray-900/30 dark:text-gray-100 text-gray-900'
                            }
                          >
                            <td className="px-6 py-4 text-sm  whitespace-nowrap">
                              {l.id}
                            </td>
                            <td className="text-sm whitespace-nowrap">
                              <button
                                className="w-full text-poltio-blue"
                                onClick={() => {
                                  setActivePayload(l.payload);
                                  setPayloadOpen(true);
                                }}
                              >
                                {formatMessage(messages.ViewPayload)}
                              </button>
                            </td>
                            <td className="text-sm whitespace-nowrap text-center">
                              {l.http_response_code}
                            </td>
                            <td className="pr-2 text-sm whitespace-nowrap">
                              <button
                                className="w-full text-poltio-blue"
                                onClick={() => {
                                  setActiveResponse(l.http_response);
                                  setResponseOpen(true);
                                }}
                              >
                                {formatMessage(messages.ViewResponse)}
                              </button>
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {formatDate(l.created_at)}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.publisher_id}
                            </td>
                            <td className="px-6 pl-4 pr-2 text-sm whitespace-nowrap">
                              {l.user_id}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
          />
        </div>
        <PayloadModal
          open={payloadOpen}
          setOpen={setPayloadOpen}
          payload={activePayload}
        />
        <ResponseModal
          open={responseOpen}
          setOpen={setResponseOpen}
          response={activeResponse}
        />
      </div>
    </div>
  );
};

export default injectIntl(LogsTable);
