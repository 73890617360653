import React, { Fragment } from 'react';

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { ArrowsUpDownIcon, CheckIcon } from '@heroicons/react/24/solid';
import Button from '../Common/Button';
import { useIntl } from 'react-intl';
import messages from './messages';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type SearchRowProps = {
  downloadRequest: () => void;
  downloading: boolean;
  setType: (type: any) => void;
  setID: (id: any) => void;
  typeValue: string;
  idValue: string;
};

const SearchRow = (props: SearchRowProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { downloadRequest, downloading, setType, setID, typeValue, idValue } =
    props;

  const contentTypes = [
    { val: 'content-sessions', name: formatMessage(messages.ContentSession) },
    { val: 'content-voters', name: formatMessage(messages.ContentVoters) },
    { val: 'voter-leads', name: formatMessage(messages.InputLeads) },
  ];

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      downloadRequest();
    }
  };

  return (
    <div className="grid gap-y-3">
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
        <div className="sm:col-span-1">
          <Listbox value={typeValue} onChange={setType}>
            {({ open }) => (
              <>
                <Label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  {formatMessage(messages.Type)}
                </Label>
                <div className="mt-1 relative">
                  <ListboxButton className="bg-white dark:text-gray-200 dark:bg-gray-900/30 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm">
                    <span className="block truncate">
                      {typeValue
                        ? contentTypes.filter((ct) => ct.val === typeValue)[0]
                            .name
                        : formatMessage(messages.Select)}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ArrowsUpDownIcon
                        className="h-5 w-5 text-gray-400 dark:text-gray-200"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <ListboxOptions className="absolute z-10 mt-1 w-full bg-white dark:bg-gray-900 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black dark:ring-gray-200 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {contentTypes.map((c, i) => (
                        <ListboxOption
                          key={i}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'text-white bg-poltio-blue-600'
                                : 'text-gray-900 dark:text-gray-200',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={c.val}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {c.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active
                                      ? 'text-white'
                                      : 'text-poltio-blue-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        <div className="sm:col-span-1">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
          >
            ID
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="id"
              id="id"
              className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 dark:bg-gray-900/30 dark:text-gray-200 rounded-md"
              onChange={(e) => setID(e.target.value)}
              value={idValue}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="sm:col-span-1 flex items-end">
          <Button.Primary
            type="button"
            className=""
            onClick={downloadRequest}
            disabled={downloading || idValue === '' || typeValue === ''}
          >
            {formatMessage(messages.Download)}
          </Button.Primary>
        </div>
      </div>
      <div>
        <div className="mx-1 text-sm text-gray-500">
          {formatMessage(messages.ReportInputDesc)}
        </div>
      </div>
    </div>
  );
};

export default SearchRow;
