import { useContext, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';

import { Expander } from '../../../../Common/Form';
import { EditorCTX } from '../../../context';
import { ConfTypes } from '../../../enums';
import { setPublished } from '../../../../../api/editor';
import { NotificationsCTX } from '../../../../../contexts/Notification';

import messages from '../messages';

import type { IntlFormatters } from 'react-intl';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

type StepType = 'cover' | 'options' | 'question' | 'result';
function getStep(step: StepType, active: StepType | string) {
  // figure out if the step is current, complete or upcoming.
  let stepIdx = {
    [ConfTypes.Cover]: 0,
    [ConfTypes.Options]: 1,
    [ConfTypes.Question]: 2,
    [ConfTypes.Result]: 3,
  };

  // step
  let i = stepIdx[step];
  // active index
  let a = stepIdx[active as StepType];

  return i === a ? 'current' : i < a ? 'complete' : 'upcoming';
}

type NavigationSectionProps = {
  modalOpen: boolean;
  setModalOpen: (...args: any) => void;
};

const NavigationSection = ({
  modalOpen,
  setModalOpen,
}: NavigationSectionProps) => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const {
    data,
    confType: activeType,
    focus,
    save,
    setActiveQuestion,
  } = useContext(EditorCTX);
  const { success, error } = useContext(NotificationsCTX);
  const [searchParams] = useSearchParams();
  const activeQuestionSetRef = useRef(false);

  useEffect(() => {
    if (!activeQuestionSetRef.current && searchParams.get('active_question')) {
      if (data?.questions?.length < 1) return;

      let activeQuestion = parseInt(
        searchParams.get('active_question') ?? '0',
        10
      );

      activeQuestionSetRef.current = true;

      activeQuestion = Math.min(
        Math.max(activeQuestion - 1, 0),
        data.questions.length - 1
      );

      focus(ConfTypes.Question, ``);
      setActiveQuestion(activeQuestion);

      setTimeout(() => {
        const questionTitleInputId = `question-title-${data.questions[activeQuestion].id}`;
        const titleTextArea = document.getElementById(questionTitleInputId);
        if (titleTextArea) {
          titleTextArea.focus();
        }
      }, 500);
    }
  }, [focus, data?.questions?.length]);

  async function _setPublished(_data: typeof data) {
    // save progress so far
    try {
      await save();
    } catch (e) {
      error(formatMessage(messages.WentWrong));
      console.log(e);
      return;
    }

    // publish
    try {
      const response = await setPublished(_data.public_id);
      if (response.status === 200) {
        success(`Content Published`);
        navigate(`/preview/${data.public_id}`);
      }
    } catch (err: any) {
      switch (err?.response?.status) {
        case 403:
          error(formatMessage(messages.DontOwn));
          break;
        case 404:
          error(formatMessage(messages.HaveSave));
          break;
        case 500:
          error(formatMessage(messages.TechError));
          break;
        default:
          error(formatMessage(messages.ErrorOccured));
          break;
      }
    }
  }

  const steps = [
    {
      name: formatMessage(messages.Cover),
      desc: formatMessage(messages.CoverDesc),
      status: getStep(ConfTypes.Cover, activeType),
      onClick: () => focus(ConfTypes.Cover, ''),
    },
    {
      name: formatMessage(messages.OptionsNav),
      desc: formatMessage(messages.OptionsNavDesc),
      status: getStep(ConfTypes.Options, activeType),
      onClick: () => focus(ConfTypes.Options, ''),
    },
    {
      name: formatMessage(messages.NavQuestions),
      desc: formatMessage(messages.QuestionsDesc),
      status: getStep(ConfTypes.Question, activeType),
      onClick: () => focus(ConfTypes.Question, ''),
    },
    {
      name: formatMessage(messages.NavResult),
      desc: formatMessage(messages.ResultDesc),
      status: getStep(ConfTypes.Result, activeType),
      onClick: () => focus(ConfTypes.Result, ''),
    },
    data.is_searchable
      ? {
          name: formatMessage(messages.ResultMapping),
          desc: formatMessage(messages.ResultMappingDesc),
          status: modalOpen ? 'current' : 'upcoming',
          onClick: () => {
            focus(ConfTypes.Result, ''), setModalOpen(!modalOpen);
          },
        }
      : null,
    {
      name: formatMessage(messages.Publish),
      desc: formatMessage(messages.PublishDesc),
      status: data.is_published === 1 ? 'complete' : 'upcoming',
      onClick: () => _setPublished(data),
    },
  ];

  return (
    <Expander
      defaultOpen={true}
      as="div"
      className="overflow-hidden bg-white dark:bg-gray-900/30 rounded-lg shadow divide-y divide-gray-200 dark:divide-gray-500"
    >
      <Expander.Button className="flex w-full px-2 py-2 sm:px-2">
        {({ open }: { open: boolean }) => (
          <div className="flex justify-between w-full mx-2 mt-1 mb-2 dark:text-gray-200">
            <h2 className="flex text-xl">
              {formatMessage(messages.Navigation)}
            </h2>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-5 sm:p-6">
          <nav aria-label="Progress">
            <ol className="overflow-hidden">
              {steps.map((step, stepIdx) =>
                step ? (
                  <li
                    key={step.name}
                    className={classNames(
                      stepIdx !== steps.length - 1 ? 'pb-10' : '',
                      'relative'
                    )}
                  >
                    {step.status === 'complete' ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-poltio-blue-600"
                            aria-hidden="true"
                          />
                        ) : null}
                        <button
                          onClick={step.onClick}
                          className="relative flex items-start text-left group"
                        >
                          <span className="flex items-center h-9">
                            <span className="relative z-10 flex items-center justify-center w-8 h-8 rounded-full bg-poltio-blue-600 group-hover:bg-poltio-blue-800">
                              <CheckIcon
                                className="w-5 h-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </span>
                          <span className="flex flex-col min-w-0 ml-4">
                            <span className="text-xs font-semibold tracking-wide uppercase dark:text-gray-200">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {step.desc}
                            </span>
                          </span>
                        </button>
                      </>
                    ) : step.status === 'current' ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <button
                          onClick={step.onClick}
                          className="relative flex items-start text-left group"
                          aria-current="step"
                        >
                          <span
                            className="flex items-center h-9"
                            aria-hidden="true"
                          >
                            <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white dark:bg-gray-800 border-2 rounded-full border-poltio-blue-600">
                              <span className="h-2.5 w-2.5 bg-poltio-blue-600 rounded-full" />
                            </span>
                          </span>
                          <span className="flex flex-col min-w-0 ml-4">
                            <span className="text-xs font-semibold tracking-wide uppercase text-poltio-blue-600">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {step.desc}
                            </span>
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <button
                          onClick={step.onClick}
                          className="relative flex items-start text-left group"
                        >
                          <span
                            className="flex items-center h-9"
                            aria-hidden="true"
                          >
                            <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white dark:bg-gray-800 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                              <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                            </span>
                          </span>
                          <span className="flex flex-col min-w-0 ml-4">
                            <span className="text-xs font-semibold tracking-wide text-gray-500 dark:text-gray-200 uppercase">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {step.desc}
                            </span>
                          </span>
                        </button>
                      </>
                    )}
                  </li>
                ) : null
              )}
            </ol>
          </nav>
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default NavigationSection;
