import { injectIntl } from 'react-intl';
import Heading from '../../Common/Page/Heading';
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import Button from '../../Common/Button';
import { addToken } from '../../../api';
import { NotificationsCTX } from '../../../contexts/Notification';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { WarningAlert } from '../../Common/Alert';
import messages from './messages';

const CreateToken = ({ intl: { formatMessage } }) => {
  const [name, setName] = useState('');
  const [expires, setExpires] = useState({
    value: '1 Day',
    name: `1 ${formatMessage(messages.Day)}`,
  });
  const [token, setToken] = useState('');

  const navigate = useNavigate();
  const { error, success } = useContext(NotificationsCTX);

  const createToken = async () => {
    try {
      const resp = await addToken(name, expires.value);
      setToken(resp.data.token);
    } catch (err) {
      error(formatMessage(messages.ErrorCreate));
    }
  };

  const copyToken = async (token) => {
    try {
      await navigator.clipboard.writeText(`${token}`);
      success(formatMessage(messages.Copied));
    } catch (e) {
      error(formatMessage(messages.ErrorCopy));
    }
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading
          title={formatMessage(messages.CreateTitle)}
          body={formatMessage(messages.CreateSubtitle)}
        ></Heading>
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        {token ? (
          <WarningAlert
            title={formatMessage(messages.Note)}
            body={
              <p className="ml-2 text-sm font-bold text-gray-600">
                {formatMessage(messages.NoteDesc)}
              </p>
            }
          />
        ) : null}
        <div className="space-y-6 sm:space-y-5 bg-white dark:bg-gray-900/30 rounded-lg p-8 mt-8">
          {!token ? (
            <>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 sm:mt-px sm:pt-2"
                >
                  {formatMessage(messages.Name)}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full max-w-lg rounded-md border-gray-300 focus:border-poltio-blue-500 dark:bg-gray-800 dark:text-gray-200 focus:ring-poltio-blue-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="expires"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 sm:mt-px sm:pt-2"
                >
                  {formatMessage(messages.ExpiresAt)}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="expires"
                    name="expires"
                    onChange={(e) =>
                      setExpires((ex) => ({
                        ...ex,
                        value: e.target.value,
                        name: e.target.options[e.target.options.selectedIndex]
                          .innerHTML,
                      }))
                    }
                    className="block w-full max-w-lg rounded-md border-gray-300 dark:bg-gray-800 dark:text-gray-200 shadow-sm focus:border-poltio-blue-500 focus:ring-poltio-blue-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="1 Day" title="asdasd">{`1 ${formatMessage(
                      messages.Day
                    )}`}</option>
                    <option value="1 Week">{`1 ${formatMessage(
                      messages.Week
                    )}`}</option>
                    <option value="1 Month">{`1 ${formatMessage(
                      messages.Month
                    )}`}</option>
                    <option value="3 Months">{`3 ${formatMessage(
                      messages.Months
                    )}`}</option>
                    <option value="6 Months">{`6 ${formatMessage(
                      messages.Months
                    )}`}</option>
                    <option value="1 Year">{`1 ${formatMessage(
                      messages.Year
                    )}`}</option>
                    <option value="Never">{`${formatMessage(
                      messages.Never
                    )}`}</option>
                  </select>
                </div>
              </div>
            </>
          ) : null}

          {token ? (
            <>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200">
                <div>
                  <label
                    htmlFor="token"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    {name}
                  </label>
                  <label
                    htmlFor="token"
                    className="block text-xs font-medium text-gray-500 sm:mt-px sm:pt-2"
                  >
                    {`${formatMessage(messages.ExpiresIn)}: ${expires.name}`}
                  </label>
                </div>

                <div className="mt-1 sm:col-span-2 sm:mt-0 flex">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <textarea
                      type="text"
                      name="name"
                      id="name"
                      rows={8}
                      cols={55}
                      readOnly
                      value={token}
                      className="block w-full max-w-lg rounded-md border-gray-300 focus:border-poltio-blue-500 focus:ring-poltio-blue-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                  <button onClick={() => copyToken(token)}>
                    <ClipboardIcon
                      className="w-5 h-5 ml-2 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>
        {!token ? (
          <div className="pt-5">
            <div className="flex justify-end">
              <Button.Secondary
                type="button"
                onClick={() => navigate(-1)}
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-poltio-blue-500 focus:ring-offset-2"
              >
                {formatMessage(messages.Cancel)}
              </Button.Secondary>
              <Button.Primary
                type="submit"
                onClick={createToken}
                disabled={token}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-poltio-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-poltio-blue-700 focus:outline-none focus:ring-2 focus:ring-poltio-blue-500 focus:ring-offset-2"
              >
                {formatMessage(messages.Create)}
              </Button.Primary>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default injectIntl(CreateToken);
