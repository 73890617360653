import { useContext, type FC } from 'react';
import {
  CalendarIcon,
  UserGroupIcon,
  RectangleStackIcon,
  HeartIcon,
} from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';
// @ts-ignore
import { defineMessages } from 'react-intl.macro';
import { feedDailyImagePathRetina } from '../../../core/constants';
import { Link } from 'react-router-dom';
import { NotificationsCTX } from '../../../contexts/Notification';

import CardStat from './CardStat';
import ContentCardOptions from './ContentCardOptions';
import { Content } from '../../../core/types';

const messages = defineMessages({
  VoterCount: {
    id: 'ContentCard.VoterCount',
    defaultMessage: 'Unique Start',
  },
  VoteCount: {
    id: 'ContentCard.VoteCount',
    defaultMessage: 'Vote Count',
  },
  ViewStats: {
    id: 'ContentCard.ViewStats',
    defaultMessage: 'Stats',
  },
  OpenInNewTab: {
    id: 'ContentCard.OpenInNewTab',
    defaultMessage: 'Open',
  },
  CreatedAt: {
    id: 'ContentCard.CreatedAt',
    defaultMessage: 'Created At',
  },
  EndDate: {
    id: 'ContentCard.EndDate',
    defaultMessage: 'End Date',
  },
  ContentIdCopied: {
    id: 'ContentCard.ContentIdCopied',
    defaultMessage: 'Content id copied.',
  },
  InternalName: {
    id: 'ContentCard.InternalName',
    defaultMessage: 'Internal Name',
  },
  ExternalName: {
    id: 'ContentCard.ExternalName',
    defaultMessage: 'External Name',
  },
  TooltipMessage: {
    id: 'ContentCard.TooltipMessage',
    defaultMessage: 'Click for copy content id.',
  },
});

type Props = {
  content: Content;
  intl: any;
  fetchContents: () => {};
};

const ContentCard: FC<Props> = ({
  content,
  intl: { formatDate, formatTime, formatMessage },
  fetchContents,
}) => {
  const end_date = new Date(content?.end_date);
  const { error, success } = useContext(NotificationsCTX);

  const copyPublicId = async () => {
    try {
      await navigator.clipboard.writeText(`${content.public_id}`);
      success(formatMessage(messages.ContentIdCopied));
    } catch (e) {
      error('Error copying embed code!');
    }
  };

  return (
    <div className="flex flex-col items-center text-center bg-white dark:bg-gray-900/30 dark:divide-gray-500 dark:text-gray-100 rounded-lg shadow col-span-1 divide-y divide-gray-200">
      <div className="flex flex-col flex-1 w-full p-1 pt-4 pl-3 pr-3">
        <div className="rounded-lg overflow-y-hidden max-h-60">
          <Link
            to={`/preview/${content.public_id}`}
            style={{ cursor: 'pointer' }}
          >
            <img
              className="flex-shrink-0 w-full mx-auto"
              src={
                content.background
                  ? `${feedDailyImagePathRetina}/${content.background}`
                  : `${feedDailyImagePathRetina}/placeholder/question/1.png`
              }
              alt=""
            />
          </Link>
        </div>
        <div className="mt-4 text-sm font-medium text-gray-900 dark:text-gray-100 truncate h-20">
          <p className="text-xs font-normal text-gray-400 dark:text-gray-200">
            {formatMessage(messages.ExternalName)}
          </p>
          <Link
            to={`/preview/${content.public_id}`}
            style={{ cursor: 'pointer' }}
            className="text-poltio-blue"
          >
            {content.title}
          </Link>
          <p className="text-xs text-gray-400 dark:text-gray-200 font-normal">
            {formatMessage(messages.InternalName)}
          </p>
          <p className="text-xs font-medium"> {content.name}</p>
        </div>

        <div className="justify-between w-full">
          <dl className="-mt-1 mr-1 divide-y space-y-0 divide-gray-200 dark:divide-gray-500">
            <CardStat
              type="div"
              label={`${content.type?.toUpperCase()} `}
              value={content.public_id}
              tinput
              onClick={copyPublicId}
              tooltipText={formatMessage(messages.TooltipMessage)}
            >
              <RectangleStackIcon
                className="w-5 h-5 text-gray-500"
                aria-hidden="true"
              />
            </CardStat>

            <CardStat
              label={formatMessage(messages.VoteCount)}
              value={content.vote_count}
              isFormattedNumber
            >
              <HeartIcon className="w-5 h-5 text-pink-500" aria-hidden="true" />
            </CardStat>

            <CardStat
              label={formatMessage(messages.VoterCount)}
              value={content.voter_count}
              isFormattedNumber
            >
              <UserGroupIcon
                className="w-5 h-5 text-blue-500"
                aria-hidden="true"
              />
            </CardStat>

            <CardStat
              label={formatMessage(messages.CreatedAt)}
              value={`${formatDate(content.created_at)}`}
            >
              <CalendarIcon
                className="w-5 h-5 text-green-500"
                aria-hidden="true"
              />
            </CardStat>

            {content.end_date ? (
              <CardStat
                label={formatMessage(messages.EndDate)}
                value={`${formatDate(end_date)} ${formatTime(end_date)} `}
              >
                <CalendarIcon
                  className="w-5 h-5 text-red-500"
                  aria-hidden="true"
                />
              </CardStat>
            ) : null}
          </dl>
        </div>
      </div>
      <div className="w-full">
        <ContentCardOptions content={content} fetchContents={fetchContents} />
      </div>
    </div>
  );
};

export default injectIntl(ContentCard);
