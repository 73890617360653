import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { FormattedMessage } from 'react-intl.macro';

import { sheethooksSave } from '../../api';
import { ClipboardIcon } from '@heroicons/react/24/solid';
import SlideOver from '../Common/SlideOver';
import ContentSearch from '../Common/Form/ContentSearch';
import { Helmet } from 'react-helmet';
import { TextAreaInput, TextInput } from '../Common/Form';

const defaultData = { name: '', sheet_id: '' };

const SheetHookForm = ({
  open,
  setOpen,
  hook,
  success,
  error,
  handleSubmit,
  intl: { formatMessage },
  selectedContent,
}) => {
  const [data, setData] = useState(defaultData);
  const [saving, setSaving] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    if (selectedContent) {
      setId(selectedContent);
    }
    if (hook.id) {
      setData(hook);
      setId(hook.public_id);
    } else {
      setData(defaultData);
    }
  }, [open, hook, selectedContent]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (formValid() && !saving) {
      try {
        setSaving(true);
        await sheethooksSave({
          ...data,
          public_id: id,
          hook_id: hook.id,
        });
        success(formatMessage(messages.SheetHookSaved));
        handleSubmit();
        setSaving(false);
        setOpen(false);
      } catch (e) {
        setSaving(false);
        error(formatMessage(messages.ErrorSaving));
      }
    }
  };

  const copyLink = (e) => {
    try {
      e.preventDefault();
      navigator.clipboard.writeText(
        `api-184@poltio-164412.iam.gserviceaccount.com`
      );
      success(formatMessage(messages.LinkCopied));
    } catch (e) {
      console.log(e);
      error(formatMessage(messages.ErrorCopy));
    }
  };

  const getIdFromUrl = (url) => {
    let matches = /\/([\w-_]{15,})\/(.*?gid=(\d+))?/.exec(url);
    if (matches) {
      return matches[1] ?? url;
    }
    return url;
  };

  const formValid = () => {
    const { name } = data;
    if (id?.length < 1) {
      error(formatMessage(messages.ErrorContentEmpty));
      return false;
    }
    if (name?.length < 1) {
      error(formatMessage(messages.ErrorNameEmpty));
      return false;
    }
    return true;
  };

  const onSelect = (item) => {
    setId(item.public_id);
  };

  return (
    <SlideOver
      open={open}
      setOpen={() => {}}
      isLoading={saving}
      onSave={onFormSubmit}
      isDisabled={saving}
      onCancel={() => setOpen(false)}
    >
      <Helmet>
        <title>
          {data.name
            ? `Editing Sheethook: ${data.name} | Poltio Platform`
            : `Creating Sheethook | Poltio Platform`}
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <SlideOver.Header
        title={
          hook.id
            ? formatMessage(messages.EditSheetHook)
            : formatMessage(messages.CreateNewSheetHook)
        }
        subtitle={
          hook.id ? (
            <FormattedMessage
              id="Sheethook.CanEdit"
              defaultMessage="You can edit and save your Sheethook for {content_type} #{content_id}"
              values={{
                content_id: data.content_id,
                content_type: data.content_type,
              }}
            />
          ) : null
        }
        setOpen={setOpen}
      />

      <SlideOver.Body>
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="pt-6 pb-5 space-y-6">
            <p className="dark:text-gray-300">
              {formatMessage(messages.SheetHookDesc)}:{' '}
            </p>
            <p className="p-2 font-bold text-green-400 bg-gray-600 rounded-lg bg-opacity-80 flex flex-row">
              <button className="mr-4" onClick={copyLink}>
                <ClipboardIcon
                  className="w-5 h-5 text-green-400"
                  aria-hidden="true"
                />
              </button>
              <span className="w-full">
                api-184@poltio-164412.iam.gserviceaccount.com
              </span>
            </p>
            <div>
              <ContentSearch
                onSelect={onSelect}
                publicId={id || hook.public_id}
              />
            </div>
            <div className="pt-10">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {formatMessage(messages.Name)}
              </label>
              <div className="mt-1">
                <TextInput
                  type="text"
                  name="name"
                  id="name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
                <p className="mt-2 text-xs text-gray-400">
                  {formatMessage(messages.NameDesc)}
                </p>
              </div>
            </div>
            <div>
              <label
                htmlFor="url"
                className="block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {formatMessage(messages.GoogleSheetURL)}
              </label>
              <p className="mt-2 text-xs text-gray-400">
                {formatMessage(messages.CopyURL)}
              </p>
              <div className="mt-1">
                <TextAreaInput
                  id="sheetId"
                  name="sheetId"
                  rows={4}
                  value={data.sheet_id}
                  onChange={(e) =>
                    setData({
                      ...data,
                      sheet_id: getIdFromUrl(e.target.value),
                    })
                  }
                />
                <p className="mt-2 text-xs text-gray-400">
                  {formatMessage(messages.UrlDesc)}
                </p>
              </div>
            </div>
            <div className="py-2 mt-10 max-w-md text-sm text-gray-600 dark:text-gray-400 font-medium">
              {formatMessage(messages.MoreInfo)}{' '}
              <a
                className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                href="https://platform.poltio.com/docs/sheethooks/"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage(messages.Docs)}
              </a>
            </div>
          </div>
        </div>
      </SlideOver.Body>
      <SlideOver.Footer />
    </SlideOver>
  );
};

export default injectIntl(SheetHookForm);
