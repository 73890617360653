import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import { NotificationsCTX } from '../../../contexts/Notification';
import Button from '../../Common/Button';
import { dataSource, setDataSource } from '../../../api';
import messages from '../messages';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';

function classNames(...args: any[]) {
  return args.filter(Boolean).join(' ');
}

type CSVUploadBlockProps = {
  file?: File;
  onupload: (file: File) => void;
};
const CSVUploadBlock = (props: CSVUploadBlockProps) => {
  const { file, onupload } = props;
  const [dragging, setDragging] = useState(false);

  const { formatMessage } = useIntl();

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragging(true);
    } else if (event.type === 'dragleave') {
      setDragging(false);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      onupload(file);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setDragging(false);

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      onupload(file);
    }
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {formatMessage(messages.UploadFile)}
      </label>
      <div
        className={classNames(
          'mt-1 sm:col-span-2 sm:mt-0 max-w-lg flex justify-center rounded-lg border-2 border-dashed px-6 py-10',
          dragging ? 'border-poltio-blue-400' : 'border-gray-900/25'
        )}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className="text-center">
          <ArrowUpTrayIcon
            className="mx-auto h-12 w-12 text-gray-700"
            aria-hidden="true"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            {file ? (
              <p className="pl-1">{file.name}</p>
            ) : (
              <>
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-transparent font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>{formatMessage(messages.UploadFile)}</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    className="sr-only"
                    type="file"
                    accept=".csv"
                    multiple={false}
                    onChange={handleChange}
                  />
                </label>
                <p className="pl-1">{formatMessage(messages.DragAndDrop)}</p>
              </>
            )}
          </div>
          {/* <p className="text-xs leading-5 text-gray-600">{'PNG, JPG, GIF up to 10MB'}</p> */}
        </div>
      </div>
    </div>
  );
};

type TFormData = {
  type: 'xml' | 'json' | 'csv' | string;
  file?: File;
  [key: string]: any;
};
type TDSCreateCSVResponse = {
  id: number | string;
  name: string;
  type: 'csv';
  source: string;
  status: 'draft' | string;
  created_at: string;
  updated_at: string;
  data_source_notes: string[];
};
function CreatePanel() {
  const { formatMessage } = useIntl();
  const [dataSources, setDataSources] = useState<TFormData>({ type: '' });
  const { error, success } = useContext(NotificationsCTX);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigate = useNavigate();

  const types = [
    { name: 'XML', val: 'xml' },
    { name: 'JSON', val: 'json' },
    { name: 'CSV', val: 'csv' },
  ];

  const saveCSV = async (data: TFormData) => {
    if (!data.file) {
      error('Please upload a CSV file');
      return;
    }

    let payload = new FormData();
    Object.keys(data).forEach((key) => {
      // skip file and source keywords
      if (['file', 'source'].includes(key)) {
        return;
      }

      payload.append(key, data[key]);
    });
    payload.append('source_file', data.file);

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    let response: AxiosResponse<TDSCreateCSVResponse>;
    response = await setDataSource(payload, config);

    success('Your DataSource created as successfully');

    // TODO: maybe more smooth transition...
    const { id } = response.data;
    navigate(`/data-source/${id}`);
  };

  const saveOther = async (data: TFormData) => {
    let payload = Object.keys(data).reduce((acc, key) => {
      if (['file'].includes(key)) {
        return acc;
      }

      acc[key] = data[key];
      return acc;
    }, {} as any);

    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await setDataSource(payload, config);
    success('Your DataSource created as successfully');
    navigate('/data-source');
  };

  const saveChanges = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      setButtonDisabled(true);
      if (dataSources.type === 'csv') {
        saveCSV(dataSources);
      } else {
        saveOther(dataSources);
      }
    } catch (e) {
      let err = e as Error | AxiosError;
      if (!isAxiosError(err) || !err.response) {
        error('Something went wrong');
        return;
      }

      if (err.response.status === 409) {
        error('Already submitted this source.');
      }
      if (err.response.status === 500) {
        error(err.response.data.msg);
      }
      setButtonDisabled(false);
    }
  };

  const selectType = async (
    event: React.MouseEvent<HTMLButtonElement>,
    type: string
  ) => {
    event.preventDefault();

    setDataSources({
      type,
    });
  };

  const handleTextChange = async (name: string, val: any) => {
    setDataSources({
      ...dataSources,
      [name]: val,
    });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-200 max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {dataSources.type
                ? formatMessage(messages.Form)
                : formatMessage(messages.SelectType)}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {formatMessage(messages.FormDesc)}
            </p>
          </div>
          {!dataSources.type ? (
            <>
              <div className="flex justify-center gap-x-5 max-w-7xl">
                {types.map((type, i) => (
                  <Button.Secondary
                    key={i}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      selectType(e, type.val)
                    }
                    className="rounded-md border border-gray-300 bg-white py-12 w-1/4 text-lg font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-poltio-blue-500 focus:ring-offset-2"
                  >
                    {type.name}
                  </Button.Secondary>
                ))}
              </div>
              <div className="flex flex-col text-xl font-medium text-poltio-blue pt-16">
                <a
                  className="cursor-pointer max-w-fit"
                  target="_blank"
                  href="https://platform.poltio.com/ds/Poltio%20DataSource%20CSV%20Example%20-%20iPhone%20Example.csv"
                >
                  {formatMessage(messages.Example)}
                </a>
                <a
                  className="cursor-pointer max-w-fit"
                  target="_blank"
                  href="https://platform.poltio.com/docs/shopify/"
                >
                  {formatMessage(messages.FeedShopify)}
                </a>
              </div>
            </>
          ) : (
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {formatMessage(messages.Name)}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) =>
                        handleTextChange(e.target.name, e.target.value)
                      }
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-poltio-blue-500 focus:ring-poltio-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              {dataSources.type === 'csv' ? (
                <CSVUploadBlock
                  file={dataSources.file}
                  onupload={(file) => handleTextChange('file', file)}
                />
              ) : (
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <label
                      htmlFor="source"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      {formatMessage(messages.Source)}
                    </label>
                    <p className="mt-2 text-xs text-gray-500">
                      {formatMessage(messages.SourceDesc, {
                        type: dataSources.type.toUpperCase(),
                      })}
                    </p>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="source"
                        id="source"
                        onChange={(e) =>
                          handleTextChange(e.target.name, e.target.value)
                        }
                        className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-poltio-blue-500 focus:ring-poltio-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="pt-5 pb-5">
        <div className="flex justify-end">
          <Button.Secondary
            type="button"
            onClick={
              dataSources.type
                ? (e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault(),
                      setDataSources(() => ({
                        type: '',
                        source: '',
                        name: '',
                      }));
                  }
                : () => navigate(-1)
            }
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-poltio-blue-500 focus:ring-offset-2"
          >
            {formatMessage(messages.Cancel)}
          </Button.Secondary>
          <Button.Primary
            type="submit"
            onClick={saveChanges}
            disabled={
              (!dataSources.source && !dataSources.file) ||
              !dataSources.name ||
              dataSources?.name?.trim().length === 0 ||
              buttonDisabled
            }
            showSpinner={buttonDisabled}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-poltio-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-poltio-blue-700 focus:outline-none focus:ring-2 focus:ring-poltio-blue-500 focus:ring-offset-2"
          >
            {formatMessage(messages.Save)}
          </Button.Primary>
        </div>
      </div>
    </div>
  );
}

export default CreatePanel;
