import Testimonials from './Testimonial';
import bg from '../../images/bg5.png';
import { useLayoutEffect } from 'react';

const AuthContainer = ({ children }) => {
  useLayoutEffect(() => {
    const theme = localStorage.getItem('poltioTheme');
    if (theme === 'dark') {
      window.document.documentElement.classList.add('dark');
    }
  }, []);

  return (
    <div className="min-w-full dark:bg-gray-800">
      <div className="flex min-h-screen overflow-hidden flex-1 max-w-7xl mx-auto">
        <div className="flex flex-1 flex-col px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">{children}</div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block bg-gray-900">
          <img
            className="absolute inset-0 h-full w-full object-cover opacity-10"
            src={bg}
            alt=""
          />
          <Testimonials />
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
