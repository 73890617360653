import { Dispatch, Fragment, SetStateAction, FC } from 'react';
import { Chart } from 'react-google-charts';
import Loading from '../../../Common/Loading';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { ArrowsUpDownIcon, CheckIcon } from '@heroicons/react/24/solid';
import messages from '../messages';

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}
type MetricsType = {
  val: string;
  name: string;
};

type DropDownProps = {
  typeValue: string;
  setTypeValue: Dispatch<SetStateAction<string>>;
  metricTypes: Array<MetricsType>;
  first: boolean;
  formatMessage: any;
};

type Props = {
  metrics?: Array<object>;
  typeValue: string;
  setTypeValue: Dispatch<SetStateAction<string>>;
  vsTypeValue: string;
  setVsTypeValue: Dispatch<SetStateAction<string>>;
  period: string;
  setPeriod: Dispatch<SetStateAction<string>>;
  formatMessage: any;
};

const DropDown: FC<DropDownProps> = ({
  typeValue,
  setTypeValue,
  metricTypes,
  first,
  formatMessage,
}) => (
  <Listbox value={typeValue} onChange={setTypeValue}>
    {({ open }) => (
      <>
        <Label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
          {formatMessage(messages.MetricsType)}
        </Label>
        <div className="mt-1 relative">
          <ListboxButton
            className={classNames(
              'bg-white dark:bg-gray-800 dark:text-gray-100 relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:ring-1 focus:outline-none sm:text-sm',
              first
                ? 'border-[#009EEC] focus:ring-[#009EEC]'
                : 'border-[#D66DDD] focus:ring-[#D66DDD]'
            )}
          >
            <span className="block truncate">
              {typeValue
                ? metricTypes.filter((ct) => ct.val === typeValue)[0].name
                : formatMessage(messages.Select)}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ArrowsUpDownIcon
                className="h-5 w-5 text-gray-400 dark:text-gray-300"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {metricTypes.map((c, i) => (
                <ListboxOption
                  key={i}
                  className={({ active }) =>
                    classNames(
                      active
                        ? 'text-white bg-poltio-blue-600'
                        : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                  value={c.val}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate'
                        )}
                      >
                        {c.name}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-poltio-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);

const VoteStats: FC<Props> = ({
  metrics,
  typeValue,
  setTypeValue,
  vsTypeValue,
  setVsTypeValue,
  period,
  setPeriod,
  formatMessage,
}) => {
  const metricTypes = [
    { val: '', name: 'None' },
    { val: 'view', name: formatMessage(messages.View) },
    { val: 'vote', name: formatMessage(messages.Vote) },
    { val: 'voter', name: formatMessage(messages.Voter) },
    { val: 'start', name: formatMessage(messages.Start) },
    { val: 'finish', name: formatMessage(messages.Finish) },
    { val: 'undo', name: formatMessage(messages.Undo) },
    { val: 'result_view', name: formatMessage(messages.ResultView) },
    { val: 'result_click', name: formatMessage(messages.ResultClick) },
    {
      val: 'result_click_unique',
      name: formatMessage(messages.UniqueResultClick),
    },
    { val: 'result_swipe', name: formatMessage(messages.ResultSwipe) },
  ];

  const periods = [
    { val: 'day', name: formatMessage(messages.Daily) },
    { val: 'month', name: formatMessage(messages.Monthly) },
    { val: 'week', name: formatMessage(messages.Weekly) },
    { val: 'year', name: formatMessage(messages.Yearly) },
  ];

  const theme = localStorage.poltioTheme;

  return (
    <div className="p-4">
      <div className="grid grid-cols-2">
        <div className="grid sm:flex gap-x-5 items-center sm:col-span-2">
          <div className="w-full sm:w-1/4">
            <DropDown
              typeValue={typeValue}
              setTypeValue={setTypeValue}
              metricTypes={metricTypes.filter(
                (c) => vsTypeValue !== c.val && c.name !== 'None'
              )}
              first={true}
              formatMessage={formatMessage}
            />
          </div>
          <div className="text-center mt-6 font-medium dark:text-gray-100">
            VS
          </div>
          <div className="w-full sm:w-1/4">
            <DropDown
              typeValue={vsTypeValue}
              setTypeValue={setVsTypeValue}
              metricTypes={metricTypes.filter((c) => typeValue !== c.val)}
              formatMessage={formatMessage}
              first={false}
            />
          </div>
          <span className="isolate inline-flex rounded-md shadow-sm h-10 mt-6 self-end justify-end w-full gap-x-1">
            {periods.map((p, i) => (
              <button
                key={i}
                value={p.val}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setPeriod(p.val);
                }}
                className={classNames(
                  period === p.val
                    ? 'bg-poltio-blue-100 dark:bg-poltio-blue-600'
                    : 'bg-white dark:bg-gray-800',
                  'relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                )}
              >
                {p.name}
              </button>
            ))}
          </span>
        </div>

        <div className="col-span-2 w-full">
          {metrics && metrics.length > 1 ? (
            <Chart
              width={'100%'}
              height={'400px'}
              chartType="AreaChart"
              loader={<Loading />}
              data={metrics}
              options={{
                title: formatMessage(messages.MetricsVsGraph),
                titleTextStyle: { color: theme === 'dark' ? 'white' : 'black' },
                hAxis: {
                  title: formatMessage(
                    messages[period[0].toUpperCase() + period.slice(1)]
                  ),
                  textStyle: {
                    color: theme === 'dark' ? 'white' : 'black',
                    fontSize: 12,
                  },
                  titleTextStyle: { color: theme === 'dark' ? '#fff' : '#333' },
                  fontSize: 16,
                },
                vAxis: {
                  minValue: 0,
                  format: 'short',
                  textStyle: {
                    color: theme === 'dark' ? 'white' : 'black',
                  },
                  gridlines: { color: theme === 'dark' ? 'white' : 'black' },
                },
                chartArea: { width: '90%', height: '70%' },
                colors: ['#009EEC', '#D66DDD'],
                backgroundColor: theme === 'dark' ? '' : '#fff',
                crosshair: {
                  color: '#000',
                  trigger: 'selection',
                },
                animation: {
                  easing: 'inAndOut',
                  duration: 1500,
                },
                areaOpacity: 1,
                legend: { position: 'none' },
              }}
            />
          ) : (
            formatMessage(messages.NoData)
          )}
        </div>
      </div>
    </div>
  );
};

export default VoteStats;
